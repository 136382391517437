exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/activityLogs`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Activity Logs',
            description: 'Get records of all activities performed on a board.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardIds',
                    in: 'query',
                    description: 'boards ids, if you want to filter by borad',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'The number of activity log events returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
                {
                    name: 'user_ids',
                    in: 'query',
                    description:
                        'User IDs that can be used to filter the events returned.',
                },
                {
                    name: 'column_ids',
                    in: 'query',
                    description:
                        'Column IDs that can be used to filter the events returned.',
                },
                {
                    name: 'group_ids',
                    in: 'query',
                    description:
                        'Group IDs that can be used to filter the events returned.',
                },
                {
                    name: 'item_ids',
                    in: 'query',
                    description:
                        'Item IDs that can be used to filter the events returned.',
                },
                {
                    name: 'from',
                    in: 'query',
                    description: 'From timestamp (ISO8601).',
                },
                {
                    name: 'to',
                    in: 'query',
                    description: 'To timestamp (ISO8601).',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/boards`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Boards',
            description: 'List all boards data',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardIds',
                    in: 'query',
                    description: 'boards ids, if you want to filter by borad',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'The number of boards data returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
                {
                    name: 'board_kind',
                    in: 'query',
                    description:
                        "The board's kind (<b>public</b> / <b>private</b> / <b>share</b>).",
                },
                {
                    name: 'state',
                    in: 'query',
                    description:
                        'The state of the board (<b>all</b> / <b>active</b> / <b>archived</b> / <b>deleted</b>). Default is <b>active</b>.',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'The order in which to retrieve your boards (<b>created_at</b> / <b>used_at</b>).',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/boardViews`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Boards Views',
            description: 'Board views allow you get all your board data.',
            produces: ['application/json'],
            parameters: [],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/createBoard`] = {
        post: {
            tags: ['Monday.com'],
            summary: 'Create Board',
            description: 'Create a new board',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardName',
                    in: 'query',
                    description: 'The name of the created board.',
                    required: true,
                },
                {
                    name: 'boardKind',
                    in: 'query',
                    description:
                        "The created board's kind (<b>public</b> / <b>private</b> / <b>share</b>).",
                },
                {
                    name: 'folderId',
                    in: 'query',
                    description: "The board's folder id.",
                },
                {
                    name: 'workspaceId',
                    in: 'query',
                    description: "The board's workspace id.",
                },
                {
                    name: 'templateId',
                    in: 'query',
                    description: "The board's template id",
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/archiveBoard`] = {
        post: {
            tags: ['Monday.com'],
            summary: 'Archive Board',
            description: 'Archive a new board',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: 'Board id to archive',
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/addSubscribers`] = {
        post: {
            tags: ['Monday.com'],
            summary: 'Add Subscribers',
            description: 'Add subscribers to a board',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: "The board's unique identifier.",
                    required: true,
                },
                {
                    name: 'userIds',
                    in: 'query',
                    description: 'User ids to subscribe to the board.',
                    required: true,
                },
                {
                    name: 'kind',
                    in: 'query',
                    description:
                        'Subscribers kind (<b>subscriber</b> / <b>owner</b>).',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/removeSubscribers`] = {
        post: {
            tags: ['Monday.com'],
            summary: 'Remove Subscribers',
            description: 'Remove subscribers from the board',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: "The board's unique identifier.",
                    required: true,
                },
                {
                    name: 'userIds',
                    in: 'query',
                    description: 'User ids to remove from the board.',
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/columns`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Columns',
            description:
                'A board is formatted as a table, where there are columns and rows, called items.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: 'board id',
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/columnValues`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Column Values',
            description:
                'Board has one or more columns, each of which holds a particular type of information.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'The number of activity log events returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/files`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Files',
            description: 'Get your assets data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'assetsIds',
                    in: 'query',
                    description: 'assets id seprated by commas',
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/groups`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Get groups',
            description:
                'Querying groups will return either one group or a collection of groups on a specific board.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: `The board's unique identifier.`,
                    required: true,
                },
                {
                    name: 'groupId',
                    in: 'query',
                    description: `The group's identifier.`,
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/items`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Get Items',
            description: 'Get items data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'itemsIds',
                    in: 'query',
                    description: 'A list of unique items identifier(s).',
                    required: true,
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'The number of items returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Monday.com'],
            summary: 'Create a item',
            description: 'Create new item into your board.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: `The board's unique identifier.`,
                    required: true,
                },
                {
                    name: 'itemName',
                    in: 'query',
                    description: `The new item's name.`,
                    required: true,
                },
                {
                    name: 'groupId',
                    in: 'query',
                    description: `The group's unique identifier.`,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
        delete: {
            tags: ['Monday.com'],
            summary: 'Delete a item',
            description: 'Get items data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'itemId',
                    in: 'query',
                    description: `The item's unique identifier.`,
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/itemsByColValues`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Items By Column Values',
            description: 'Get items data as JSON by column values',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: "The board's unique identifier.",
                    required: true,
                },
                {
                    name: 'columnId',
                    in: 'query',
                    description: "The column's unique identifier.",
                    required: true,
                },
                {
                    name: 'columnValue',
                    in: 'query',
                    description:
                        'The column value to search items by. You can use text values for the search.',
                    required: true,
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'The number of items returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/itemsByMultiColValues`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Items By Multiple Column Values',
            description: 'Get items data as JSON by multiple column values',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'boardId',
                    in: 'query',
                    description: "The board's unique identifier.",
                    required: true,
                },
                {
                    name: 'columnId',
                    in: 'query',
                    description: "The column's unique identifier.",
                    required: true,
                },
                {
                    name: 'column_values',
                    in: 'query',
                    description:
                        'The column values to search items by. You can use text values for the search. ex: ["val1","v1l2"]',
                    required: true,
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'The number of items returned. Default is 25.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page number returned, should you implement pagination. Starts at 1.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/users`] = {
        get: {
            tags: ['Monday.com'],
            summary: 'Users',
            description: 'Get users data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_ids',
                    in: 'query',
                    description:
                        'User IDs that can be used to filter the users returned.',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'The number of users returned. Default is 25.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
