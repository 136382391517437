import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Menu, Drawer, Alert, Empty } from 'antd';
import { RiFolderInfoLine } from 'react-icons/ri';

import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { formatLogs } from '../../config/custom/functions';
import { Firebase } from '../../config/firebase';

const DrawerTitle = () => (
      <div className="drawer-title">
            <h3>API logs</h3>
      </div>
);

const ViewLogs = ({ token }) => {
      const [logsVisible, setLogsVisible] = useState(false);

      const state = useContext(GlobalStateContext);
      const { selectedProjectId } = state;
      const [loading, setLoading] = useState(false);
      const [logsList, setLogsList] = useState([]);
      const [logsListCopy, setLogsListCopy] = useState([]);
      const [appList, setAppList] = useState([]);
      const [noLogs, setNoLogs] = useState(false);

      const showEndpointLogs = () => {
            async function fetchLogs(user) {
                  setLoading(true);
                  setNoLogs(false);
                  const response = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/api/logs/${user.uid}/${selectedProjectId}?token=${token}`
                  );
                  const logsData = response.data;
                  setLogsVisible(true);
                  if (!logsData.notFound) {
                        const formatData = formatLogs(logsData);
                        setLogsList(formatData.logData);
                        setLogsListCopy(formatData.logData);
                        if (formatData.logData.length === 0) {
                              setNoLogs(true);
                        }
                  }
                  setLoading(false);
            }
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        if (selectedProjectId) {
                              fetchLogs(user);
                        }
                  }
            });
      };

      return (
            <div className="view-logs">
                  <Menu.Item
                        style={{
                              fontSize: 14,
                              fontWeight: 300,
                              color: '#3c3c3c',
                        }}
                        onClick={showEndpointLogs}
                        key="1"
                        icon={<RiFolderInfoLine size={14} />}
                  >
                        View logs
                  </Menu.Item>

                  <Drawer
                        width={700}
                        title={<DrawerTitle />}
                        placement="left"
                        closable={false}
                        onClose={() => setLogsVisible(false)}
                        visible={logsVisible}
                  >
                        {logsList.length !== null && !noLogs ? (
                              logsList.map((log, key) => {
                                    if (
                                          log.status === '200' ||
                                          log.status === 'undefined'
                                    ) {
                                          return (
                                                <Alert
                                                      message={`${
                                                            log.status
                                                      } - ${log.type} - ${
                                                            log.path
                                                      } - ${moment(
                                                            log.timestamp
                                                      ).fromNow()}`}
                                                      type="success"
                                                      key={key}
                                                      showIcon
                                                      style={{
                                                            fontSize: 12,
                                                            fontWeight: 300,
                                                            borderRadius: 0,
                                                      }}
                                                />
                                          );
                                    }
                                    if (log.status === '304') {
                                          return (
                                                <Alert
                                                      message={`${
                                                            log.status
                                                      } - ${log.type} - ${
                                                            log.path
                                                      } - ${moment(
                                                            log.timestamp
                                                      ).fromNow()}`}
                                                      type="info"
                                                      key={key}
                                                      showIcon
                                                      style={{
                                                            fontSize: 12,
                                                            fontWeight: 300,
                                                            borderRadius: 0,
                                                      }}
                                                />
                                          );
                                    }
                                    return (
                                          <Alert
                                                message={`${log.status} - ${
                                                      log.type
                                                } - ${log.path} - ${moment(
                                                      log.timestamp
                                                ).fromNow()}`}
                                                type="error"
                                                key={key}
                                                showIcon
                                                style={{
                                                      fontSize: 12,
                                                      fontWeight: 300,
                                                      borderRadius: 0,
                                                }}
                                          />
                                    );
                              })
                        ) : (
                              <Empty
                                    description="There is no logs."
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                        )}
                  </Drawer>
            </div>
      );
};

export default ViewLogs;
