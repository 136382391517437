exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}/listFiles`] = {
            get: {
                  tags: ['Google Drive'],
                  summary: 'List files',
                  description: 'Lists or searches files.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'driveId',
                              in: 'query',
                              description: 'ID of the shared drive to search.',
                        },
                        {
                              name: 'corpora',
                              in: 'query',
                              description: 'Specify when searching in specific driveId, supported values are user, drive, allDrives, domain - default value when driveId is set is drive',

                        },
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'The paths of the fields you want included in the response..',
                        },
                        {
                              name: 'includeItemsFromAllDrives',
                              in: 'query',
                              description:
                                    'Whether both My Drive and shared drive items should be included in results. (Default: false).',
                        },
                        {
                              name: 'includePermissionsForView',
                              in: 'query',
                              description:
                                    "Specifies which additional view's permissions to include in the response. Only 'published' is supported.",
                        },
                        {
                              name: 'orderBy',
                              in: 'query',
                              description:
                                    "A comma-separated list of sort keys. Valid keys are createdTime, folder, modifiedByMeTime, modifiedTime, name, name_natural, quotaBytesUsed, recenct, sharedWithMeTime, starred, viewedByMeTime. Each key sorts ascending by default, but may be reversed with the 'desc' modifier. Example usage: ?orderBy=folder,modifiedTime desc,name.",
                        },
                        {
                              name: 'pageSize',
                              in: 'query',
                              description:
                                    'The maximum number of files to return per page. Acceptable values are 1 to 1000, inclusive. (Default: 100)',
                        },
                        {
                              name: 'pageToken',
                              in: 'query',
                              description:
                                    "The token for continuing a previous list request on the next page. This should be set to the value of 'nextPageToken' from the previous response.",
                        },
                        {
                              name: 'q',
                              in: 'query',
                              description:
                                    "A query for filtering the file results. See for all queries examples https://developers.google.com/drive/api/v3/search-files to search in specific folder instead of driveId use: 'YOUR FOLDERID' in parents"
                        },
                        {
                              name: 'spaces',
                              in: 'query',
                              description:
                                    "A comma-separated list of spaces to query within the corpus. Supported values are 'drive', 'appDataFolder' and 'photos'.",
                        },
                        {
                              name: 'supportsAllDrives',
                              in: 'query',
                              description:
                                    'Whether the requesting application supports both My Drives and shared drives. (Default: false)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/getFile`] = {
            get: {
                  tags: ['Google Drive'],
                  summary: 'Single file',
                  description: "Gets a file's metadata or content by ID.",
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fileId',
                              in: 'query',
                              description: 'The ID of the file.',
                              required: true,
                        },
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    "The paths of the fields you want included in the response. For development you can use the special value * to return all fields, but you'll achieve greater performance by only selecting the fields you need.",
                        },
                        {
                              name: 'includePermissionsForView',
                              in: 'query',
                              description:
                                    "Specifies which additional view's permissions to include in the response. Only 'published' is supported.",
                        },
                        {
                              name: 'supportsAllDrives',
                              in: 'query',
                              description:
                                    'Whether the requesting application supports both My Drives and shared drives. (Default: false)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/uploadFile`] = {
            post: {
                  tags: ['Google Drive'],
                  summary: 'Upload file',
                  description: 'The type of upload request to the /upload URI.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'file',
                              in: 'formData',
                              type: 'file',
                              description: 'file to upload',
                              required: true,
                        },
                        {
                              name: 'ignoreDefaultVisibility',
                              in: 'query',
                              description:
                                    "Whether to ignore the domain's default visibility settings for the created file.",
                        },
                        {
                              name: 'includePermissionsForView',
                              in: 'query',
                              description:
                                    "Specifies which additional view's permissions to include in the response. Only 'published' is supported.",
                        },
                        {
                              name: 'keepRevisionForever',
                              in: 'query',
                              description:
                                    "Whether to set the 'keepForever' field in the new head revision. This is only applicable to files with binary content in Google Drive. Only 200 revisions for the file can be kept forever. If the limit is reached, try deleting pinned revisions. (Default: false)",
                        },
                        {
                              name: 'ocrLanguage',
                              in: 'query',
                              description:
                                    'A language hint for OCR processing during image import (ISO 639-1 code).',
                        },
                        {
                              name: 'supportsAllDrives',
                              in: 'query',
                              description:
                                    'Whether the requesting application supports both My Drives and shared drives. (Default: false)',
                        },
                        {
                              name: 'useContentAsIndexableText',
                              in: 'query',
                              description:
                                    'Whether to use the uploaded content as indexable text. (Default: false)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/uploadRemoteFile`] = {
            post: {
                  tags: ['Google Drive'],
                  summary: 'Upload Remote file',
                  description: 'The type of upload any remote file via url',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'remote_url',
                              in: 'query',
                              description: 'remote url of your file to upload',
                              required: true,
                        },
                        {
                              name: 'ignoreDefaultVisibility',
                              in: 'query',
                              description:
                                    "Whether to ignore the domain's default visibility settings for the created file.",
                        },
                        {
                              name: 'includePermissionsForView',
                              in: 'query',
                              description:
                                    "Specifies which additional view's permissions to include in the response. Only 'published' is supported.",
                        },
                        {
                              name: 'keepRevisionForever',
                              in: 'query',
                              description:
                                    "Whether to set the 'keepForever' field in the new head revision. This is only applicable to files with binary content in Google Drive. Only 200 revisions for the file can be kept forever. If the limit is reached, try deleting pinned revisions. (Default: false)",
                        },
                        {
                              name: 'ocrLanguage',
                              in: 'query',
                              description:
                                    'A language hint for OCR processing during image import (ISO 639-1 code).',
                        },
                        {
                              name: 'supportsAllDrives',
                              in: 'query',
                              description:
                                    'Whether the requesting application supports both My Drives and shared drives. (Default: false)',
                        },
                        {
                              name: 'useContentAsIndexableText',
                              in: 'query',
                              description:
                                    'Whether to use the uploaded content as indexable text. (Default: false)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/deleteFile`] = {
            delete: {
                  tags: ['Google Drive'],
                  summary: 'Delete File',
                  description: 'Delete your drive file with simple endpoint',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fileId',
                              in: 'query',
                              description: 'The ID of the file.',
                              required: true,
                        },
                        {
                              name: 'supportsAllDrives',
                              in: 'query',
                              description:
                                    'Whether the requesting application supports both My Drives and shared drives. (Default: false)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };
      schemaObj[`/${username}/${appId}/${token}/downloadFile`] = {
            get: {
                  tags: ['Google Drive'],
                  summary: 'Download file',
                  description:
                        'Download your drive file with simple GET request.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fileId',
                              in: 'query',
                              description: 'The ID of the file.',
                              required: true,
                        },
                        {
                              name: 'localFolderLocation',
                              in: 'query',
                              description:
                                    'your local folder location. default is temp folder in your OS',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      return schemaObj;
};
