exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Instagram'],
            summary: 'Instagram profile feeds',
            description:
                'Get all your Instagram profile feeds with simple REST API.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'limit',
                    in: 'query',
                    description: 'Number of result you want in one requests',
                },
                {
                    name: 'before',
                    in: 'query',
                    description: 'To get result before current request',
                },
                {
                    name: 'after',
                    in: 'query',
                    description: 'To get result after current request',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/singleFeed`] = {
        get: {
            tags: ['Instagram'],
            summary: 'Single feed',
            description: 'Get single feed by feed id',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'feed id',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
