exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}`] = {
    get: {
      tags: ['Airtable'],
      summary: 'Get Records',
      description: 'List all records from the table of your Airtable Base.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tableName',
          in: 'query',
          description: 'Name of your Airtable base table.',
          required: true,
          type: 'string',
        },
        {
          name: 'fields',
          in: 'query',
          description:
            'Only data for fields whose names are in this list will be included in the result. with seprated commas',
          type: 'string',
        },
        {
          name: 'id',
          in: 'query',
          description: 'record id',
          type: 'string',
        },
        {
          name: 'view',
          in: 'query',
          description: "By default is 'Grid view'",
          required: false,
          type: 'string',
        },
        {
          name: 'perPage',
          in: 'query',
          description:
            "Total number of records you want in one request.(0-100). <b>PRO users can get more than 100 records or pass 'all' to get all. Just pass perPage=all</b>",
          required: false,
          type: 'string',
        },
        {
          name: 'offset',
          in: 'query',
          description: 'To get next page records.',
          required: false,
          type: 'string',
        },
        {
          name: 'sortBy',
          in: 'query',
          description: 'Field Name by which you wanna sort the response',
          required: false,
          type: 'string',
        },
        {
          name: 'sortDirection',
          in: 'query',
          description: 'Sort the responce ascending or descending order',
          required: false,
          type: 'string',
        },
        {
          name: 'filterByFormula',
          in: 'query',
          description:
            'A formula used to filter records. Here is <a href="https://support.airtable.com/hc/en-us/articles/203255215-Formula-field-reference" target="_blank">list of formulas</a>',
          required: false,
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    post: {
      tags: ['Airtable'],
      summary: 'Create new Records',
      description: 'Create multiple new record into Airtable.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tableName',
          in: 'query',
          description: 'Name of your Airtable base table.',
          required: true,
          type: 'string',
        },
        {
          name: 'typecast',
          in: 'query',
          description: 'To enable Automatic conversion. true or false',
          type: 'boolean',
        },
        {
          name: 'body',
          in: 'body',
          description: 'Pass an array of row array into body',
          required: true,
          sampleData: [
            {
              title: 'Example 2',
              website: 'https://example2.com',
            },
          ],
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    put: {
      tags: ['Airtable'],
      summary: 'Update Records',
      description: 'You can update mutiple record in one call.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tableName',
          in: 'query',
          description: 'Name of your Airtable base table.',
          required: true,
          type: 'string',
        },
        {
          name: 'body',
          in: 'body',
          description: 'Pass an array of row array into body',
          required: true,
          sampleData: [
            {
              id: 'rec2CO7nLufSs5pKx',
              fields: {
                title: 'New Example 2',
                website: 'https://example2.com',
              },
            },
          ],
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    delete: {
      tags: ['Airtable'],
      summary: 'Delete Records',
      description: 'You can delete mutiple record in one call.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tableName',
          in: 'query',
          description: 'Name of your Airtable base table.',
          required: true,
          type: 'string',
        },
        {
          name: 'body',
          in: 'body',
          description: 'Pass an array of row array into body',
          required: true,
          sampleData: ['rec2CO7nLufSs5pKx', 'rec40zVHDN4nW4jVu'],
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };
  schemaObj[`/${username}/${appId}/${token}/record`] = {
    get: {
      tags: ['Airtable'],
      summary: 'Get Single Record',
      description: 'Get a record by id',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tableName',
          in: 'query',
          description: 'Name of your Airtable base table.',
          required: true,
          type: 'string',
        },
        {
          name: 'id',
          in: 'query',
          description: 'record id.',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  return schemaObj;
};
