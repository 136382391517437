exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Medium'],
            summary: 'Get Medium feed as JSON',
            description: 'Get your medium feed as json',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
