import React, { useEffect, useState } from 'react';
import { helpMeWith, languageOrFramework, outputAs } from './option';
import { Form, Select } from 'antd';

const Programmer = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    'Help me with': null,
    'Output As': null,
    'Language/Framework': null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Help me with'>
        <Select
          options={helpMeWith}
          placeholder='Help me with'
          onChange={(value) => {
            setFormKey('Help me with', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Output As'>
        <Select
          options={outputAs}
          placeholder='Output As'
          onChange={(value) => {
            setFormKey('Output As', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Language/Framework'>
        <Select
          options={languageOrFramework}
          placeholder='Language/Framework'
          onChange={(value) => {
            setFormKey('Language/Framework', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
    </Form>
  );
};

export default Programmer;
