exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/channels`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Channels List',
            description: 'List of all the channels from your account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'channelId',
                    in: 'query',
                    description:
                        "This value indicates that the API should only return the specified channel's list.",
                    required: false,
                    type: 'string',
                },
                {
                    name: 'part',
                    in: 'query',
                    description:
                        'The part parameter specifies a comma-separated list of one or more playlist resource properties that the API response will include. There are possible list <b>auditDetails</b>, <b>brandingSettings</b>, <b>contentDetails</b>, <b>contentOwnerDetails</b>, <b>id</b>, <b>localizations</b>, <b>snippet</b>, <b>statistics</b>, <b>status</b>, <b>topicDetails</b>',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/channelVideos`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Channel Videos',
            description:
                'List all the upload videos from your YouTube channels with single endpoint.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'channelId',
                    in: 'query',
                    description:
                        "This value indicates that the API should only return the specified channel's upload videos.",
                    required: false,
                    type: 'string',
                },
                {
                    name: 'part',
                    in: 'query',
                    description:
                        'The part parameter specifies a comma-separated list of one or more playlist resource properties that the API response will include.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5. <b>PRO users can get more than 50 videos in one API call. Just pass maxResults=all</b>',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/playlist`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Playlist',
            description:
                'Information about playlist or list of playlist if you pass channelId.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description:
                        'The id parameter specifies a comma-separated list of the YouTube playlist ID(s) for the resource(s) that are being retrieved.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'channelId',
                    in: 'query',
                    description:
                        "This value indicates that the API should only return the specified channel's playlists.",
                    required: false,
                    type: 'string',
                },
                {
                    name: 'part',
                    in: 'query',
                    description:
                        'The part parameter specifies a comma-separated list of one or more playlist resource properties that the API response will include.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/playlistItems`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Playlist videos',
            description:
                'List of playlist video as json by passing playlist id.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'playlistId',
                    in: 'query',
                    description:
                        'The playlistId parameter specifies the unique ID of the playlist for which you want to retrieve playlist items.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Search',
            description:
                'List of playlist video as json by passing playlist id.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'You string query wants to search.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'part',
                    in: 'query',
                    description:
                        'The part parameter specifies a comma-separated list of one or more playlist resource properties that the API response will include.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'type',
                    in: 'query',
                    description: 'channel, playlist or video',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'forMine',
                    in: 'query',
                    description:
                        'The forMine parameter restricts the search to only retrieve videos owned by the authenticated user. (boolean 0 or 1)',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'channelId',
                    in: 'query',
                    description:
                        'The channelId parameter indicates that the API response should only contain resources created by the channel.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'channelType',
                    in: 'query',
                    description:
                        'any – Return all channels. or show – Only retrieve shows.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'eventType',
                    in: 'query',
                    description:
                        'completed – Only include completed broadcasts, live – Only include active broadcasts, or upcoming – Only include upcoming broadcasts.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'publishedAfter',
                    in: 'query',
                    description:
                        'The value is an RFC 3339 formatted date-time value (1970-01-01T00:00:00Z).',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'publishedBefore',
                    in: 'query',
                    description:
                        'The value is an RFC 3339 formatted date-time value (1970-01-01T00:00:00Z).',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'regionCode',
                    in: 'query',
                    description:
                        'The regionCode parameter instructs the API to return search results for videos that can be viewed in the specified country. The parameter value is an ISO 3166-1 alpha-2 country code.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'safeSearch',
                    in: 'query',
                    description: 'moderate, none, or strict',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoDefinition',
                    in: 'query',
                    description: 'any, high, or standard',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoDimension',
                    in: 'query',
                    description: 'ad, 3d, or any',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoDuration',
                    in: 'query',
                    description: 'any, long, medium, or short',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoLicense',
                    in: 'query',
                    description: 'any, creativeCommon, or youtube',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoType',
                    in: 'query',
                    description: 'any, episode, or movie',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/videos`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Videos',
            description:
                'Retrieves information about a specific video or multiple videos',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description:
                        'The id parameter specifies a comma-separated list of the YouTube video ID(s)',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/trending`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Trending Videos',
            description:
                'List of trending videos in any country with single endpoint',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5. <b>PRO users can get more than 50 videos in one API call. Just pass maxResults=all</b>',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'regionCode',
                    in: 'query',
                    description:
                        'The regionCode parameter instructs the API to return search results for videos that can be viewed in the specified country. The parameter value is an ISO 3166-1 alpha-2 country code.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'videoCategoryId',
                    in: 'query',
                    description:
                        'The videoCategoryId parameter identifies the video category for which the chart should be retrieved. The default value is 0.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/likedVieos`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Liked Videos',
            description:
                'Retrieves your liked videos from your YouTube account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5. <b>PRO users can get more than 50 videos in one API call. Just pass maxResults=all</b>',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/subscriptions`] = {
        get: {
            tags: ['YouTube'],
            summary: 'Subscriptions',
            description: 'List of all your subscription on YouTube',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'type',
                    in: 'query',
                    description: 'mine, mySubscribers',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description:
                        'The maxResults parameter specifies the maximum number of items that should be returned in the result set. Acceptable values are 0 to 50, inclusive. The default value is 5. <b>PRO users can get more than 50 items in one API call. Just pass maxResults=all</b>',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description:
                        'The pageToken parameter identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'alphabetical, relevance, or unread',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
