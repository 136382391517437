import { Button, Modal, Space, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from '../../context/GlobalContextProvider';

const EnrichList = ({ apiObj }) => {
  const state = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(true);
  const [enrichData, setEnrichData] = useState([]);
  const { cloud_name, token } = apiObj;
  const [open, setOpen] = useState(false);
  const [selectedReply, setSelectedReply] = useState('');

  const fetchEnrichData = async () => {
    setLoading(true);
    const enrichList = await axios.get(
      `${process.env.GATSBY_BASE_URL}/${cloud_name}/enrich/${token}/list`,
      { headers: { 'Content-Type': 'application/json' } },
    );
    if (enrichList.data && enrichList.data.length > 0) {
      const data = [];
      enrichList.data.map((enrich, index) => {
        data.push({
          key: index + 1,
          date: moment(enrich.created_date).format('DD MMM YYYY'),
          id: enrich._id,
          email: enrich.email,
          reply: enrich.fields[0],
        });
      });
      setEnrichData(data);
    } else {
      setEnrichData([]);
    }
    setLoading(false);
  };

  const handleModalOpen = (data) => {
    setSelectedReply(data);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'JSON',
      key: 'reply',
      render: (_, record) => (
        <>
          <Button onClick={() => handleModalOpen(record.reply)}>view</Button>
          <Modal
            title='JSON'
            open={open}
            onCancel={handleCancel}
            footer={null}
            width={800}
            bodyStyle={{ maxHeight: '500px', overflowY: 'scroll' }}
          >
            <pre>{JSON.stringify(selectedReply, null, 2)}</pre>
          </Modal>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchEnrichData();
  }, []);

  return (
    <div>
      <Table dataSource={enrichData} columns={columns} />
    </div>
  );
};
export default EnrichList;
