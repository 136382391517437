import React, { useEffect, useContext, useState } from 'react';
import { Empty, message, Modal, Tabs } from 'antd';
import axios from 'axios';
import { navigate, Link } from 'gatsby';

import { Firebase } from '../../config/firebase';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { getAppName } from '../../config/custom/functions';
import MakeNewAPI from './makeNewAPI';
import APIendpointCard from './apiEndpointCard';
import ScreenshotList from "./screenshotList";
import NoCodeFormData from "./nocodeformData";
import AirtableImportLogic from './importers/airtable';
import AirtableImporter from './importers/airtableImportPlayground';
import SheetImporter from './importers/sheetImportPlayground';
import WebflowImporter from './importers/webflowImportPlayground';
import EnrichList from './enrichList';
import '../../styles/api-page.scss';

const APIpage = ({ appId, location }) => {
      const state = useContext(GlobalStateContext);
      const { user, selectedProjectId } = state;
      const [isImporterVisible, setIsImporterVisible] = useState(false);

      const appName = getAppName(appId);

      const [baseURLForEndpoint, setBaseURLForEndpoint] = useState(
            process.env.GATSBY_BASE_URL
      );
      const [apisLists, setApisLists] = useState([]);
      const [loading, setLoading] = useState(false);
      const [apiDataToEdit, setApiDataToEdit] = useState(null);
      const [formState, setFormState] = useState('adding');
      const [triggerChange, setTriggerChange] = useState(0);
      const [openDrawerToEdit, setOpenDrawerToEdit] = useState(false);
      const [activeAPIData, setActiveAPIData] = useState(null);

      const closeEditDrawer = () => {
            setOpenDrawerToEdit(false);
            setFormState('adding');
      };

      const openImportModal = (apiObj) => {
            setActiveAPIData(apiObj);
            setIsImporterVisible(true);
      };

      useEffect(() => {
            if (user !== null && user.status[0] === 'enterprise') {
                  setBaseURLForEndpoint(user.custom_domain);
            }

            async function fetchAPIsLists(uid) {
                  setLoading(true);
                  const response = await axios.post(
                        `${process.env.GATSBY_BASE_URL}/getAppList/${appId}`,
                        { uid: uid, projectId: selectedProjectId },
                        { headers: { 'Content-Type': 'application/json' } }
                  );

                  if (response.data.notFound !== undefined) {
                        navigate('/setup');
                  } else {
                        setApisLists([]);
                        setApisLists(response.data);
                        setLoading(false);
                  }
            }

            if (selectedProjectId) {
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              fetchAPIsLists(user.uid);
                        }
                  });
            }
      }, [appId, selectedProjectId, triggerChange]);

      const deleteThisAPI = (api) => {
            if (
                  window.confirm(
                        `Are you sure you want to delete ${api.apis_info.name} API?`
                  )
            ) {
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              axios.delete(
                                    `${process.env.GATSBY_BASE_URL}/make-api`,
                                    { params: { _id: api._id, uid: user.uid } },
                                    {
                                          headers: {
                                                'Content-Type':
                                                      'application/json',
                                          },
                                    }
                              )
                                    .then((res) => {
                                          message.info(
                                                `${api.apis_info.name} API deleted!`
                                          );
                                          setTriggerChange(triggerChange + 1);
                                    })
                                    .catch((error) => {
                                          message.info(
                                                'Something is not right!'
                                          );
                                    });
                        }
                  });
            }
      };

      const onEditMode = (apiInfo) => {
            setFormState('editing');
            setApiDataToEdit(apiInfo);
            setOpenDrawerToEdit(!openDrawerToEdit);
      };

      return (
            <div className="api-page">
                  <Modal
                        title={
                              <h3
                                    style={{
                                          fontSize: 16,
                                          display: 'flex',
                                          alignItems: 'center',
                                          gridGap: 10,
                                    }}
                              >
                                    <img
                                          src={`/logos/${appId}.png`}
                                          alt=""
                                          height="26px"
                                    />
                                    {getAppName(appId)} Data Importer
                              </h3>
                        }
                        centered
                        className="playground-modal"
                        visible={isImporterVisible}
                        onOk={() => setIsImporterVisible(false)}
                        onCancel={() => setIsImporterVisible(false)}
                        width="95%"
                        bodyStyle={{ height: '85vh' }}
                        footer={null}
                        keyboard={false}
                  >
                        {appId === 'airtable' && (
                              <AirtableImporter activeAPIData={activeAPIData} />
                        )}
                        {appId === 'google_sheets' && (
                              <SheetImporter activeAPIData={activeAPIData} />
                        )}
                        {appId === 'webflow' && (
                              <WebflowImporter activeAPIData={activeAPIData} />
                        )}
                  </Modal>

                  <div className="two-col-page-head-section">
                        <h3 style={{ marginBottom: 0 }}>
                              <p
                                    style={{
                                          fontSize: 13,
                                          margin: 0,
                                          padding: 0,
                                    }}
                              >
                                    <Link to="/dashboard">
                                          View all applications
                                    </Link>
                              </p>
                              <img
                                    src={`/logos/${appId}.png`}
                                    alt=""
                                    height="26px"
                                    style={{
                                          marginRight: 10,
                                    }}
                              />{' '}
                              {appName} APIs
                        </h3>
                        <MakeNewAPI
                              location={location}
                              appName={appName}
                              appId={appId}
                              apiToEdit={apiDataToEdit}
                              formState={formState}
                              openDrawerToEdit={openDrawerToEdit}
                              closeEditDrawer={() => closeEditDrawer()}
                              refreshList={() =>
                                    setTriggerChange(triggerChange + 1)
                              }
                        />
                  </div>
                  <div className="api-list">
                        {apisLists.length > 0 ? (
                              <div>
                                    {apisLists.map((apiObj, key) => (
                                          <>
                                          <APIendpointCard
                                                key={key}
                                                apiObj={apiObj}
                                                baseURLForEndpoint={
                                                      baseURLForEndpoint
                                                }
                                                onEditMode={() =>
                                                      onEditMode(apiObj)
                                                }
                                                deleteThisAPI={() =>
                                                      deleteThisAPI(apiObj)
                                                }
                                                openImportModal={
                                                      openImportModal
                                                }
                                          />
                                               {apiObj.app_id ===  'screen' && 
                                                      <div style={{fontWeight:200}}>
                                                            <Tabs defaultActiveKey={apiObj.app_id}>
                                                                  <Tabs.TabPane tab="Screenshots" key={apiObj.app_id}>
                                                                        <ScreenshotList apiObj={apiObj}/>  
                                                                  </Tabs.TabPane>
                                                            </Tabs>  
                                                      </div>       
                                                }

                                                {apiObj.app_id ===  'nForms' && 
                                                      <div style={{fontWeight:200}}>
                                                            <Tabs defaultActiveKey={apiObj.app_id}>
                                                                  <Tabs.TabPane tab="NoCode Forms" key={apiObj.app_id}>
                                                                        <NoCodeFormData apiObj={apiObj}/>  
                                                                  </Tabs.TabPane>
                                                            </Tabs>  
                                                      </div>       
                                                }

                                                {apiObj.app_id ===  'enrich' && 
                                                      <div style={{fontWeight:200}}>
                                                            <Tabs defaultActiveKey={apiObj.app_id}>
                                                            <Tabs.TabPane tab="Enrich" key={apiObj.app_id}>
                                                                        <EnrichList apiObj={apiObj}/>  
                                                                  </Tabs.TabPane>
                                                            </Tabs>  
                                                      </div>       
                                                }                                                
                                          </>
                                    ))}
                              </div>
                        ) : (
                              <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                          <span
                                                style={{
                                                      fontWeight: 300,
                                                      color: '#6f6f6f',
                                                }}
                                                dangerouslySetInnerHTML={{
                                                      __html: `There is no <b><u>${appName} API</u></b>. Click on <b><u>Create</u></b> button to get started.`,
                                                }}
                                          />
                                    }
                              />
                        )}
                  </div>
            </div>
      );
};

export default APIpage;
