exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Link Preview'],
            summary: 'Get Meta Data',
            description: 'Extract meta data from link',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'pass url query params to get metadata',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
