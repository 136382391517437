import { Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  howToTranslate,
  inTheVoiceOf,
  length,
  outputAs,
  readerComprehension,
  slang,
  style,
  tone,
  translateTo,
} from './options';
import { showTextArea } from '../helpers';

const Translator = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    'How to translate': null,
    'Translate to': null,
    Style: null,
    Tone: null,
    'In the voice of': null,
    Slang: null,
    'Reader comprehension': null,
    Length: null,
    'Output as': null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  const onChangeText = (event, name) => {
    setFormKey(name, event.target.value);
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='How to translate'>
        <Select
          options={howToTranslate}
          placeholder='How to translate'
          onChange={(value) => {
            setFormKey('How to translate', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Translate to'>
        <Select
          options={translateTo}
          placeholder='Translate to'
          onChange={(value) => {
            setFormKey('Translate to', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea('Add your language', 'Translate to', translateTo, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Language'>
          <Input.TextArea
            placeholder='Your language'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Translate to')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Style'>
        <Select
          options={style}
          placeholder='Style'
          onChange={(value) => {
            setFormKey('Style', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Tone'>
        <Select
          options={tone}
          placeholder='Tone'
          onChange={(value) => {
            setFormKey('Tone', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='In the voice of'>
        <Select
          options={inTheVoiceOf}
          placeholder='In the voice of'
          onChange={(value) => {
            setFormKey('In the voice of', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea('Add other famous personas here', 'In the voice of', inTheVoiceOf, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your chosen voice'>
          <Input.TextArea
            placeholder='Your chosen voice'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'In the voice of')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Slang'>
        <Select
          options={slang}
          placeholder='Slang'
          onChange={(value) => {
            setFormKey('Slang', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea('Use your imagination and add :)', 'Slang', slang, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Slang'>
          <Input.TextArea
            placeholder='Your slang'
            style={{ height: 120, marginBottom: 24 }}
            // value={addMoreOption.Slang}
            onChange={(e) => onChangeText(e, 'Slang')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Reader comprehension'>
        <Select
          options={readerComprehension}
          placeholder='Reader comprehension'
          onChange={(value) => {
            setFormKey('Reader comprehension', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Length'>
        <Select
          options={length}
          placeholder='Length'
          onChange={(value) => {
            setFormKey('Length', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Output as'>
        <Select
          options={outputAs}
          placeholder='Output as'
          onChange={(value) => {
            setFormKey('Output as', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
    </Form>
  );
};

export default Translator;
