exports.obj = function(username, appId, token) {
    const schemaObj = {}

    schemaObj[`/${username}/${appId}/${token}/sendText`] = {
        post: {
            tags: ['Telegram'],
            summary: 'Send Text to Channel',
            description: 'Pass text data as query POST request and you will recieved the data in your channel',
            parameters: [
                {
                    name: 'text',
                    in: 'query',
                    description: 'Pass the text',
                    required: true,
                },
                {
                    name: 'parse_mode',
                    in: 'query',
                    description: 'Mode for parsing entities in the message text.',
                },
                {
                    name: 'disable_web_page_preview',
                    in: 'query',
                    description: 'Disables link previews for links in this message',
                },
                {
                    name: 'disable_notification',
                    in: 'query',
                    description: 'Sends the message silently. Users will receive a notification with no sound.',
                },
                {
                    name: 'reply_to_message_id',
                    in: 'query',
                    description: 'If the message is a reply, ID of the original message',
                },
                {
                    name: 'allow_sending_without_reply',
                    in: 'query',
                    description:
                        'Pass True, if the message should be sent even if the specified replied-to message is not found',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}`] = {
        post: {
            tags: ['Telegram'],
            summary: 'Send Object Data to Channel',
            description: 'Pass object data into body as POST request and you will recieved the data in your channel',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'bindKeys',
                    in: 'query',
                    description: 'pass key values by comma those wants to filter',
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an array of row array into body',
                    required: true,
                    sampleData: {
                        email: 'hello@nocodeapi.com',
                        notification: 'payment',
                        source: 'nocodeapi.com',
                    },
                },
                {
                    name: 'disable_web_page_preview',
                    in: 'query',
                    description: 'Disables link previews for links in this message',
                },
                {
                    name: 'disable_notification',
                    in: 'query',
                    description: 'Sends the message silently. Users will receive a notification with no sound.',
                },
                {
                    name: 'reply_to_message_id',
                    in: 'query',
                    description: 'If the message is a reply, ID of the original message',
                },
                {
                    name: 'allow_sending_without_reply',
                    in: 'query',
                    description:
                        'Pass True, if the message should be sent even if the specified replied-to message is not found',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
