exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['IP Geolocation'],
            summary: 'IP address Lookup',
            description: 'IP address Lookup and geolocation as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'ip',
                    in: 'query',
                    description:
                        'pass IP address as query param. by default network IP.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
