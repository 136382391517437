exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/getAllUsers`] = {
        get: {
            tags: ['Firebase Admin'],
            summary: 'List all users',
            description: 'Retrieving the entire list of users in batches',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'limit the object to return',
                },
                {
                    name: 'nextPageToken',
                    in: 'query',
                    description: 'The cursor token for the next page.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/getUser`] = {
        get: {
            tags: ['Firebase Admin'],
            summary: 'User by uid',
            description: 'Get single user information by uid',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'uid',
                    in: 'query',
                    description: 'user uid',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/getUser`] = {
        get: {
            tags: ['Firebase Admin'],
            summary: 'User by uid',
            description: 'Get single user information by uid',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'uid',
                    in: 'query',
                    description: 'user uid',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/getUserByEmail`] = {
        get: {
            tags: ['Firebase Admin'],
            summary: 'User by email',
            description: 'Get single user information by email',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'email',
                    in: 'query',
                    description: 'user email address',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/firestore/allDocuments`] = {
        get: {
            tags: ['Firebase Admin'],
            summary: 'List Documents',
            description: 'Get all documents in a collection',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'collectionName',
                    in: 'query',
                    description: 'collection name',
                    required: true,
                },
                {
                    name: 'orderBy',
                    in: 'query',
                    description: 'key value to order by',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'order direction: asc or desc',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'limit the object to return',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'page number',
                },
                {
                    name: 'whereQuery',
                    in: 'query',
                    description:
                        'your query to filter results. ex: age,>,18 will result all the object those have age greater than 18',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
