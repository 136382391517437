exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/add`] = {
        post: {
            tags: ['Mailgun'],
            summary: 'Add Subscriber',
            description: 'Add email address into your mailgun subscribers list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'listAddress',
                    in: 'query',
                    description: 'Mailing list address',
                    required: true,
                },
                {
                    name: 'address',
                    in: 'query',
                    description: 'Email Address of subscriber',
                    required: true,
                },
                {
                    name: 'name',
                    in: 'query',
                    description: 'Name of the user',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/send`] = {
        post: {
            tags: ['Mailgun'],
            summary: 'Send Email',
            description:
                'Use this Endpoint to send to one user by one email. like welcome & onboarding email',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'senderName',
                    in: 'query',
                    description: 'Sender name',
                    required: true,
                },
                {
                    name: 'senderEmail',
                    in: 'query',
                    description: 'Sender Email',
                    required: true,
                },
                {
                    name: 'to',
                    in: 'query',
                    description:
                        'Email of the reciever (You can use commas to separate multiple recipients.)',
                    required: true,
                },
                {
                    name: 'subject',
                    in: 'query',
                    description: 'Subject of you email',
                    required: true,
                },
                {
                    name: 'template',
                    in: 'query',
                    description: 'Name of your template from mailgun',
                    required: true,
                },
                {
                    name: 'deliveryTime',
                    in: 'query',
                    description:
                        'Desired time of delivery as strings encoded according to RFC 2822. ex: (Sun, 19 Jan 2020 19:30:54 GMT) Note: Messages can be scheduled for a maximum of 3 days in the future.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/memberList`] = {
        get: {
            tags: ['Mailgun'],
            summary: 'Members List',
            description: 'Retrieves a mailing list member.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'listAddress',
                    in: 'query',
                    description: 'mailing list address',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/member`] = {
        put: {
            tags: ['Mailgun'],
            summary: 'Update member',
            description: 'Updates a mailing list member with given properties',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'listAddress',
                    in: 'query',
                    description: 'mailing list address',
                    required: true,
                },

                {
                    name: 'emailAddress',
                    in: 'query',
                    description: 'member address',
                    required: true,
                },
                {
                    name: 'properties',
                    in: 'body',
                    description: 'pass object',
                    required: false,
                    sampleData: {
                        name: 'bob',
                        vars: { gender: 'female', age: 27 },
                        subscribed: 'yes',
                    },
                },
                {
                    name: 'properties',
                    in: 'body',
                    description: 'pass object',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        delete: {
            tags: ['Mailgun'],
            summary: 'Delete member',
            description: 'Delete a mailing list member.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'listAddress',
                    in: 'query',
                    description: 'mailing list address',
                    required: true,
                },

                {
                    name: 'emailAddress',
                    in: 'query',
                    description: 'member address',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
