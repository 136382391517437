exports.obj = function (username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/invoices`] = {
        get: {
            tags: ['QuickBook'],
            summary: 'Get all invoices',
            description:
                'Get an invoice in quickbook',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'startPosition',
                    in: 'query',
                    description: 'starting index of invoice list',
                    required: true

                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'max number of invoices to retrieve. Not more than 50',
                    required: true

                }
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/invoice/create`] = {
        post: {
            tags: ['QuickBook'],
            summary: 'Create an invoice',
            description:
                'Create an invoice',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass invoice object',
                    sampleData: {
                        "Line": [
                            {
                                "Amount": 100.00,
                                "DetailType": "SalesItemLineDetail",
                                "SalesItemLineDetail": {
                                    "ItemRef": {
                                        "value": "1",
                                        "name": "Services"
                                    }
                                }
                            }
                        ],
                        "CustomerRef": {
                            "value": "1"
                        }
                    },
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/customers`] = {
        get: {
            tags: ['QuickBook'],
            summary: 'Get list of customers',
            description: 'Get list of customers',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'startPosition',
                    in: 'query',
                    description: 'starting index of customers list',
                    required: true

                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'max number of customers to retrieve. Not more than 50',
                    required: true

                }
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/customer/create`] = {
        post: {
            tags: ['QuickBook'],
            summary: 'Add a customer',
            description: 'Add a customer',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass customer object',
                    sampleData: {
                        "FullyQualifiedName": "King Groceries",
                        "PrimaryEmailAddr": {
                            "Address": "jdrew@myemail.com"
                        },
                        "DisplayName": "King's Groceries",
                        "Suffix": "Jr",
                        "Title": "Mr",
                        "MiddleName": "B",
                        "Notes": "Here are other details.",
                        "FamilyName": "King",
                        "PrimaryPhone": {
                            "FreeFormNumber": "(555) 555-5555"
                        },
                        "CompanyName": "King Groceries",
                        "BillAddr": {
                            "CountrySubDivisionCode": "CA",
                            "City": "Mountain View",
                            "PostalCode": "94042",
                            "Line1": "123 Main Street",
                            "Country": "USA"
                        },
                        "GivenName": "James"
                    },
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/customer/update`] = {
        put: {
            tags: ['QuickBook'],
            summary: 'Update a customer',
            description: 'Update single customer by Id',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Id is required, SyncToken is required and is to be incremented on each update. DisplayName must be unique in combination with Title, GivenName, MiddleName, FamilyName, and Suffix',
                    sampleData: {
                        "Customer": {
                            "domain": "QBO",
                            "PrimaryEmailAddr": {
                                "Address": "Surf@Intuit.com"
                            },
                            "DisplayName": "Bill's Windsurf Shop",
                            "PreferredDeliveryMethod": "Print",
                            "GivenName": "Bill",
                            "FullyQualifiedName": "Bill's Windsurf Shop",
                            "BillWithParent": false,
                            "Job": false,
                            "BalanceWithJobs": 85.0,
                            "PrimaryPhone": {
                                "FreeFormNumber": "(415) 444-6538"
                            },
                            "Active": true,
                            "MetaData": {
                                "CreateTime": "2014-09-11T16:49:28-07:00",
                                "LastUpdatedTime": "2015-07-23T11:07:55-07:00"
                            },
                            "BillAddr": {
                                "City": "Half Moon Bay",
                                "Line1": "12 Ocean Dr.",
                                "PostalCode": "94213",
                                "Lat": "37.4307072",
                                "Long": "-122.4295234",
                                "CountrySubDivisionCode": "CA",
                                "Id": "3"
                            },
                            "MiddleName": "Mark",
                            "Taxable": false,
                            "Balance": 85.0,
                            "SyncToken": "1",
                            "CompanyName": "Bill's Windsurf Shop",
                            "FamilyName": "Lucchini",
                            "PrintOnCheckName": "Bill's Windsurf Shop",
                            "sparse": false,
                            "Id": "2"
                        },
                        "time": "2015-07-23T11:07:55.772-07:00"
                    },
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
