import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GiCube } from 'react-icons/gi';
import { Button, Empty } from 'antd';
import { GlobalStateContext } from '../../context/GlobalContextProvider';

import '../../styles/applications.scss';

const Addons = () => {
      const state = useContext(GlobalStateContext);
      const { projects, selectedProjectId } = state;

      const [activeAddons, setActiveAddons] = useState([]);

      useEffect(() => {
            if (projects) {
                  let project = projects.find(
                        (p) => p._id == selectedProjectId
                  );
                  setActiveAddons(project?.addons_list);
            }
      }, []);
      
      return (
            <div className="applications">
                  <div
                        className="two-col-page-head-section"
                        style={{ padding: 0 }}
                  >
                        <div className="page-content-header">
                              <h3>Addons</h3>
                              <p>
                                    Your all active addons in this current
                                    project.
                              </p>
                        </div>
                        <Link to="/addonsmarketplace">
                              <Button
                                    type="primary"
                                    className="make-btn"
                                    style={{
                                          fontSize: 13,
                                          fontWeight: 400,
                                    }}
                                    icon={<GiCube />}
                              >
                                    View Addons Marketplace
                              </Button>
                        </Link>
                  </div>
                  {projects !== null && selectedProjectId !== null ? (
                        <div className="apps-list" style={{ margin: 0 }}>
                              {projects
                                    .find((p) => p._id == selectedProjectId)
                                    ?.addons_list?.map((addon, key) => (
                                          <Link
                                                to={`/dashboard/addons/${addon.id}`}
                                                className="active-app-card"
                                                key={key}
                                          >
                                                <div className="card-content">
                                                      <img
                                                            src={`/logos/addons_${addon.id}.png`}
                                                            alt={addon.name}
                                                            height="30px"
                                                      />
                                                      <h3>{addon.name}</h3>
                                                </div>
                                          </Link>
                                    ))}
                        </div>
                  ) : (
                        'Loading...'
                  )}
                        
                  {projects !== null &&
                        (projects.find((p) => p._id == selectedProjectId)?.addons_list?.length === 0
                        ||
                        projects.find((p) => p._id == selectedProjectId)?.addons_list === undefined)
                        && (
                              <>
                                    <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description="No active addons"
                                    />

                                    <div className="getting-started">
                                          <p
                                                style={{
                                                      fontWeight: 500,
                                                      textDecoration:
                                                            'underline',
                                                }}
                                          >
                                                Quick Getting Started in
                                                3-steps.
                                          </p>
                                          <ul>
                                                <li>
                                                      <p>
                                                            — Go to the{' '}
                                                            <Link to="/addonsmarketplace">
                                                                  Addons Marketplace.
                                                            </Link>
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            — <b>Activate and Subscribe</b>{' '}
                                                            the Addon you want to connect.
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            — Click on the Addon in your Addons section and start using
                                                      </p>
                                                </li>
                                          </ul>
                                    </div>
                              </>
                  )}
                  
            </div>
      );
};

export default Addons;
