exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/videos`] = {
        get: {
            tags: ['Vimeo'],
            summary: 'Get all Videos',
            description: 'List all videos that the authenticated user has uploaded or by user_id',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'The ID of the user.',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description:
                        'likes, modified_time, plays, alphabetical, date, duration, last_user_action_event_date',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc',
                    type: 'string',
                },
                {
                    name: 'filter',
                    in: 'query',
                    description: 'app_only, embeddable, featured, playable',
                    type: 'string',
                },
                {
                    name: 'filter_category',
                    in: 'query',
                    description: 'The category filter option.',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'The page number of the results to show.',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of items to show on each page of results, up to a maximum of 100.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/videoInfo`] = {
        get: {
            tags: ['Vimeo'],
            summary: 'Single Video Info',
            description: 'Get single video info.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'video_id',
                    in: 'query',
                    description: 'video id',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Vimeo'],
            summary: 'Search Videos',
            description: 'Search videos on vimeo',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'The search query.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'alphabetical, comments, date, duration, likes, plays, relevant',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc',
                    type: 'string',
                },
                {
                    name: 'filter',
                    in: 'query',
                    description:
                        'categories, duration, in-progress, minimum_likes, trending, upload_date, CC, CC-BY, CC-BY-NC, CC-BY-NC-ND, CC-BY-NC-SA, CC-BY-ND, CC-BY-SA, CC0',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'The page number of the results to show.',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of items to show on each page of results, up to a maximum of 100.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/channelVideos`] = {
        get: {
            tags: ['Vimeo'],
            summary: 'Channel Videos',
            description: 'Get all the videos in a channel',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'channel_id',
                    in: 'query',
                    description: 'channel id',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'added, alphabetical, comments, date, duration, likes, manual, modified_time, plays',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc',
                    type: 'string',
                },
                {
                    name: 'filter',
                    in: 'query',
                    description: 'app_only, embeddable, featured, playable',
                    type: 'embeddable',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'The page number of the results to show.',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of items to show on each page of results, up to a maximum of 100.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/showcaseVideos`] = {
        get: {
            tags: ['Vimeo'],
            summary: 'Showcase Videos',
            description: 'List of videos in a showcase',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'album_id',
                    in: 'query',
                    description: 'Album/Showcase id',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'user id if you want a perticular user album videos',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'alphabetical, comments, date, duration, likes, manual, modified_time, plays',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc',
                    type: 'string',
                },
                {
                    name: 'filter',
                    in: 'query',
                    description: 'app_only, embeddable, featured, playable',
                    type: 'embeddable',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'The page number of the results to show.',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of items to show on each page of results, up to a maximum of 100.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
