exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/url2pdf`] = {
        get: {
            tags: ['HTML to PDF'],
            summary: 'URL to PDF',
            description: 'Convert any web URL into PDF',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'web url to convert into PDF',
                    required: true,
                },
                {
                    name: 'inline',
                    in: 'query',
                    description:
                        'set <b>download</b> to download PDF document & <b>json</b> to get JSON response with PDF remote URL',
                },
                {
                    name: 'ttl',
                    in: 'query',
                    description:
                        'the time (in seconds) a generated PDF is cached. default is (2,592,000 = 30 days)',
                },
                {
                    name: 'force',
                    in: 'query',
                    description: 'set to <b>1</b> to force new PDF',
                },
                {
                    name: 'scope',
                    in: 'query',
                    description:
                        'specify page zoom factor as scope param between <b>0.1</b> to <b>2</b>',
                },
                {
                    name: 'user_agent',
                    in: 'query',
                    description:
                        'You can read about this here <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent" target="_blank">User-Agent</a>.',
                },
                {
                    name: 'document_size',
                    in: 'query',
                    description:
                        'preferred page size, e.g. <b>Letter</b>, <b>Legal</b>, <b>Tabloid</b>, <b>Ledger</b>, <b>A0</b>, <b>A1</b>, <b>A2</b>, <b>A3</b>, <b>A4</b>, <b>A5</b>, <b>A6</b> etc.',
                },
                {
                    name: 'page_width',
                    in: 'query',
                    description:
                        'page width, e.g. <b>200</b> (overrides page_format).',
                },
                {
                    name: 'page_height',
                    in: 'query',
                    description:
                        'page height, e.g. <b>600</b> (overrides page_format).',
                },
                {
                    name: 'orientation',
                    in: 'query',
                    description: 'set to <b>portrait</b> or <b>landscape</b>.',
                },
                {
                    name: 'margin_top',
                    in: 'query',
                    description:
                        'set to preferred top margin value (numeric), e.g. <b>5</b>.',
                },
                {
                    name: 'margin_bottom',
                    in: 'query',
                    description:
                        'set to preferred bottom margin value (numeric), e.g. <b>5</b>.',
                },
                {
                    name: 'margin_left',
                    in: 'query',
                    description:
                        'set to preferred left margin value (numeric), e.g. <b>5</b>.',
                },
                {
                    name: 'margin_right',
                    in: 'query',
                    description:
                        'set to preferred right margin value (numeric), e.g. <b>5</b>.',
                },
                {
                    name: 'footer_template',
                    in: 'query',
                    description: 'set <b>true</b> if you want to show footer.',
                },
                {
                    name: 'footer_title',
                    in: 'query',
                    description:
                        'pass footer title with <b>footer_title</b> query param.',
                },
                {
                    name: 'viewport',
                    in: 'query',
                    description:
                        'Set to preferred viewport "width x height", e.g. <b>320x480</b>.',
                },
                {
                    name: 'footer_template',
                    in: 'query',
                    description: 'characters.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
