exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}`] = {
            get: {
                  tags: ['CSV to JSON'],
                  summary: 'CSV to JSON',
                  description:
                        'Convert your CSV data into JSON API without code and also get data with pagination as your requirements.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'page',
                              in: 'query',
                              description: 'Page number',
                              required: false,
                              type: 'string',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Total number of rows you want in one request. By default is 1000',
                              required: false,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };
      return schemaObj;
};
