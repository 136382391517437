exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Search photos',
            description: 'Get a single page of photo results for a query.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'query',
                    in: 'query',
                    description: 'Search terms.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'How to sort the photos. (Optional; default: relevant). Valid values are latest and relevant',
                    type: 'string',
                },
                {
                    name: 'collections',
                    in: 'query',
                    description: 'Collection ID(‘s) to narrow search. Optional. If multiple, comma-separated.',
                    type: 'string',
                },
                {
                    name: 'content_filter',
                    in: 'query',
                    description:
                        'Limit results by content safety. (Optional; default: low). Valid values are low and high.',
                    type: 'string',
                },
                {
                    name: 'color',
                    in: 'query',
                    description:
                        'Filter results by color. Optional. Valid values are: black_and_white, black, white, yellow, orange, red, purple, magenta, green, teal, and blue.',
                    type: 'string',
                },
                {
                    name: 'orientation',
                    in: 'query',
                    description: 'Filter by photo orientation. Optional. (Valid values: landscape, portrait, squarish)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/photos`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'List photos',
            description: 'Get a single page from the list of all photos.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'How to sort the photos. (Optional; default: relevant). Valid values are latest and relevant',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/randomPhoto`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Get a random photo',
            description: 'Retrieve a single random photo, given optional filters.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'collections',
                    in: 'query',
                    description: 'Public collection ID(‘s) to filter selection. If multiple, comma-separated',
                    type: 'string',
                },
                {
                    name: 'featured',
                    in: 'query',
                    description: 'Limit selection to featured photos.',
                    type: 'string',
                },
                {
                    name: 'username',
                    in: 'query',
                    description: 'Limit selection to a single user.',
                    type: 'string',
                },
                {
                    name: 'query',
                    in: 'query',
                    description: 'Limit selection to photos matching a search term.',
                    type: 'string',
                },
                {
                    name: 'orientation',
                    in: 'query',
                    description: 'Filter by photo orientation. (Valid values: landscape, portrait, squarish)',
                    type: 'string',
                },
                {
                    name: 'content_filter',
                    in: 'query',
                    description: 'Limit results by content safety. Default: low. Valid values are low and high.',
                    type: 'string',
                },
                {
                    name: 'count',
                    in: 'query',
                    description: 'The number of photos to return. (Default: 1; max: 30)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/photoStatistics`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Get a photo’s statistics',
            description:
                'Retrieve total number of downloads, views and likes of a single photo, as well as the historical breakdown of these stats in a specific timeframe (default is 30 days).',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'The public id of the photo. Required.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'resolution',
                    in: 'query',
                    description: 'The frequency of the stats. (Optional; default: “days”)',
                    type: 'string',
                },
                {
                    name: 'quantity',
                    in: 'query',
                    description: 'The amount of for each stat. (Optional; default: 30)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/collections`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'List collections',
            description: 'Get a single page from the list of all collections.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/collectionPhotos`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Collection’s photos',
            description: 'Retrieve a collection’s photos.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'The collection’s ID.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'orientation',
                    in: 'query',
                    description: 'Filter by photo orientation. Optional. (Valid values: landscape, portrait, squarish)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/users`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Users Information',
            description: 'Get a user’s public profile, photos, linked photos, collections, & statistics.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'username',
                    in: 'query',
                    description: 'The user’s username.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'How to sort the photos. (Optional; default: relevant). Valid values are latest and relevant',
                    type: 'string',
                },
                {
                    name: 'stats',
                    in: 'query',
                    description: 'Show the stats for each user’s photo. (Optional; default: false)',
                    type: 'string',
                },
                {
                    name: 'resolution',
                    in: 'query',
                    description: 'The frequency of the stats. (Optional; default: “days”)',
                    type: 'string',
                },
                {
                    name: 'quantity',
                    in: 'query',
                    description: 'The amount of for each stat. (Optional; default: 30)',
                    type: 'string',
                },
                {
                    name: 'orientation',
                    in: 'query',
                    description: 'Filter by photo orientation. Optional. (Valid values: landscape, portrait, squarish)',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/topics`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Topics',
            description: 'Get a single page from the list of all topics.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'ids',
                    in: 'query',
                    description: 'Limit to only matching topic ids or slugs. (Optional; Comma separated string)',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'How to sort the photos. (Optional; default: relevant). Valid values are latest and relevant',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/topicPhotos`] = {
        get: {
            tags: ['Unsplash'],
            summary: 'Topics Photos',
            description: 'Retrieve a topic’s photos.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id_or_slug',
                    in: 'query',
                    description: 'The topics’s ID or slug.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number to retrieve. (Optional; default: 1)',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of items per page. (Optional; default: 10)',
                    type: 'string',
                },
                {
                    name: 'order_by',
                    in: 'query',
                    description:
                        'How to sort the photos. (Optional; default: relevant). Valid values are latest and relevant',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
