import React, { useState } from 'react';
import { Form, Input, Button, Select, message, Alert } from 'antd';
import axios from 'axios';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';

const { Option } = Select;

const GaForm = ({ form, userID, formState }) => {
      const [loading, setLoading] = useState(false);
      const [selctSlackChannel, setSelctSlackChannel] = useState([]);

      const getSlackChannels = (e) => {
            const token = e.target.value;
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/getSlackChannelsList?token=${token}`,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            )
                  .then((result) => {
                        setSelctSlackChannel(result.data.channels);
                  })
                  .catch((err) => {
                        message.error('Bot OAuth Access Token is not valid.');
                  });
      };

      const refreshSlackChannelsList = () => {
            const token = form.getFieldValue('oauth_token');
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/getSlackChannelsList?token=${token}`,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            )
                  .then((result) => {
                        setSelctSlackChannel(result.data.channels);
                  })
                  .catch((err) => {
                        message.error('Bot OAuth Access Token is not valid.');
                  });
      };

      return (
            <>
                  <Form.Item
                        label="Give a name"
                        name="name"
                        rules={[
                              { required: true, message: 'Name is required!' },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Give a name to this API.',
                                    }}
                              />
                        }
                  >
                        <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                        label="Bot User OAuth Access Token"
                        name="oauth_token"
                        rules={[
                              {
                                    required: true,
                                    message: 'OAuth Token is required!',
                              },
                        ]}
                        extra={
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: '1. Create an Slack App Here <a href="https://api.slack.com/apps?new_app=1" target="_blank" rel="noopener noreferrer">https://api.slack.com/apps?new_app=1</a><br/> 2. Install App <br/>3. Add the scopes you want to use (required scopes are "channels:read", "groups:read", "groups:write", "chat:write") <br/>4. After Installation you will get the Bot Token',
                                    }}
                              />
                        }
                  >
                        <Input.Password onChange={getSlackChannels} />
                  </Form.Item>

                  <a
                        style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                        }}
                        onClick={refreshSlackChannelsList}
                  >
                        Refresh List
                  </a>
                  <Form.Item
                        name="channel_id"
                        label="Select Channel"
                        rules={[
                              {
                                    required: true,
                                    message: 'Channel id is required!',
                              },
                        ]}
                  >
                        <Select
                              name="channel_id"
                              placeholder="Select Channel"
                              allowClear
                        >
                              {selctSlackChannel.map((channel, key) => (
                                    <Option value={channel.id} key={key}>
                                          {channel.name}
                                    </Option>
                              ))}
                        </Select>
                  </Form.Item>

                  <Form.Item>
                        <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="make-btn"
                              icon={
                                    formState === 'adding' ? (
                                          <BsFolderPlus />
                                    ) : (
                                          <RiEditBoxFill />
                                    )
                              }
                        >
                              {formState === 'adding' ? 'Create' : 'Edit API'}
                        </Button>
                  </Form.Item>

                  <Alert
                        message="After Create this API add the bot into selected channel"
                        type="info"
                        showIcon
                  />
                  <br />
                  <Alert message="/invite @bot_name" type="success" showIcon />
            </>
      );
};

export default GaForm;
