import React, { useState, useEffect } from 'react';
import { mapToSelectOptions } from '../helpers';

import { Form, Select, Input } from 'antd';

const UserText = ({ handleChange }) => {
  const [formMap, setFormMap] = useState('');
  const [text, setText] = useState('');

  const options = mapToSelectOptions(['My Text', 'My Topic', 'My Example', 'My Structure']);

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const onChangeText = (event) => {
    setFormMap(event.target.value);
    setText(event.target.value);
  };

  const onChangeTextPrefix = (val) => {
    setText(`${val}: `);
  };

  return (
    <Form layout='vertical'>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='Add options to your text'
      >
        <Select
          options={options}
          onChange={onChangeTextPrefix}
          className={'mt-2'}
          placeholder='Add options to your text'
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Text'>
        <Input.TextArea
          placeholder='Your prompt text'
          style={{ height: 120, marginBottom: 24 }}
          value={text}
          onChange={onChangeText}
        />
      </Form.Item>
    </Form>
  );
};

export default UserText;
