exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/campaigns`] = {
        get: {
            tags: ['Mailerlite'],
            summary: 'Get Campaigns',
            description: 'Returns all campaigns you have in your account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'status',
                    in: 'query',
                    description: 'available values: sent, draft, outbox',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'Number of result you want in one API call',
                },
                {
                    name: 'offset',
                    in: 'query',
                    description: 'To navigate for pagination',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/subscribers`] = {
        get: {
            tags: ['Mailerlite'],
            summary: 'Subscribers',
            description: "Get account's subscribers",
            produces: ['application/json'],
            parameters: [
                {
                    name: 'limit',
                    in: 'query',
                    description: 'Number of result you want in one API call',
                },
                {
                    name: 'offset',
                    in: 'query',
                    description: 'To navigate for pagination',
                },
                {
                    name: 'type',
                    in: 'query',
                    description:
                        'available values: active, unsubscribed, bounced, junk, unconfirmed',
                },
                {
                    name: 'groupId',
                    in: 'query',
                    description:
                        'Group id, if you want to filter the subscribers data',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        post: {
            tags: ['Mailerlite'],
            summary: 'Add subscriber',
            description: 'Add new single subscriber',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'groupId',
                    in: 'query',
                    description: 'Group id, if you want to add intt a group',
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an body object, email is required',
                    required: true,
                    sampleData: {
                        email: '',
                        name: '',
                        fields: { company: '', city: '' },
                        type: '',
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/groups`] = {
        get: {
            tags: ['Mailerlite'],
            summary: 'Groups',
            description: 'Get list of groups.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'limit',
                    in: 'query',
                    description: 'Number of result you want in one API call',
                },
                {
                    name: 'offset',
                    in: 'query',
                    description: 'To navigate for pagination',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
