import React, { useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import axios from 'axios';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';

const { Option } = Select;

function formatTelegramUpdates(updatesArray) {
      const messageList = [];
      for (let index = 0; index < updatesArray.length; index++) {
            const msgObj = updatesArray[index];

            if (
                  msgObj.message !== undefined &&
                  msgObj.message.chat !== undefined
            ) {
                  const chatIndex = messageList.findIndex(
                        (x) => x.id === msgObj.message.chat.id
                  );
                  if (chatIndex < 0) {
                        messageList.push(msgObj.message.chat);
                  }
            }

            if (
                  msgObj.channel_post !== undefined &&
                  msgObj.channel_post.chat !== undefined
            ) {
                  const chatIndex = messageList.findIndex(
                        (x) => x.id === msgObj.channel_post.chat.id
                  );
                  if (chatIndex < 0) {
                        messageList.push(msgObj.channel_post.chat);
                  }
            }
      }
      return messageList;
}

const TelegramForm = ({ form, userID, formState }) => {
      const [loading, setLoading] = useState(false);
      const [chatIds, setChatIds] = useState([]);

      const getChatIds = (e) => {
            const apiAccess = e.target.value;
            axios.get(`https://api.telegram.org/bot${apiAccess}/getUpdates`, {
                  headers: { 'Content-Type': 'application/json' },
            })
                  .then((result) => {
                        setChatIds(formatTelegramUpdates(result.data.result));
                  })
                  .catch((err) => {
                        message.error('Your Bot token key is in-valid');
                  });
      };

      const refreshChatIdsList = () => {
            const token = form.getFieldValue('bot_key');
            axios.get(`https://api.telegram.org/bot${token}/getUpdates`, {
                  headers: { 'Content-Type': 'application/json' },
            })
                  .then((result) => {
                        setChatIds(formatTelegramUpdates(result.data.result));
                  })
                  .catch((err) => {
                        message.error('Your Bot token key is in-valid');
                  });
      };

      return (
            <>
                  <Form.Item
                        label="Give a name"
                        name="name"
                        rules={[
                              { required: true, message: 'Name is required!' },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Give a name to this API.',
                                    }}
                              />
                        }
                  >
                        <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                        label="Bot token key"
                        name="bot_key"
                        rules={[
                              {
                                    required: true,
                                    message: 'Bot token key is required!',
                              },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'To get bot token, you have to make a telegram bot with <b>@botfather</b> bot',
                                    }}
                              />
                        }
                  >
                        <Input.Password onChange={getChatIds} />
                  </Form.Item>

                  <a
                        style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                        }}
                        onClick={refreshChatIdsList}
                  >
                        Refresh Dropdown
                  </a>
                  <Form.Item
                        name="chat_id"
                        label="Chat Id"
                        rules={[
                              {
                                    required: true,
                                    message: 'chat_id is required!',
                              },
                        ]}
                        extra={
                              chatIds.length < 1 && (
                                    <div
                                          style={{
                                                fontWeight: 300,
                                                fontSize: 14,
                                          }}
                                          dangerouslySetInnerHTML={{
                                                __html: 'Please send some dummy message to fetch chat ids.',
                                          }}
                                    />
                              )
                        }
                  >
                        <Select
                              name="chat_id"
                              placeholder="Select Chat ID"
                              allowClear
                        >
                              {chatIds.map((chat, key) => (
                                    <Option value={chat.id} key={key}>
                                          {chat.type === 'private'
                                                ? chat.username
                                                : chat.title}
                                          - <b>{chat.type}</b>
                                    </Option>
                              ))}
                        </Select>
                  </Form.Item>

                  <Form.Item>
                        <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="make-btn"
                              icon={
                                    formState === 'adding' ? (
                                          <BsFolderPlus />
                                    ) : (
                                          <RiEditBoxFill />
                                    )
                              }
                        >
                              {formState === 'adding' ? 'Create' : 'Edit API'}
                        </Button>
                  </Form.Item>
            </>
      );
};

export default TelegramForm;
