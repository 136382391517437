import React, {useState, useEffect, useContext} from 'react';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { IoMdCopy } from 'react-icons/io';
import moment from 'moment';
import {
    RiDeleteBinLine,   
} from 'react-icons/ri';
import { Space, Table, message, Popconfirm } from 'antd';
import axios from 'axios';

const ScreenshotList = ({apiObj}) =>{

    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId } = state;
    const [loading, setLoading] = useState(true);
    const [screenshotsData, setScreenshotsData] = useState([]);
    const { cloud_name, app_id, token } = apiObj;

   const fetchScreenshots = async()=> {
        setLoading(true);
        const screenshots = await axios.get(
              `${process.env.GATSBY_BASE_URL}/${cloud_name}/screen/${token}/screenshots`,
              { headers: { 'Content-Type': 'application/json' } }
        );
      if (screenshots.data && screenshots.data.length >0 ) {
          const data = [];
          screenshots.data.map((screenshot, index) => {
              data.push({
                  key: index+1,
                  date: moment(screenshot.created_date).format('DD MMM YYYY'),
                  id: screenshot._id,
                  fileName: screenshot.fileName,
                  location: screenshot.location
              })
          })
          setScreenshotsData(data);
      } else {
          setScreenshotsData([]);
      }
      setLoading(false);
  }

  const deleteScreenshot = async(id) =>{
      axios.delete(`${process.env.GATSBY_BASE_URL}/${cloud_name}/screen/${token}/screenshots/`,
      { params: { _id: id} },
      {
        headers: {
              'Content-Type':
                    'application/json',
        },
    }).then((res)=> {
        message.info(`Screenshot is deleted`);
        fetchScreenshots();
    }).catch((error)=>{
        message.info("Something went wrong!")
    })
  }

  const confirm = (e) => {
    console.log(e);
    message.success('Click on Yes');
  };

  const triggerCopy = (copyURL) => {
      console.log(2342432);
    const textField = document.createElement('textarea');
    textField.innerText = copyURL;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.success('URL copied');
};

    const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                render: (_, record) => (
                    <>
                        <a href={record.location} target="_blank">{record.location}</a> <a onClick={() =>triggerCopy(record.location)} ><IoMdCopy/></a>
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this screenshot?"
                        onConfirm={()=> deleteScreenshot(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >   
                        <a href="#" style={{
                                fontSize: 14,
                                fontWeight: 300,
                                color: '#ff0000',
                            }}><RiDeleteBinLine /></a>
                    </Popconfirm>
                  </Space>
                ),
              },
            ];  
   
      useEffect(() => {
        fetchScreenshots()
      }, []);  

    return (
        <div>
            <Table dataSource={screenshotsData} columns={columns} />
        </div>
    )
}
export default ScreenshotList;