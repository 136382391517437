exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Dribbble'],
            summary: 'Dribbble Profile Info',
            description: 'Get your dribbble profile data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/projects`] = {
        get: {
            tags: ['Dribbble'],
            summary: 'Dribbble Profile Info',
            description: 'Get your dribbble profile data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/shots`] = {
        get: {
            tags: ['Dribbble'],
            summary: 'Shots List',
            description: 'Get your dribbble shots with simple JSON API',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
                {
                    name: 'api_key',
                    in: 'query',
                    description: 'You api key to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
