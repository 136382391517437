import React, { useState, useEffect, useContext } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { RiKey2Line } from 'react-icons/ri';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';
import axios from 'axios';
import { Drawer, Form, Input, Button, message, Upload, Select } from 'antd';

import Editor from '@monaco-editor/react';

import { navigate } from '@reach/router';
import { getAppName, randomString } from '../../config/custom/functions';
import { makeNewAPI, updateAPI } from '../../config/custom/services';
import { Firebase } from '../../config/firebase';
import { formObj } from '../../data/forms';
import ConnectionList from './connectionList';
import SlackForm from './forms/slackForm';
import WebflowForm from './forms/webflowForm';
import TelegramForm from './forms/telegramForm';
import ZohoMailForm from './forms/zohoMailForm';

import { GlobalStateContext } from '../../context/GlobalContextProvider';

import '../../styles/make-form.scss';
import SheetForm from './forms/sheetForm';
import CSVtoJSON from './forms/csvtojson';

let breakTheLoop = false;

const MakeNewAPI = ({
  appId,
  refreshList,
  location,
  apiToEdit,
  formState,
  openDrawerToEdit,
  closeEditDrawer,
  appName,
}) => {
  const state = useContext(GlobalStateContext);
  const [form] = Form.useForm();

  const [makeFormVisible, setMakeFormVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oauthValidation, setOauthValidation] = useState(false);
  const [userID, setUserID] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [sdkObj, setSdkObj] = useState({});
  const [connectionID, setConnectionID] = useState('');
  const formLayout = formObj[appId];

  useEffect(() => {
    if (state.selectedProjectId) {
      const { authType } = formLayout;
      setMakeFormVisible(openDrawerToEdit);
      if (formState === 'editing') {
        form.setFieldsValue(apiToEdit.apis_info);
      }
      async function fetchUser(user) {
        const { authKey, authEndpoint } = formLayout;
        setLoading(true);
        const response = await axios.get(
          `${process.env.GATSBY_BASE_URL}/user/${user.uid}/${state.selectedProjectId}`,
        );
        const isOauthEnable = response.data[authKey];

        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        const realmId = urlParams.get('realmId');
        const formStateValue = urlParams.get('form');
        if (formStateValue !== null) {
          setMakeFormVisible(true);
        }
        if (code !== null && !breakTheLoop && user.uid !== undefined) {
          message.loading('Authenticating...', 10);
          breakTheLoop = true;
          await axios.post(
            authEndpoint,
            {
              code,
              realmId,
              uid: user.uid,
              projectId: window.localStorage.getItem('active_project_id'),
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          navigate(`/dashboard/api/${appId}?form=true`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        setOauthValidation(isOauthEnable);
        setLoading(false);
      }
      if (authType === 'oauth') {
        Firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            fetchUser(user);
            setUserID(user.uid);
          }
        });
      }
    }
  }, [openDrawerToEdit, state.selectedProjectId]);

  const onClose = () => {
    closeEditDrawer(false);
    setMakeFormVisible(false);
  };

  const DrawerTitle = ({ formAppId }) => (
    <div className='drawer-title'>
      <img src={`/logos/${formAppId}.png`} alt={formAppId} height='26px' />
      <h3>{`Setup ${getAppName(formAppId)} API`}</h3>
    </div>
  );

  const createAPI = (values) => {
    setLoading(true);

    values.encrypt = formLayout.encrypt;

    if (appId === 'ga') {
      values.websiteName = '';
      values.websiteUrl = '';
    }

    if (appId === 'fbsdk') {
      values.upload = undefined;
      values.sdkObj = sdkObj;
    }

    const appObj = {
      app_id: appId,
      token: randomString(16, 'aA'),
      apis_info: values,
    };

    Firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const result = await makeNewAPI(
          appObj,
          user.uid,
          formLayout.authType,
          connectionID,
          state.selectedProjectId,
        );
        if (result.status === 201) {
          setLoading(false);
          form.resetFields();
          refreshList();
          setMakeFormVisible(false);
          message.success('API created successfully!');
          navigate(`/dashboard/api/${appId}`);
        } else if (result.status === 200) {
          setLoading(false);
          form.resetFields();
          refreshList();
          setMakeFormVisible(false);
          message.success('API created successfully!');
          navigate(`/dashboard/api/${appId}`);
        } else {
          message.error(result.message);
          setLoading(false);
        }
      }
    });
  };

  const activeConnectionID = (id) => {
    setConnectionID(id);
  };

  const updateThisAPI = (values) => {
    setLoading(true);

    values.encrypt = formLayout.encrypt;

    const appObj = {
      app_id: appId,
      token: apiToEdit.token,
      apis_info: values,
    };

    if (appId === 'google_sheets') {
      apiToEdit.apis_info.name = values.name;
      apiToEdit.apis_info.sheet_id = values.sheet_id;
      appObj.apis_info = apiToEdit.apis_info;
    }

    if (appId === 'ga') {
      apiToEdit.apis_info.websiteName = '';
      apiToEdit.apis_info.websiteUrl = '';
      apiToEdit.apis_info.name = values.name;
      apiToEdit.apis_info.sheet_id = values.id;
      apiToEdit.apis_info.accountId = values.accountId;
      apiToEdit.apis_info.view_id = values.view_id;
      apiToEdit.apis_info.property_id = values.property_id;
      appObj.apis_info = apiToEdit.apis_info;
    }

    if (
      appId === 'yt' ||
      appId === 'github' ||
      appId === 'calendly' ||
      appId === 'mailchimp' ||
      appId === 'spotify' ||
      appId === 'quickbooks'
    ) {
      apiToEdit.apis_info.name = values.name;
      appObj.apis_info = apiToEdit.apis_info;
    }

    Firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const result = await updateAPI(appObj, apiToEdit._id, formLayout.authType);
        if (result.status === 201) {
          setLoading(false);
          form.resetFields();
          refreshList();
          closeEditDrawer(false);
          setMakeFormVisible(false);
          message.success('API updated successfully!');
        } else if (result.status === 200) {
          setLoading(false);
          form.resetFields();
          refreshList();
          closeEditDrawer(false);
          setMakeFormVisible(false);
          message.success('API updated successfully!');
        } else {
          setLoading(false);
        }
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    const { errorFields } = errorInfo;
    for (let k = 0; k < errorFields.length; k++) {
      const errObj = errorFields[k];
      message.error(errObj.errors[0]);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  function handleEditorChange(value, event) {
    console.log(value);
  }

  return (
    <div className='make-new-api'>
      <Button
        type='primary'
        className='make-btn'
        style={{
          fontSize: 13,
          fontWeight: 400,
        }}
        icon={<BsFolderPlus />}
        onClick={() => {
          setMakeFormVisible(true);
          form.resetFields();
        }}
      >
        Create {appName} API
      </Button>
      <Drawer
        width={500}
        title={<DrawerTitle formAppId={appId} />}
        placement='left'
        closable={false}
        onClose={onClose}
        visible={makeFormVisible}
      >
        <div className='make-form'>
          {formLayout.authType === 'oauth' && !oauthValidation ? (
            <div style={{ textAlign: 'center' }}>
              <p style={{ color: '#606060' }}>
                Authenticate first with {getAppName(appId)} Account
              </p>
              <a href={formLayout.authEndpoint} className='authenticate-btn'>
                <Button loading={loading} icon={<RiKey2Line />} type='primary'>
                  Authenticate
                </Button>
              </a>
            </div>
          ) : (
            <>
              {formLayout.authType === 'oauth' && formState === 'adding' && (
                <>
                  <ConnectionList
                    appId={appId}
                    selectedConnectionID={activeConnectionID}
                    authEndpointURL={formLayout.authEndpoint}
                  />

                  <br />
                </>
              )}
            </>
          )}

          {formLayout.authType === 'key' ||
            (formLayout.authType === 'oauth' &&
              oauthValidation &&
              formLayout.formType !== 'dynamicForm') ? (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              autoComplete='off'
              initialValues={initialValues}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              {formLayout.fields.map((f, key) => {
                if (f.type === 'input') {
                  return (
                    <Form.Item
                      label={f.label}
                      name={f.name}
                      rules={f.rules}
                      key={key}
                      extra={
                        <div
                          style={{
                            fontWeight: 300,
                            fontSize: 14,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: f.instructions,
                          }}
                        />
                      }
                    >
                      <Input autoComplete='off' disabled={f.readOnly} />
                    </Form.Item>
                  );
                }
                if (f.type === 'inputPwd') {
                  return (
                    <Form.Item
                      label={f.label}
                      name={f.name}
                      rules={f.rules}
                      key={key}
                      extra={
                        <div
                          style={{
                            fontWeight: 300,
                            fontSize: 14,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: f.instructions,
                          }}
                        />
                      }
                    >
                      <Input.Password disabled={f.readOnly} />
                    </Form.Item>
                  );
                }
                if (f.type === 'jsonInput') {
                  return (
                    <Form.Item
                      label={f.label}
                      name={f.name}
                      rules={f.rules}
                      key={key}
                      extra={
                        <div
                          style={{
                            fontWeight: 300,
                            fontSize: 14,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: f.instructions,
                          }}
                        />
                      }
                    >
                      <Editor
                        width='800'
                        height='20vh'
                        language='json'
                        theme='vs'
                        value={JSON.stringify({}, null, 2)}
                        options={{
                          selectOnLineNumbers: true,
                          minimap: {
                            enabled: false,
                          },
                          lineNumbers: false,
                        }}
                        onChange={handleEditorChange}
                      />
                    </Form.Item>
                  );
                }

                if (f.type === 'uploadFile') {
                  return (
                    <Form.Item
                      name='upload'
                      label='Private key json file'
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      extra='Upload service account json file'
                    >
                      <Upload
                        name={f.name}
                        listType='picture'
                        beforeUpload={(file) => {
                          const reader = new FileReader();

                          reader.onload = (e) => {
                            setSdkObj(JSON.parse(e.target.result));
                          };
                          reader.readAsText(file);

                          // Prevent upload
                          return false;
                        }}
                      >
                        <Button icon={<UploadOutlined />}>Upload JSON file</Button>
                      </Upload>
                    </Form.Item>
                  );
                }
                if (f.type === 'dropdown') {
                  return (
                    <Form.Item label={f.label} name={f.name} rules={f.rules} key={key}>
                      <Select>
                        {f.dropdown.map((d) => {
                          return <Select.Option value={d}>{d}</Select.Option>;
                        })}
                      </Select>
                    </Form.Item>
                  );
                }
              })}

              <Form.Item>
                <Button
                  loading={loading}
                  htmlType='submit'
                  type='primary'
                  className='make-btn'
                  icon={formState === 'adding' ? <BsFolderPlus /> : <RiEditBoxFill />}
                >
                  {formState === 'adding' ? 'Create' : 'Edit API'}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            ''
          )}

          {formLayout.formType === 'dynamicForm' && appId === 'csv2json' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
                websiteName: '',
                websiteUrl: '',
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <CSVtoJSON
                formLayout={formLayout}
                userID={userID}
                form={form}
                formState={formState}
              />
            </Form>
          )}

          {formLayout.formType === 'dynamicForm' && appId === 'slack' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
                websiteName: '',
                websiteUrl: '',
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <SlackForm
                formLayout={formLayout}
                userID={userID}
                form={form}
                formState={formState}
              />
            </Form>
          )}

          {formLayout.formType === 'dynamicForm' && appId === 'webflow' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <WebflowForm
                formLayout={formLayout}
                userID={userID}
                form={form}
                formState={formState}
              />
            </Form>
          )}
          {formLayout.formType === 'dynamicForm' && appId === 'google_sheets' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <SheetForm
                formLayout={formLayout}
                userID={userID}
                form={form}
                connectionID={connectionID}
                formState={formState}
              />
            </Form>
          )}
          {formLayout.formType === 'dynamicForm' && appId === 'zohomail' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <ZohoMailForm
                formLayout={formLayout}
                userID={userID}
                form={form}
                formState={formState}
                connectionID={connectionID}
                oauthValidation={oauthValidation}
              />
            </Form>
          )}

          {formLayout.formType === 'dynamicForm' && appId === 'telegram' && (
            <Form
              form={form}
              layout='vertical'
              name={formLayout.name}
              initialValues={{
                remember: true,
              }}
              onFinish={formState === 'adding' ? createAPI : updateThisAPI}
              onFinishFailed={onFinishFailed}
            >
              <TelegramForm
                formLayout={formLayout}
                userID={userID}
                form={form}
                formState={formState}
              />
            </Form>
          )}
        </div>
      </Drawer>
    </div>
  );
};
export default MakeNewAPI;
