exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Calendly'],
            summary: 'List of Events',
            description: 'Returns all events for user which is assigned as event publisher.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'status',
                    in: 'query',
                    description: '[active, canceled] Filter events by their status',
                    type: 'string',
                },
                {
                    name: 'min_start_time',
                    in: 'query',
                    description: 'Earliest time for event starting time, inclusive',
                    type: 'string',
                },
                {
                    name: 'max_start_time',
                    in: 'query',
                    description: 'Latest time for event starting time, exclusive',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: '[asc, desc] Order by event start time ascending or descending',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Pagination page number. Must be greater than or equal to 1',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Items per pagination page. Between 1 and 50 per request',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/invitees`] = {
        get: {
            tags: ['Calendly'],
            summary: 'List of Event Invitees',
            description: 'List of all invitees in a events.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'uuid',
                    in: 'query',
                    description: 'UUID of Event.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'status',
                    in: 'query',
                    description: '[active, canceled] Filter events by their status',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Pagination page number. Must be greater than or equal to 1',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Items per pagination page. Between 1 and 50 per request',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
