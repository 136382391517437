exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/screenshot`] = {
        get: {
            tags: ['Web Screenshot'],
            summary: 'Take Screenshot',
            description: 'Create screenshot for any web url',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description:
                        'The full URL (including the HTTP & HTTPS Protocol) of the website you want to take screenshot. <b>https://google.com</b>',
                    required: true,
                },
                {
                    name: 'file_name',
                    in: 'query',
                    description:
                        'Specify a image name of up to 180 characters.',
                },
                {
                    name: 'inline',
                    in: 'query',
                    description:
                        'set to <b>show</b> to display screenshot, <b>download</b> to download screenshot & <b>json</b> to get JSON response with image URL',
                },
                {
                    name: 'full_page',
                    in: 'query',
                    description:
                        'set <b>true</b> if you want to take full page screenshot.',
                },
                {
                    name: 'delay',
                    in: 'query',
                    description:
                        'specify a delay before screenshot is captured (in seconds)',
                },
                {
                    name: 'viewport',
                    in: 'query',
                    description:
                        'specify your preferred viewport dimensions in pixels. default 1440x900',
                },
                {
                    name: 'encoding',
                    in: 'query',
                    description:
                        'The encoding of the image, can be either <b>base64</b> or <b>binary</b>',
                },
                {
                    name: 'format',
                    in: 'query',
                    description:
                        'set your preferred image output format. default is <b>png</b>',
                },
                {
                    name: 'user_agent',
                    in: 'query',
                    description:
                        'You can read about this here <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent" target="_blank">User-Agent</a>.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/screenshots`] = {
        get: {
            tags: ['Fetch Screenshots'],
            summary: 'Fetch All Screenshots',
            description: 'Get all the previous screenshots',
            produces: ['application/json'],
            parameters: [
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj;
};
