import React, { useEffect, useState } from "react";
import CodeSnippet from "./codeSnippet";
import { Select } from "antd";

import "../../styles/code-block.scss";

const { Option } = Select;
const options = [
  {
    activeLang: "cURL",
    name: "cURL",
  },
  {
    activeLang: "Python",
    name: "Python",
  },
  {
    activeLang: "Node",
    name: "Node.js",
  },
  {
    activeLang: "JavaScriptFetch",
    name: "JavaScript - Fetch",
  },
  {
    activeLang: "JavaScript",
    name: "JavaScript - jQuery",
  },
  {
    activeLang: "PHP",
    name: "PHP",
  },
  {
    activeLang: "RUBY",
    name: "Ruby",
  },
];

const CodeBlocks = ({ type, url, params, body, bodyType, formData, token }) => {
  const [activeLang, setActiveLang] = useState("JavaScriptFetch");

  useEffect(() => {
    console.log("url changed");
  }, [url]);

  const handleTab = (value) => {
    setActiveLang(value);
  };

  return (
    <div className="code-block">
      <Select
        defaultValue="JavaScript"
        style={{ width: 200, marginBottom: "43px" }}
        onChange={handleTab}
      >
        {type === "get" && <Option value="liveTest">Live test</Option>}
        {options.map((opt, key) => (
          <Option value={opt.activeLang} key={key}>
            {opt.name}
          </Option>
        ))}
      </Select>
      {activeLang === "Node" && (
        <div className="tab-container">
          <CodeSnippet
            language="javascript"
            token={token}
            id={`copyCode${activeLang}`}
            code={`const axios = require('axios');

axios({
    method: '${type}',
    url: '${url}', ${
              params !== undefined
                ? `\n    params: {${Object.keys(params).map(
                    (key) => `${key}: '${params[key]}'`
                  )}},`
                : ""
            }${
              body !== undefined
                ? `\n    data: ${JSON.stringify(body, null)}`
                : ""
            }
}).then(function (response) {
        // handle success
        console.log(response.data);
}).catch(function (error) {
        // handle error
        console.log(error);
})`}
          />
        </div>
      )}
      {activeLang === "liveTest" && type === "get" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }`}
            language="shell"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "cURL" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`curl --location --request ${type.toUpperCase()} "${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }" ${
              body !== undefined
                ? ` --header 'Content-Type: application/json' --data-raw ${JSON.stringify(
                    `${JSON.stringify(body, null)}`
                  )}`
                : ""
            } ${formData ? `--data-binary "@/path/to/file"` : ""}`}
            language="shell"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "Python" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`import requests

url = "${url}"${
              params !== undefined
                ? `\nparams = {${Object.keys(params).map(
                    (key) => `"${key}": "${params[key]}"`
                  )}}`
                : ""
            }${
              body !== undefined ? `\ndata = ${JSON.stringify(body, null)}` : ""
            }
r = requests.${type}(url = url${
              params !== undefined ? `, params = params` : ""
            }${body !== undefined ? `, json = data` : ""})
result = r.json()
print(result)`}
            language="python"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "PHP" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`<?php

$curl = curl_init();

curl_setopt_array($curl, array(
    CURLOPT_URL => "${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }",
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => '${type.toUpperCase()}',
    CURLOPT_POSTFIELDS =>'${
      body !== undefined ? `${JSON.stringify(body, null)}` : `{}`
    }',
    CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json'
    ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`}
            language="php"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "RUBY" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`require "uri"
require "net/http"
                        
url = URI("${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

${type === "get" ? `request = Net::HTTP::Get.new(url)` : ""}${
              type === "post" ? `request = Net::HTTP::Post.new(url)` : ""
            }${type === "put" ? `request = Net::HTTP::Put.new(url)` : ""}${
              type === "delete" ? `request = Net::HTTP::Delete.new(url)` : ""
            }
request["Content-Type"] = "application/json"
${
  body !== undefined
    ? `request.body = ${JSON.stringify(`${JSON.stringify(body, null)}`)}`
    : ""
}
response = https.request(request)
puts response.read_body`}
            language="ruby"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "JavaScript" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`var settings = {
    "url": "${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }",
    "method": "${type}",
    "timeout": 0,
    "headers": {
        "Content-Type": "application/json"
    },${
      body !== undefined
        ? `\ndata = JSON.stringify(${JSON.stringify(body, null)})}`
        : ""
    }
};

$.ajax(settings).done(function (response) {
    console.log(response);
});`}
            language="python"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {activeLang === "JavaScriptFetch" && (
        <div className="tab-container">
          <CodeSnippet
            token={token}
            code={`var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
var requestOptions = {
    method: "${type}",
    headers: myHeaders,
    redirect: "follow",
    ${
      body !== undefined
        ? `body: JSON.stringify(${JSON.stringify(body, null)})`
        : ""
    }
};

fetch("${url}${
              params !== undefined && Object.keys(params).length > 0
                ? `?${Object.keys(params)
                    .map((key) => `${key}=${params[key]}`)
                    .join("&")}`
                : ""
            }", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`}
            language="javascript"
            id={`copyCode${activeLang}`}
          />
        </div>
      )}

      {/* {activeLang === "liveTest" ? (
                <Button type="primary" href={url} target="_blank">
                    Make test request
                </Button>
            ) : (
                <Button type="primary" onClick={copyAPICode}>
                    Copy code
                </Button>
            )} */}
    </div>
  );
};

export default CodeBlocks;
