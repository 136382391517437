exports.obj = function (username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/data`] = {
      post: {
        tags: ["Forms"],
        summary: "Send form data to NocodeAPI",
        description: "Send all your form fields to your NoCodeAPI DB, save them and then use them as a JSON format to build applications ",
        produces: ["application/json"],
        parameters: [
          {
            name: "body",
            in: "body",
            description: "Pass an array of row array into body",
            required: true,
            type: "string",
            sampleData: {
                fields: [
                      {
                            field: 'email',
                            value: 'test@nocodeapi.com',
                      },
                      {
                            field: 'firstname',
                            value: 'Adrian',
                      },
                      {
                            field: 'lastname',
                            value: 'Mott',
                      },
                      {
                            field: 'website',
                            value: 'http://hubspot.com',
                      },
                      {
                            field: 'phone',
                            value: '555-122-2323',
                      },
                      {
                            field: 'address',
                            value: '25 First Street',
                      },
                      {
                            field: 'city',
                            value: 'Cambridge',
                      },
                      {
                            field: 'state',
                            value: 'MA',
                      },
                      {
                            field: 'zip',
                            value: '02139',
                      },
                ],
            }
          },
          {
            name: 'email',
            in: 'query',
            description: 'Your email on which you want to receive form submission data. Feature only available to PRO users',
        }
        ],
        responses: {
          200: {
            description: "successful operation",
          },
        },
      },
      delete: {
            tags: ["Forms"],
            summary: "Remove stored data",
            description: "Removed stored data in Nocode database",
            produces: ["application/json"],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'Id of record to delete',
                    required: true
                },
                
            ],
            responses: {
              200: {
                description: "successful operation",
              },
            },
          },
    };
    schemaObj[`/${username}/${appId}/${token}/search`] = {
      get: {
        tags: ["Forms"],
        summary: "Search in form data",
        description: "Search or get your form data from NoCode Database",
        produces: ["application/json"],
        parameters: [
            {
                name: 'field',
                in: 'query',
                description: 'Field name to search',
            },
            {
                name: 'value',
                in: 'query',
                description: 'Field value to search',
            }
        ],
        responses: {
          200: {
            description: "successful operation",
          },
        },
      },
    };
    return schemaObj;
  };
  