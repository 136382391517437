import { mapToSelectOptions } from '../helpers/index';
export const helpMeWith = mapToSelectOptions([
  'Generating new ideas and concepts on my topic',
  'Offering different perspectives on my topic',
  'Connecting ideas and associations',
  'Organisation and structure',
  'Identifying potential problems and proposing solutions',
  'Generating of new questions and angles to explore',
  'References or resources',
  'Generating of names',
  'Generating a plan of action to move forward',
  'Generating viral ideas',
  'Generating product ideas',
  'Generating startup ideas',
]);

export const style = mapToSelectOptions([
  'Formal',
  'Informal',
  'Casual',
  'Emotional',
  'Creative',
  'Persuasive',
  'Business',
  'Technical',
  'Legal',
  'Medical',
  'Academic',
]);
export const tone = mapToSelectOptions([
  'Funny',
  'Serious',
  'Friendly',
  'Professional',
  'Empathetic',
  'Confident',
  'Enthusiastic',
  'Assertive',
  'Encouraging',
  'Excited',
  'Witty',
  'Sympathetic',
  'Analytical',
  'Authoritative',
  'Romantic',
]);
export const language = mapToSelectOptions([
  'Spanish',
  'French',
  'German',
  'Italian',
  'Arabic',
  'Japanese',
  'Chinese',
  'US English',
  'UK English',
  'Australian English',
  'New Zealand English',
  'Irish',
  'Scottish',
  'Swiss German',
  'Berliner German',
  'Kölsch German',
  'Add your language',
]);
export const inTheVoiceOf = mapToSelectOptions([
  'Socrate',
  'Shakespeare',
  'Albert Einstein',
  'Add other famous personas here',
]);
export const slang = mapToSelectOptions([
  'Bro Talk',
  'Sassy girl Talk',
  'Snoop dogg Talk',
  '40s Gangster Talk',
  'Use your imagination and add :)',
]);
export const readerComprehension = mapToSelectOptions([
  '5 years old',
  '10 years old',
  '25 years old',
  '85 years old',
  'IQ 69',
  'IQ 115',
  'IQ 150',
  'beginner, assume no prior knowledge',
  'intermediate, assume some prior knowledge',
  'advanced, assume extensive prior knowledge',
  'PhD Graduate',
  'Triple PhD galactic brain',
]);
export const length = mapToSelectOptions([
  '280 characters',
  'Short, simple and to the point',
  '1 Paragraph',
  '3 Paragraphs',
  '100 Words',
  '300 Words',
  '500 Words',
  '1000 Words',
]);
export const outputAs = mapToSelectOptions([
  'Numbered list',
  'Bulleted list',
  'Bulleted list with nested items',
  'Task List',
  'Markdown table',
  'Markdown table with Column 1: Number',
  'Blockquote',
  'Code block',
  'JSON',
  'CSV',
  'YAML',
  'XML',
  'SQL',
]);
export const formatting = mapToSelectOptions([
  'Bold the important words',
  'Only the text. No comments before and after.',
  'Highlight key words and phrases',
]);
