import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Form, Input, Menu, Drawer, Alert, Empty, Button, List, Skeleton, Popconfirm } from 'antd';
import { randomString } from '../../config/custom/functions';

import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { SiFastapi } from 'react-icons/si';

const DrawerTitle = () => (
    <div className="drawer-title">
        <h3>API Tokens</h3>
    </div>
);

const ViewAPITokens = ({ token }) => {
    const [showTokenForm, setShowTokenForm] = useState(false);
    const [form] = Form.useForm();

    const state = useContext(GlobalStateContext);
    const { selectedProjectId, user } = state;

    const [loading, setLoading] = useState(false);
    const [showAPITokens, setshowAPITokens] = useState(false);
    const [tokenList, setTokenList] = useState([]);



    const addNewToken = async (values) => {
        const dataObj = { secondaryToken: randomString(16, 'aA'), tokenDescription: values.tokenDesc }
        const response = await axios.post(
            `${process.env.GATSBY_BASE_URL}/secondary-token/${user.uid}/${selectedProjectId}?token=${token}`,
            dataObj,
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );
        if (response.status === 200) {
            getAPITokens();
            setShowTokenForm(false);
            form.resetFields();
        }
    }

    const getAPITokens = async () => {
        setshowAPITokens(true);
        setLoading(true);
        const response = await axios.get(
            `${process.env.GATSBY_BASE_URL}/secondary-token/${user.uid}/${selectedProjectId}?token=${token}`
        );
        if (response && response.data) {
            setTokenList(response.data.secondary_tokens);
        }
        setLoading(false);

    };

    const deleteToken = async (tokenToDelete) => {

        const response = await axios.delete(
            `${process.env.GATSBY_BASE_URL}/secondary-token/${user.uid}/${selectedProjectId}?token=${token}&deleteToken=${tokenToDelete}`
        );
        console.log(response.status);
        if (response.status === 200) {
            getAPITokens();
        }
    };


    return (
        <div className="view-logs">
            <Menu.Item
                style={{
                    fontSize: 14,
                    fontWeight: 300,
                    color: '#3c3c3c',
                }}
                onClick={getAPITokens}
                key="1"
                icon={<SiFastapi size={14} />}
            >
                View & Create New API Tokens
            </Menu.Item>

            <Drawer
                width={700}
                title={<DrawerTitle />}
                placement="left"
                closable={false}
                onClose={() => setshowAPITokens(false)}
                open={showAPITokens}
            >
                {tokenList ? (
                    <>
                        <List
                            className="demo-loadmore-list"
                            loading={loading}
                            itemLayout="horizontal"
                            dataSource={tokenList}
                            renderItem={(item) => (
                                <List.Item
                                    actions={[
                                        <Popconfirm
                                            title="Are you sure to delete this token?"
                                            onConfirm={() => deleteToken(item.token)}
                                            okText="Yes"
                                            cancelText="No">
                                            <a key="list-loadmore-edit">Delete</a>
                                        </Popconfirm>

                                    ]}
                                >
                                    <Skeleton avatar title={false} loading={loading} active>
                                        <List.Item.Meta
                                            title={item.token_desc}
                                        />
                                        <List.Item.Meta
                                            title={item.token}
                                        />

                                        <div>{moment(item.created_date).format('DD MMM YYYY')}</div>
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                        {
                            !showTokenForm &&
                            <Button
                                type="primary"
                                className="make-btn"
                                style={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    margin: "0 auto"
                                }}
                                onClick={() => setShowTokenForm(true)}
                            >
                                Add New API Token
                            </Button>
                        }

                    </>
                ) : (
                    <>
                        {!showTokenForm &&
                            <Empty
                                description="There are no secondary tokens added."
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />}

                        {
                            !showTokenForm &&
                            <Button
                                type="primary"
                                className="make-btn"
                                style={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    margin: "0 auto"
                                }}
                                onClick={() => setShowTokenForm(true)}
                            >
                                Add New API Token
                            </Button>
                        }

                    </>
                )}

                {
                    showTokenForm &&
                    <Form
                        name="secondaryTokenFrm"
                        form={form}
                        onFinish={addNewToken}
                        style={{
                            marginTop: 20
                        }}
                    >
                        <Form.Item label="Token Description" name="tokenDesc" rules={[
                            {
                                required: true,
                                message: 'Please enter token description',
                            },
                        ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="make-btn"
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </Drawer>
        </div>
    );
};

export default ViewAPITokens;
