exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}/list_table`] = {
    get: {
      tags: ['Nocode Database', 'Database'],
      summary: 'Get All Database Tables',
      description:
        'List all database tables in PostgreSQL. By default 20 tables are included, will need to be an enterprise user to get more',
      produces: ['application/json'],
      parameters: [],
      responses: {
        200: {
          description: 'Operation was successful.',
        },
      },
    },
  };
  schemaObj[`/${username}/${appId}/${token}/update_rows`] = {
    put: {
      tags: ['Nocode Database', 'Database'],
      summary: 'Update Row',
      description: 'Update row in database using ID',
      produces: ['application/json'],
      parameters: [
        {
          name: 'table_name',
          in: 'query',
          description: 'Name of table you want to search in ',
          required: true,
          type: 'string',
        },
        {
          name: 'identifier',
          in: 'query',
          description: 'Field name by which you want to update the row',
          required: true,
          type: 'string',
        },
        {
          name: 'identifier_value',
          in: 'query',
          description: 'Field value by which you want to update the row',
          required: true,
          type: 'string',
        },
        {
          name: 'update_fields',
          in: 'body',
          description: 'List of field and values you want to update',
          required: true,
          sampleData: {
            name: 'Nocode',
            email: 'nocode@nocode.com',
            time: '1348853312',
          },
        },
      ],
      responses: {
        200: {
          description: 'Operation was successful.',
        },
      },
    },
  };
  schemaObj[`/${username}/${appId}/${token}/list_rows`] = {
    get: {
      tags: ['Nocode Database', 'Database'],
      summary: 'List table rows',
      description: 'List table data',
      produces: ['application/json'],
      parameters: [
        {
          name: 'table_name',
          in: 'query',
          description: 'Name of table you want to search in ',
          required: true,
          type: 'string',
        },
        {
          name: 'projection',
          in: 'query',
          description: 'Fields to include in search result, separated by comma (,)',
          required: false,
          type: 'string',
        },
        {
          name: 'pagination_next_index',
          in: 'query',
          description:
            'Index to start the pagination from, for eg: if 10 record is being shown then your next index will be from 10',
          required: false,
          type: 'string',
        },
        {
          name: 'sort_by',
          in: 'query',
          description: 'Fields separated by comma (,)',
          required: false,
          type: 'string',
        },
        {
          name: 'sort_order',
          in: 'query',
          description: 'values accepted are asc and desc ',
          required: false,
          type: 'string',
        },
        {
          name: 'limit',
          in: 'query',
          description:
            'No. of rows to be returned, by default 10, will need to be enterprise to get more',
          required: false,
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'Operation was successful.',
        },
      },
    },
  };
  schemaObj[`/${username}/${appId}/${token}/search_rows`] = {
    get: {
      tags: ['Nocode Database', 'Database'],
      summary: 'Search in a table',
      description: 'Search in a table',
      produces: ['application/json'],
      parameters: [
        {
          name: 'table_name',
          in: 'query',
          description: 'Name of table you want to search in ',
          required: true,
          type: 'string',
        },
        {
          name: 'search_string',
          in: 'query',
          description: 'String to search',
          required: true,
          type: 'string',
        },
        {
          name: 'search_field',
          in: 'query',
          description: 'Field name to search in',
          required: true,
          type: 'string',
        },
        {
          name: 'projection',
          in: 'query',
          description: 'Fields to include in search result, separated by comma (,)',
          required: false,
          type: 'string',
        },
        {
          name: 'pagination_next_index',
          in: 'query',
          description:
            'Index to start the pagination from, for eg: if 10 record is being shown then your next index will be from 10',
          required: false,
          type: 'string',
        },
        {
          name: 'sort_by',
          in: 'query',
          description: 'Fields separated by comma (,)',
          required: false,
          type: 'string',
        },
        {
          name: 'sort_order',
          in: 'query',
          description: 'values accepted are asc and desc ',
          required: false,
          type: 'string',
        },
        {
          name: 'limit',
          in: 'query',
          description:
            'No. of rows to be returned, by default 20, will need to be enterprise to get more',
          required: false,
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'Operation was successful.',
        },
      },
    },
  };
  return schemaObj;
};
