exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}/customers`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'List of customers',
                  description: 'Retrieves a list of customers.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Show only certain fields, specified by a comma-separated list of field names.',
                        },
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'Restrict results to customers specified by a comma-separated list of IDs.',
                        },
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The maximum number of results to show.',
                        },
                        {
                              name: 'since_id',
                              in: 'query',
                              description:
                                    'Restrict results to those after the specified ID.',
                        },
                        {
                              name: 'created_at_max',
                              in: 'query',
                              description:
                                    'Show customers created before a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'created_at_min',
                              in: 'query',
                              description:
                                    'Show customers created after a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'updated_at_max',
                              in: 'query',
                              description:
                                    'Show customers last updated before a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'updated_at_min',
                              in: 'query',
                              description:
                                    'Show customers last updated after a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },

            post: {
                  tags: ['Shopify'],
                  summary: 'Create customer',
                  description: 'Creates a customer',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description: 'Customer object',
                              required: true,
                              sampleData: {
                                    customer: {
                                          first_name: 'Steve',
                                          last_name: 'Lastnameson',
                                          email: 'steve.lastnameson@example.com',
                                          phone: '+15142546011',
                                          verified_email: true,
                                          addresses: [
                                                {
                                                      address1: '123 Oak St',
                                                      city: 'Ottawa',
                                                      province: 'ON',
                                                      phone: '555-1212',
                                                      zip: '123 ABC',
                                                      last_name: 'Lastnameson',
                                                      first_name: 'Mother',
                                                      country: 'CA',
                                                },
                                          ],
                                    },
                              },
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },

            put: {
                  tags: ['Shopify'],
                  summary: 'Update customer',
                  description: 'Updates a customer',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description: 'Customer object',
                              required: true,
                              sampleData: {
                                    customer: {
                                          email: 'changed@email.address.com',
                                          note: 'Customer is a great guy',
                                    },
                              },
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/customer`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'Customer',
                  description: 'Retrieves a single customer.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'Customer ID',
                              required: true,
                        },
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Show only certain fields, specified by a comma-separated list of field names.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/customerOrders`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'Customer Orders',
                  description:
                        'Retrieves all orders that belong to a customer.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'Customer ID',
                              required: true,
                        },
                        {
                              name: 'status',
                              in: 'query',
                              description:
                                    'The status of the orders to return. The status defaults to open.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/orders`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'List of orders',
                  description:
                        'Retrieves a list of orders that meet the specified criteria.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Retrieve only certain fields, specified by a comma-separated list of fields names.',
                        },
                        {
                              name: 'status',
                              in: 'query',
                              description:
                                    '<b>any</b>: Show orders of any status, including archived orders. <b>open</b>: Show only open orders. closed: Show only closed orders. <b>cancelled</b>: Show only canceled orders.',
                        },
                        {
                              name: 'created_at_max',
                              in: 'query',
                              description:
                                    'Show orders created at or before date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'created_at_min',
                              in: 'query',
                              description:
                                    'Show orders created at or after date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'financial_status',
                              in: 'query',
                              description:
                                    'Filter orders by their financial status.',
                        },
                        {
                              name: 'fulfillment_status',
                              in: 'query',
                              description:
                                    'Filter orders by their fulfillment status.',
                        },
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'Retrieve only orders specified by a comma-separated list of order IDs.',
                        },
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The maximum number of results to show on a page.',
                        },
                        {
                              name: 'processed_at_max',
                              in: 'query',
                              description:
                                    'Show orders imported at or before date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'processed_at_min',
                              in: 'query',
                              description:
                                    'Show orders imported at or after date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'since_id',
                              in: 'query',
                              description:
                                    'Show orders after the specified ID.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/singleOrder`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'Single Order',
                  description:
                        'Retrieve an order by specifying the ID. All fields of an order are returned unless specific fields are named.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'order id',
                              required: true,
                        },
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Retrieve only certain fields, specified by a comma-separated list of fields names.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/products`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'Products',
                  description: 'Retrieve a list of products.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Retrieve only certain fields, specified by a comma-separated list of fields names.',
                        },
                        {
                              name: 'collection_id',
                              in: 'query',
                              description:
                                    'Return products by product collection ID.',
                        },
                        {
                              name: 'created_at_max',
                              in: 'query',
                              description:
                                    'Return products created before a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'created_at_min',
                              in: 'query',
                              description:
                                    'Return products created after a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'handle',
                              in: 'query',
                              description:
                                    'Return only products specified by a comma-separated list of product handles.',
                        },
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'Return only products specified by a comma-separated list of product IDs.',
                        },
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'Return up to this many results per page.',
                        },
                        {
                              name: 'presentment_currencies',
                              in: 'query',
                              description:
                                    'Return presentment prices in only certain currencies, specified by a comma-separated list of <a href="https://en.wikipedia.org/wiki/ISO_4217" target="_blank">ISO 4217</a> currency codes.',
                        },
                        {
                              name: 'product_type',
                              in: 'query',
                              description: 'Return products by product type.',
                        },
                        {
                              name: 'published_at_max',
                              in: 'query',
                              description:
                                    'Return products published before a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                        {
                              name: 'published_at_min',
                              in: 'query',
                              description:
                                    'Return products published after a specified date. (format: 2014-04-25T16:15:47-04:00)',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/singleProduct`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'Single Product',
                  description: 'Retrieve a single product.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'product id',
                              required: true,
                        },
                        {
                              name: 'fields',
                              in: 'query',
                              description:
                                    'Retrieve only certain fields, specified by a comma-separated list of fields names.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/payouts`] = {
            get: {
                  tags: ['Shopify'],
                  summary: 'All payouts',
                  description: 'Return a list of all payouts',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'date',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made on the specified date.',
                        },
                        {
                              name: 'date_max',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made inclusively before the specified date.',
                        },
                        {
                              name: 'date_min',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made inclusively after the specified date.',
                        },
                        {
                              name: 'last_id',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made before the specified ID.',
                        },
                        {
                              name: 'since_id',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made after the specified ID.',
                        },
                        {
                              name: 'status',
                              in: 'query',
                              description:
                                    'Filter the response to payouts made with the specified status.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      return schemaObj;
};
