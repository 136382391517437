import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';

import {
      Select,
      Modal,
      Form,
      Button,
      Input,
      Collapse,
      Alert,
      Progress,
} from 'antd';
import ConnectorAPIPlayground from '../connectorPLayground';
import { Firebase } from '../../../config/firebase';

import { isValidJson, recursiveSearch } from '../../../config/custom/functions';

import {
      GlobalDispatchContext,
      GlobalStateContext,
} from '../../../context/GlobalContextProvider';

const _ = require('lodash');
const { Panel } = Collapse;
const { Option } = Select;

function getNestedObject(nestedObj, pathArr) {
      const arrayPath = pathArr.split('.');
      const objectKeyValue = arrayPath.reduce(
            (obj, key) =>
                  obj && obj[key] !== 'undefined' ? obj[key] : undefined,
            nestedObj
      );

      if (typeof objectKeyValue === 'object') {
            return JSON.stringify(objectKeyValue);
      } else {
            return objectKeyValue;
      }
}

const RenderReactJSON = (props) => {
      if (typeof window !== 'undefined') {
            const ReactJson = require('react-json-view').default;
            return <ReactJson {...props} />;
      }
      return null;
};

export default function SheetImporter({ activeAPIData }) {
      const state = useContext(GlobalStateContext);
      const [isModalVisible, setIsModalVisible] = useState(false);
      const [apiResult, setApiResult] = useState({});
      const [tableResult, setTableResult] = useState([]);
      const [tableColumns, setTableColumns] = useState([]);
      const [tableColumns2, setTableColumns2] = useState([]);
      const [mapKeys, setMapKeys] = useState({});
      const [errorMessage, setErrorMessage] = useState('');
      const [errorType, setErrorType] = useState('info');
      const [loading, setLoading] = useState(false);
      const [airtableLink, setAirtableLink] = useState('');
      const [panelActiveKey, setPanelActiveKey] = useState('1');
      const [sheetAPIs, setSheetAPIs] = useState([]);
      const [activeApiToken, setActiveApiToken] = useState('');
      const [activeApiUserName, setActiveApiUserName] = useState('');
      const [activeTabId, setActiveTabId] = useState('');
      const [activeSheetId, setActiveSheetId] = useState('');
      const [tabList, setTabList] = useState([]);
      const [isGoodToGo, setIsGoodToGo] = useState(false);
      const [progressCount, setProgressCount] = useState(0);
      const [loadingTabs, setLoadingTabs] = useState(false);
      const [selectedSheetName, setSelectedSheetName] = useState('');

      // useEffect(() => {
      //     console.log('j', activeAPIData);
      // }, []);

      useEffect(() => {
            selectTargetSheet();
      }, []);

      const children = [];
      for (let i = 0; i < tableColumns.length; i++) {
            children.push(
                  <Option key={tableColumns[i].id}>{tableColumns[i].id}</Option>
            );
      }

      function handleChange(value) {
            const colArray = String(value).split(',');
            setMapKeys(_.zipObject([...colArray], [...colArray]));
            setTableColumns2(colArray);
      }

      const sendResultBack = (result) => {
            setApiResult(result);
            setTableColumns2([]);
            setTableColumns([]);
            setTableResult([]);
            if (isValidJson(result) && Array.isArray(result)) {
                  setTableColumns(recursiveSearch(result[0], ''));
                  setTableResult(result);
            } else {
                  setIsModalVisible(true);
            }
      };

      const onResultKeyInput = ({ resultKey }) => {
            setTableColumns(recursiveSearch(apiResult[resultKey][0], ''));
            setTableResult(apiResult[resultKey]);
            setIsModalVisible(false);
      };

      const seeTheRows = ({ tableName }) => {
            setLoading(true);
            const { cloud_name, token, apis_info } = activeAPIData;
            const finalKeyArray = Object.values(mapKeys);
            const tableColumnsCam = finalKeyArray.map((sweetItem) => {
                  return sweetItem.split('.').join('-');
            });

            const gRowsData = [[...tableColumnsCam]];
            for (let index = 0; index < tableResult.length; index++) {
                  const tableSingleRow = tableResult[index];
                  const dummyArray = [];
                  for (
                        let index2 = 0;
                        index2 < tableColumns2.length;
                        index2++
                  ) {
                        const colKey = tableColumns2[index2];
                        dummyArray.push(
                              getNestedObject(tableSingleRow, colKey)
                        );
                  }
                  gRowsData.push(dummyArray);
            }

            if (tableColumnsCam.length > 0) {
                  var myHeaders = new Headers();
                  myHeaders.append('Content-Type', 'application/json');

                  let numberOfRowsPushed = 0;
                  function makePushCall() {
                        const dataToPush = gRowsData.splice(0, 50);

                        var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: JSON.stringify(dataToPush),
                              redirect: 'follow',
                        };
                        fetch(
                              `${process.env.GATSBY_BASE_URL}/${cloud_name}/google_sheets/${token}?tabId=${activeTabId}`,
                              requestOptions
                        )
                              .then(async (response) => {
                                    const data = await response.json();

                                    // check for error response
                                    if (!response.ok) {
                                          // get error message from body or default to response statusText
                                          const error =
                                                (data && data.info) ||
                                                response.statusText;
                                          setErrorMessage(error);
                                          setLoading(false);
                                          setErrorType('error');
                                    } else {
                                          numberOfRowsPushed =
                                                numberOfRowsPushed + 50;
                                          setProgressCount(
                                                (numberOfRowsPushed * 100) /
                                                      tableResult.length
                                          );
                                          setAirtableLink(
                                                `https://docs.google.com/spreadsheets/d/${apis_info.sheet_id}`
                                          );
                                          if (gRowsData.length > 0) {
                                                makePushCall();
                                          } else {
                                                setLoading(false);
                                                setErrorType('success');
                                                setErrorMessage(
                                                      'Successfully Done!'
                                                );
                                                setMapKeys({});
                                                setTabList([]);
                                                setIsGoodToGo(false);
                                          }
                                    }
                              })
                              .catch((error) => {
                                    setLoading(false);
                                    setErrorType('error');
                              });
                  }
                  makePushCall();
            } else {
                  setLoading(false);
                  setErrorType('error');
                  setErrorMessage('First pull the data!');
            }
      };

      const onChangePanel = (obj) => {
            if (obj[0] !== undefined) setPanelActiveKey(obj[1]);
      };

      const selectTargetSheet = () => {
            setLoadingTabs(true);
            const { cloud_name, token } = activeAPIData;
            const { sheet_id } = activeAPIData.apis_info;
            setActiveSheetId(sheet_id);
            setActiveApiUserName(cloud_name);
            setErrorMessage('');
            setTabList([]);
            setActiveTabId('');
            setActiveApiToken(token);
            var config = {
                  method: 'get',
                  url: `${process.env.GATSBY_BASE_URL}/${cloud_name}/google_sheets/${token}/listTabs`,
                  headers: {},
            };

            axios(config)
                  .then(function (response) {
                        const { error, info } = response.data;
                        if (error === undefined) {
                              setTabList(response.data);
                        } else {
                              setErrorMessage(info);
                        }
                        setLoadingTabs(false);
                  })
                  .catch(function (error) {
                        setErrorMessage(error.info);
                        setLoadingTabs(false);
                  });
      };

      const selectTargetSheetTab = (title) => {
            // const { title, sheetId } = tabObj;
            const { apis_info } = activeAPIData;
            setLoadingTabs(true);
            setActiveTabId(title);
            setErrorMessage('');
            var config = {
                  method: 'get',
                  url: `${process.env.GATSBY_BASE_URL}/${activeApiUserName}/google_sheets/${activeApiToken}/getResults?tabId=${title}`,
                  headers: {},
            };

            axios(config)
                  .then(function (response) {
                        const { data } = response.data;
                        if (data.length > 0) {
                              setErrorMessage(
                                    `This Sheet have data. Please select blank sheet tab or first make blank sheet in your spreadsheet. <a href="${`https://docs.google.com/spreadsheets/d/${apis_info.sheet_id}`}" target="_blank">View Spreadsheet</a>`
                              );
                        } else {
                              setIsGoodToGo(true);
                        }
                        setLoadingTabs(false);
                  })
                  .catch(function (error) {
                        setErrorMessage(error.info);
                        setLoadingTabs(false);
                  });
      };

      return (
            <div className="connector-page">
                  <Collapse
                        activeKey={[panelActiveKey]}
                        onChange={onChangePanel}
                        // accordion
                        collapsible
                  >
                        <Panel header="Pull Data from API" key="1">
                              <Modal
                                    width={700}
                                    title="Result Key is required."
                                    visible={isModalVisible}
                                    footer={null}
                                    onCancel={() => setIsModalVisible(false)}
                              >
                                    <Form
                                          name="basic"
                                          initialValues={{}}
                                          onFinish={onResultKeyInput}
                                    >
                                          <Form.Item
                                                name="resultKey"
                                                rules={[{ required: true }]}
                                                extra="Is the results array nested under some key? Check below JSON response."
                                          >
                                                <Input placeholder="Enter result key" />
                                          </Form.Item>

                                          <Form.Item>
                                                <Button
                                                      type="primary"
                                                      htmlType="submit"
                                                >
                                                      Submit
                                                </Button>
                                          </Form.Item>
                                    </Form>
                                    <RenderReactJSON
                                          style={{
                                                fontSize: 13,
                                                borderRadius: 3,
                                          }}
                                          name={false}
                                          theme="ocean"
                                          enableClipboard={false}
                                          displayDataTypes={false}
                                          src={apiResult}
                                          displayObjectSize={false}
                                    />
                              </Modal>

                              <ConnectorAPIPlayground
                                    sendResultBack={sendResultBack}
                              />

                              {tableResult.length > 0 && (
                                    <Select
                                          mode="multiple"
                                          allowClear
                                          style={{ width: '100%' }}
                                          placeholder="Please select key values"
                                          defaultValue={[]}
                                          onChange={handleChange}
                                    >
                                          {children}
                                    </Select>
                              )}
                              {tableResult.length > 0 && (
                                    <p
                                          style={{
                                                padding: 0,
                                                marginTop: 10,
                                                fontSize: 13,
                                          }}
                                    >
                                          Total rows: {tableResult.length}
                                    </p>
                              )}
                              <div
                                    style={{
                                          overflowX: 'auto',
                                          maxHeight: 250,
                                          overflowY: 'scroll',
                                          marginTop: 10,
                                    }}
                              >
                                    <table className="table">
                                          <thead>
                                                <tr>
                                                      {tableColumns2.map(
                                                            (colKey, key) => (
                                                                  <th
                                                                        key={`header-${key}`}
                                                                  >
                                                                        <div>
                                                                              {
                                                                                    colKey
                                                                              }
                                                                        </div>
                                                                  </th>
                                                            )
                                                      )}
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {tableResult.map(
                                                      (tableBobyRow, key2) => (
                                                            <tr
                                                                  key={`${key2}-row`}
                                                            >
                                                                  {tableColumns2.map(
                                                                        (
                                                                              colKey,
                                                                              key3
                                                                        ) => (
                                                                              <td
                                                                                    key={`body-${key3}`}
                                                                              >
                                                                                    {getNestedObject(
                                                                                          tableBobyRow,
                                                                                          colKey
                                                                                    )}
                                                                              </td>
                                                                        )
                                                                  )}
                                                            </tr>
                                                      )
                                                )}
                                          </tbody>
                                    </table>
                              </div>
                              <br />
                              <Button
                                    disabled={tableColumns2.length === 0}
                                    onClick={() => setPanelActiveKey('2')}
                              >
                                    Next Step
                              </Button>
                        </Panel>
                        <Panel header="Select Sheet Tab & push data" key="2">
                              <RenderReactJSON
                                    style={{
                                          fontSize: 13,
                                          borderRadius: 3,
                                    }}
                                    name={false}
                                    theme="ocean"
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    src={mapKeys}
                                    displayObjectSize={false}
                                    onEdit={(edit) => {
                                          setMapKeys(edit.updated_src);
                                    }}
                              />

                              <br />
                              <Form
                                    layout="vertical"
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={seeTheRows}
                              >
                                    {tabList.length > 0 && (
                                          <h4>Select Sheet Tab</h4>
                                    )}
                                    <div
                                          style={{
                                                display: 'grid',
                                                gridTemplateColumns:
                                                      'repeat(6, 1fr)',
                                                gridGap: 20,
                                          }}
                                    >
                                          {tabList.length > 0 && (
                                                <>
                                                      <Select
                                                            style={{
                                                                  width: 320,
                                                            }}
                                                            onChange={
                                                                  selectTargetSheetTab
                                                            }
                                                      >
                                                            {tabList.map(
                                                                  (
                                                                        tab,
                                                                        tabKey
                                                                  ) => (
                                                                        <Option
                                                                              value={
                                                                                    tab.title
                                                                              }
                                                                              key={
                                                                                    tabKey
                                                                              }
                                                                        >
                                                                              {
                                                                                    tab.title
                                                                              }
                                                                        </Option>
                                                                  )
                                                            )}
                                                      </Select>

                                                      <div
                                                            onClick={() =>
                                                                  selectTargetSheet()
                                                            }
                                                            style={{
                                                                  textDecoration:
                                                                        'underline',
                                                            }}
                                                      >
                                                            Refresh tab list
                                                      </div>
                                                </>
                                          )}
                                    </div>
                                    {loadingTabs ? <LoadingOutlined /> : ''}
                                    <br />

                                    {errorMessage !== '' && (
                                          <>
                                                <br />
                                                <Alert
                                                      message={
                                                            <div
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: errorMessage,
                                                                  }}
                                                            />
                                                      }
                                                      type={errorType}
                                                />
                                                <br />
                                          </>
                                    )}

                                    <Form.Item>
                                          <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={!isGoodToGo}
                                                loading={loading}
                                          >
                                                {loading
                                                      ? 'Pushing Data'
                                                      : 'Push Data'}
                                          </Button>
                                    </Form.Item>
                              </Form>
                              <Progress
                                    percent={progressCount.toFixed(2)}
                                    steps={10}
                                    strokeColor="#52c41a"
                              />
                              {errorType === 'success' && (
                                    <a href={airtableLink} target="_blank">
                                          View Spreadsheet
                                    </a>
                              )}
                        </Panel>
                  </Collapse>
            </div>
      );
}
