import React from 'react';
import { Link } from 'gatsby';
import { Menu, Dropdown } from 'antd';
import { AiOutlineForm, AiOutlinePlaySquare } from 'react-icons/ai';
import {
      RiArrowRightLine,
      RiDeleteBinLine,

} from 'react-icons/ri';
import { SiGooglesheets } from 'react-icons/si';
import { CgImport } from 'react-icons/cg';

import SecureEndpoint from './secureEndpoint';
import { convertObj2D, getAppName } from '../../config/custom/functions';
import { isImportEnable } from '../../config/custom/importConfig';
import { apiJsonDocs } from '../../data/docs';
import APIPlayground from './apiPlayground';
import '../../styles/api-endpoint.scss';
import ViewLogs from './viewLogs';
import ViewAPITokens from './secondaryAPIs';
// import SheetFormBackend from '../codes/formBackend/sheet';
// import AirtableFormBackend from '../codes/formBackend/airtable';
// import SupabaseFormBackend from '../codes/formBackend/supabase';

const APIendpointCard = ({
      apiObj,
      onEditMode,
      baseURLForEndpoint,
      deleteThisAPI,
      openImportModal,
}) => {
      const { name, sheet_id, base_id } = apiObj.apis_info;
      const { cloud_name, app_id, token } = apiObj;

      const isImporting = isImportEnable(app_id);
      const menu = (
            <Menu>
                  <ViewLogs token={token} />
                  <ViewAPITokens token={token} />
                  {/* {app_id === 'google_sheets' && (
                        <SheetFormBackend
                              appId={app_id}
                              apiEndpoint={`${baseURLForEndpoint}/${cloud_name}/${app_id}/${token}`}
                              apiObj={apiObj}
                        />
                  )}
                  {app_id === 'airtable' && (
                        <AirtableFormBackend
                              appId={app_id}
                              apiEndpoint={`${baseURLForEndpoint}/${cloud_name}/${app_id}/${token}`}
                              apiObj={apiObj}
                        />
                  )}
                  {app_id === 'supabase' && (
                        <Menu.Item
                              style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    color: '#3c3c3c',
                              }}
                              key="3"
                              onClick={() => openImportModal(apiObj)}
                              icon={<CgImport size={14} />}
                        >
                              Import data
                        </Menu.Item>
                  )} */}

                  {app_id === 'google_sheets' && (
                        <a
                              href={`https://docs.google.com/spreadsheets/d/${sheet_id}`}
                              target="_blank"
                        >
                              <Menu.Item
                                    style={{
                                          fontSize: 14,
                                          fontWeight: 300,
                                          color: '#3c3c3c',
                                    }}
                                    key="3"
                                    icon={<SiGooglesheets size={14} />}
                              >
                                    View the spreadsheet
                              </Menu.Item>
                        </a>
                  )}

                  {app_id === 'airtable' && (
                        <a
                              href={`https://airtable.com/${base_id}`}
                              target="_blank"
                        >
                              <Menu.Item
                                    style={{
                                          fontSize: 14,
                                          fontWeight: 300,
                                          color: '#3c3c3c',
                                    }}
                                    key="3"
                                    icon={<SiGooglesheets size={14} />}
                              >
                                    View Airtable base
                              </Menu.Item>
                        </a>
                  )}

                  {isImporting && (
                        <Menu.Item
                              style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    color: '#3c3c3c',
                              }}
                              key="3"
                              onClick={() => openImportModal(apiObj)}
                              icon={<CgImport size={14} />}
                        >
                              Import data
                        </Menu.Item>
                  )}
                  <Menu.Item
                        style={{
                              fontSize: 14,
                              fontWeight: 300,
                              color: '#3c3c3c',
                        }}
                        key="7"
                        onClick={deleteThisAPI}
                        icon={<RiDeleteBinLine size={14} />}
                  >
                        Delete this endpoint
                  </Menu.Item>
            </Menu>
      );

      return (
            <div className="api-endpoint">
                  <div className="card">
                        <div className="card-head">
                              <div className="name">{name}</div>
                              <div className="options">
                                    <SecureEndpoint apiObj={apiObj} />
                                    <div
                                          className="option edit-endpoint"
                                          onClick={onEditMode}
                                    >
                                          <AiOutlineForm />
                                          <span>Edit this endpoint</span>
                                    </div>
                                    <div className="endpoint-settings">
                                          <Dropdown.Button overlay={menu} />
                                    </div>
                              </div>
                        </div>
                        <div className="card-body">
                              <div className="endpoint">
                                    <div className="endpoint-key label">
                                          <span style={{ fontWeight: 400 }}>
                                                API Endpoint
                                          </span>
                                          <RiArrowRightLine />
                                    </div>
                                    <div className="endpoint-key endpoint-url">
                                          {`${baseURLForEndpoint}/${cloud_name}/${app_id}/${token}`}
                                    </div>
                                    <APIPlayground
                                          docsJSON={convertObj2D(
                                                apiJsonDocs(
                                                      cloud_name,
                                                      app_id,
                                                      token
                                                )
                                          )}
                                          token={token}
                                          appId={app_id}
                                          apiMetaData={apiObj}
                                          baseURLForEndpoint={
                                                baseURLForEndpoint
                                          }
                                    />
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default APIendpointCard;
