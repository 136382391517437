import React, { useState, useEffect, useContext } from 'react';
import { Select, Input, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { RiKey2Line } from 'react-icons/ri';
import { Firebase } from '../../config/firebase';
import { getAppName } from '../../config/custom/functions';

import {
      GlobalDispatchContext,
      GlobalStateContext,
} from '../../context/GlobalContextProvider';

const { Option } = Select;

const ConnectionList = ({ appId, selectedConnectionID, authEndpointURL }) => {
      const dispatch = useContext(GlobalDispatchContext);
      const state = useContext(GlobalStateContext);
      const [accounts, setAccounts] = useState([]);

      const handleChange = (value) => {
            selectedConnectionID(value);
      };

      useEffect(() => {
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        const data = JSON.stringify({
                              projectId: state.selectedProjectId,
                              uid: user.uid,
                              app_id: appId,
                        });

                        const config = {
                              method: 'post',
                              url: `${process.env.GATSBY_BASE_URL}/auth/accounts`,
                              headers: {
                                    'Content-Type': 'application/json',
                              },
                              data,
                        };

                        axios(config)
                              .then(function (response) {
                                    const accList = response.data;
                                    setAccounts(accList);
                                    if (accList.length > 0)
                                          selectedConnectionID(accList[0]._id);
                              })
                              .catch(function (error) {
                                    console.log(error);
                              });
                  }
            });
      }, []);

      return (
            <>
                  {accounts.length > 0 ? (
                        <>
                              <label>Choose account</label>
                              <Input.Group compact>
                                    <Select
                                          defaultValue={accounts[0]._id}
                                          style={{
                                                width: '100%',
                                                fontWeight: 300,
                                                fontSize: 14,
                                          }}
                                          onChange={handleChange}
                                    >
                                          {accounts.map((item) => (
                                                <Option
                                                      key={item._id}
                                                      value={item._id}
                                                      style={{
                                                            fontWeight: 300,
                                                            fontSize: 14,
                                                      }}
                                                >
                                                      {item.userInfo !==
                                                      undefined
                                                            ? item.userInfo.name
                                                            : item.name}{' '}
                                                      - added{' '}
                                                      {moment(
                                                            item.updated_date
                                                      ).fromNow()}
                                                </Option>
                                          ))}
                                    </Select>
                              </Input.Group>
                              <a href={authEndpointURL}>
                                    Or, add another account
                              </a>
                              <br />
                        </>
                  ) : (
                        <div style={{ textAlign: 'center' }}>
                              <p style={{ color: '#606060' }}>
                                    Authenticate first with {getAppName(appId)}{' '}
                                    Account
                              </p>
                              <a
                                    href={authEndpointURL}
                                    className="authenticate-btn"
                              >
                                    <Button
                                          icon={<RiKey2Line />}
                                          type="primary"
                                    >
                                          Authenticate
                                    </Button>
                              </a>
                        </div>
                  )}
            </>
      );
};

export default ConnectionList;
