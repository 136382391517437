exports.obj = function (username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ["Enrich"],
            summary: "Enrich data using email",
            description: "Get data enriched based on email provided",
            produces: ["application/json"],
            parameters: [
                {
                    name: 'email',
                    in: 'query',
                    description: 'Please enter the email',
                    required: true
                },
                {
                    name: 'type',
                    in: 'query',
                    description: 'Please enter enrich type, available values are: "simple", "advanced", "extended".',
                    required: true
                },
            ],
            responses: {
                200: {
                    description: "successful operation",
                },
            },
        },
    };
    return schemaObj;
};
