exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/deals`] = {
        get: {
            tags: ['Pipedrive'],
            summary: 'Get all deals',
            description: 'Returns all deals',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'If supplied, only deals matching the given user will be returned.',
                    type: 'string',
                },
                {
                    name: 'stage_id',
                    in: 'query',
                    description: 'If supplied, only deals within the given stage will be returned.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'Only fetch deals with specific status. If omitted, all not deleted deals are fetched. ex: open, won, lost, deleted',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Total number of records you want in one request',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Pipedrive'],
            summary: 'Add a deal',
            description: 'Adds a new deal.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'dealInfo',
                    in: 'body',
                    description: 'Deal info to add like: title, currency, user_id, status, etc.',
                    required: true,
                    sampleData: {
                        title: 'deal name',
                    },
                },
            ],
            isQueryParam: false,
            bodyOptionalParams: [
                {
                    name: 'value',
                    description: 'Value of the deal. If omitted, value will be set to 0.',
                },
                {
                    name: 'currency',
                    description: 'Currency of the deal. Accepts a 3-character currency code. ',
                },
                {
                    name: 'user_id',
                    description: 'ID of the user who will be marked as the owner of this deal.',
                },
                {
                    name: 'person_id',
                    description: 'ID of the person this deal will be associated with',
                },
                {
                    name: 'org_id',
                    description: 'ID of the organization this deal will be associated with',
                },
                {
                    name: 'stage_id',
                    description: 'ID of the stage this deal will be placed in a pipeline',
                },
                {
                    name: 'status',
                    description: 'open = Open, won = Won, lost = Lost, deleted = Deleted.',
                },
                {
                    name: 'expected_close_date',
                    description: 'The expected close date of the Deal. In ISO 8601 format: YYYY-MM-DD.',
                },
                {
                    name: 'probability',
                    description:
                        'Deal success probability percentage. Used/shown only when deal_probability for the pipeline of the deal is enabled.',
                },
                {
                    name: 'lost_reason',
                    description: 'Optional message about why the deal was lost (to be used when status=lost)',
                },
                {
                    name: 'visible_to',
                    description:
                        'Visibility of the deal. If omitted, visibility will be set to the default visibility setting of this item type for the authorized user.',
                },
                {
                    name: 'add_time',
                    description: 'ormat: YYYY-MM-DD HH:MM:SS',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        put: {
            tags: ['Pipedrive'],
            summary: 'Updates a deal.',
            description: 'Updates the properties of a deal.',
            produces: ['application/json'],
            parameters: [],
            isQueryParam: false,
            bodyOptionalParams: [
                {
                    name: 'title',
                    description: 'title to update',
                },
                {
                    name: 'value',
                    description: 'Value of the deal. If omitted, value will be set to 0.',
                },
                {
                    name: 'currency',
                    description: 'Currency of the deal. Accepts a 3-character currency code. ',
                },
                {
                    name: 'user_id',
                    description: 'ID of the user who will be marked as the owner of this deal.',
                },
                {
                    name: 'person_id',
                    description: 'ID of the person this deal will be associated with',
                },
                {
                    name: 'org_id',
                    description: 'ID of the organization this deal will be associated with',
                },
                {
                    name: 'stage_id',
                    description: 'ID of the stage this deal will be placed in a pipeline',
                },
                {
                    name: 'status',
                    description: 'open = Open, won = Won, lost = Lost, deleted = Deleted.',
                },
                {
                    name: 'expected_close_date',
                    description: 'The expected close date of the Deal. In ISO 8601 format: YYYY-MM-DD.',
                },
                {
                    name: 'probability',
                    description:
                        'Deal success probability percentage. Used/shown only when deal_probability for the pipeline of the deal is enabled.',
                },
                {
                    name: 'lost_reason',
                    description: 'Optional message about why the deal was lost (to be used when status=lost)',
                },
                {
                    name: 'visible_to',
                    description:
                        'Visibility of the deal. If omitted, visibility will be set to the default visibility setting of this item type for the authorized user.',
                },
                {
                    name: 'add_time',
                    description: 'ormat: YYYY-MM-DD HH:MM:SS',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/deals/{id}`] = {
        delete: {
            tags: ['Pipedrive'],
            summary: 'Delete deal',
            description: 'You can delete deal from pipedrive.',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/deals/search`] = {
        delete: {
            tags: ['Pipedrive'],
            summary: 'Search deal',
            description: 'Searches all Deals by title, notes and/or custom fields.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'term',
                    in: 'query',
                    description: 'The search term to look for. Minimum 2 characters (or 1 if using exact_match).',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'fields',
                    in: 'query',
                    description: 'A comma-separated string array.',
                    type: 'string',
                },
                {
                    name: 'exact_match',
                    in: 'query',
                    description: 'true or false',
                    type: 'string',
                },
                {
                    name: 'person_id',
                    in: 'query',
                    description: 'Will filter Deals by the provided Person ID.',
                    type: 'string',
                },
                {
                    name: 'organization_id',
                    in: 'query',
                    description: 'Will filter Deals by the provided Organization ID',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description: 'open = Open, won = Won, lost = Lost.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'Supports including optional fields in the results which are not provided by default.',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'ddelete',
                    type: 'Items shown per page',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/persons`] = {
        get: {
            tags: ['Pipedrive'],
            summary: 'Get all persons',
            description: 'Returns all persons',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'If supplied, only deals matching the given user will be returned.',
                    type: 'string',
                },
                {
                    name: 'stage_id',
                    in: 'query',
                    description: 'If supplied, only deals within the given stage will be returned.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'Only fetch deals with specific status. If omitted, all not deleted deals are fetched. ex: open, won, lost, deleted',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Total number of records you want in one request',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Pipedrive'],
            summary: 'Add a person',
            description: 'Add a new person.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'personInfo',
                    in: 'body',
                    description: 'Person info to add like: name, owner_id, org_id, email, phone, etc.',
                    required: true,
                    sampleData: {
                        name: 'person name',
                    },
                },
            ],
            isQueryParam: false,
            bodyOptionalParams: [
                {
                    name: 'owner_id',
                    description: 'ID of the user who will be marked as the owner of this person.',
                },
                {
                    name: 'org_id',
                    description: 'ID of the organization this deal will be associated with',
                },
                {
                    name: 'email',
                    description: 'Email addresses (one or more) associated with the person',
                },
                {
                    name: 'phone',
                    description: 'Phone numbers (one or more) associated with the person',
                },
                {
                    name: 'visible_to',
                    description:
                        'Visibility of the deal. If omitted, visibility will be set to the default visibility setting of this item type for the authorized user.',
                },
                {
                    name: 'add_time',
                    description: 'ormat: YYYY-MM-DD HH:MM:SS',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        put: {
            tags: ['Pipedrive'],
            summary: 'Update a person',
            description: 'Updates the properties of a person.',
            produces: ['application/json'],
            parameters: [],
            isQueryParam: false,
            bodyOptionalParams: [
                {
                    name: 'name',
                    description: 'new name of person',
                },
                {
                    name: 'owner_id',
                    description: 'ID of the user who will be marked as the owner of this person.',
                },
                {
                    name: 'org_id',
                    description: 'ID of the organization this deal will be associated with',
                },
                {
                    name: 'email',
                    description: 'Email addresses (one or more) associated with the person',
                },
                {
                    name: 'phone',
                    description: 'Phone numbers (one or more) associated with the person',
                },
                {
                    name: 'visible_to',
                    description:
                        'Visibility of the deal. If omitted, visibility will be set to the default visibility setting of this item type for the authorized user.',
                },
                {
                    name: 'add_time',
                    description: 'ormat: YYYY-MM-DD HH:MM:SS',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/persons/{id}`] = {
        delete: {
            tags: ['Pipedrive'],
            summary: 'Delete a person',
            description: 'You can delete person from pipedrive.',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/persons/search`] = {
        delete: {
            tags: ['Pipedrive'],
            summary: 'Search person',
            description: 'Make search for persons',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'term',
                    in: 'query',
                    description: 'The search term to look for. Minimum 2 characters (or 1 if using exact_match).',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'fields',
                    in: 'query',
                    description: 'A comma-separated string array.',
                    type: 'string',
                },
                {
                    name: 'exact_match',
                    in: 'query',
                    description: 'true or false',
                    type: 'string',
                },
                {
                    name: 'organization_id',
                    in: 'query',
                    description: 'Will filter Deals by the provided Organization ID',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'Supports including optional fields in the results which are not provided by default.',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'ddelete',
                    type: 'Items shown per page',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
