import { Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formatting,
  inTheVoiceOf,
  language,
  length,
  outputAs,
  readerComprehension,
  slang,
  style,
  task,
  tone,
} from './option';
import { showTextArea } from '../helpers';

const SwissKnife = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    Task: null,
    Style: null,
    Tone: null,
    Language: null,
    'In the voice of': null,
    Slang: null,
    'Reader comprehension': null,
    Length: null,
    'Output as': null,
    Formatting: null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  const onChangeText = (event, name) => {
    setFormKey(name, event.target.value);
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Task'>
        <Select
          options={task}
          placeholder='Task'
          onChange={(value) => {
            setFormKey('Task', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea(`Add what you need, delete what you don't`, 'Task', task, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Task'>
          <Input.TextArea
            placeholder='Your task'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Task')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Style'>
        <Select
          options={style}
          placeholder='Style'
          onChange={(value) => {
            setFormKey('Style', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Tone'>
        <Select
          options={tone}
          placeholder='Tone'
          onChange={(value) => {
            setFormKey('Tone', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Language'>
        <Select
          options={language}
          placeholder='Language'
          onChange={(value) => {
            setFormKey('Language', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      {showTextArea('Add your language', 'Language', language, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Language'>
          <Input.TextArea
            placeholder='Your language'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Language')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='In the voice of'>
        <Select
          options={inTheVoiceOf}
          onChange={(value) => {
            setFormKey('In the voice of', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      {showTextArea('Add other famous personas here', 'In the voice of', inTheVoiceOf, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your chosen voice'>
          <Input.TextArea
            placeholder='Your chosen voice'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'In the voice of')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Slang'>
        <Select
          options={slang}
          placeholder='Slang'
          onChange={(value) => {
            setFormKey('Slang', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea('Use your imagination and add :)', 'Slang', slang, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Slang'>
          <Input.TextArea
            placeholder='Your slang'
            style={{ height: 120, marginBottom: 24 }}
            // value={addMoreOption.Slang}
            onChange={(e) => onChangeText(e, 'Slang')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Reader comprehension'>
        <Select
          options={readerComprehension}
          placeholder='Reader comprehension'
          onChange={(value) => {
            setFormKey('Reader comprehension', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Length'>
        <Select
          options={length}
          placeholder='Length'
          onChange={(value) => {
            setFormKey('Length', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Output as'>
        <Select
          options={outputAs}
          placeholder='Output as'
          onChange={(value) => {
            setFormKey('Output as', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Formatting'>
        <Select
          options={formatting}
          placeholder='Formatting'
          onChange={(value) => {
            setFormKey('Formatting', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {/*  <Form.Item className='mt-4 text-gray-600 font-medium text-md' label="Add">
         
          <Add
            onButtonStateChange={(e, type) => {
              store.promptWriter.setExtraAttributeValue(type);
            }}
          />
        </Form.Item> */}
    </Form>
  );
};

export default SwissKnife;
