exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/orders`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'All orders',
            description: 'List all your orders',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Number of results to fetch. Default is 20.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'A status criteria for your order collection. Possible values: (InProgress, Processed, Disputed, Shipped, Delivered, Pending, Cancelled)',
                    type: 'string',
                },
                {
                    name: 'invoiceNumber',
                    in: 'query',
                    description: 'The invoice number of the order to retrieve.',
                    type: 'string',
                },
                {
                    name: 'productId',
                    in: 'query',
                    description: 'Returns only orders containing that product.',
                    type: 'string',
                },
                {
                    name: 'placedBy',
                    in: 'query',
                    description:
                        'The name of the person who made the purchase.',
                    type: 'string',
                },
                {
                    name: 'from',
                    in: 'query',
                    description:
                        'Returns only the orders placed after this date.',
                    type: 'string',
                },
                {
                    name: 'to',
                    in: 'query',
                    description:
                        'Returns only the orders placed before this date.',
                    type: 'string',
                },
                {
                    name: 'isRecurringOrder',
                    in: 'query',
                    description:
                        'Returns only the orders that are recurring or not.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        put: {
            tags: ['Snipcart'],
            summary: 'Update Order',
            description: 'Updates the status of the specified order.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'token',
                    in: 'query',
                    description: 'order token',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an array of row array into body',
                    required: true,
                    sampleData: {
                        status: '',
                        paymentStatus: '',
                        trackingNumber: '',
                        trackingUrl: '',
                        metadata: {},
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/singleOrder`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'Single Order',
            description: 'Get single order by token',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'token',
                    in: 'query',
                    description: 'order token',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/customers`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'List Customers',
            description: 'List of all your existing customers.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Number of results to fetch. Default is 20.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'The status of your customers, Confirmed means that the customers have created an account and Unconfirmed are those who checked out as guests.',
                    type: 'string',
                },
                {
                    name: 'email',
                    in: 'query',
                    description:
                        'The email of the customer who placed the order.',
                    type: 'string',
                },
                {
                    name: 'name',
                    in: 'query',
                    description:
                        'The name of the customer who placed the order.',
                    type: 'string',
                },
                {
                    name: 'from',
                    in: 'query',
                    description:
                        'Returns only the customers created after this date.',
                    type: 'string',
                },
                {
                    name: 'to',
                    in: 'query',
                    description:
                        'Returns only the customers created before this date.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/customerOrders`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'Customer Orders',
            description: 'List all order related to a customer',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'customer id',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/products`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'List Products',
            description: 'List of all products',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Pagination',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Number of results to fetch. Default is 20.',
                    type: 'string',
                },
                {
                    name: 'userDefinedId',
                    in: 'query',
                    description: 'The product ID defined by the user.',
                    type: 'string',
                },
                {
                    name: 'from',
                    in: 'query',
                    description:
                        'Filter products to return those that have been bought from specified date.',
                    type: 'string',
                },
                {
                    name: 'to',
                    in: 'query',
                    description:
                        'Filter products to return those that have been bought until specified date.',
                    type: 'string',
                },
                {
                    name: 'orderBy',
                    in: 'query',
                    description:
                        'Sort products to return them in the requested order. Possible values: nbrSales (return products sorted by the number of times they were sold), salesValue (return products sorted by the total sales generated by these products) and creationDate (return products sorted by creation date, newest products first).',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/singleProduct`] = {
        get: {
            tags: ['Snipcart'],
            summary: 'Single Product',
            description: 'A specific product information by id',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'product id',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
