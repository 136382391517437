import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import axios from 'axios';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';

const { Option } = Select;

const SheetForm = ({ connectionID, formState }) => {
      const [loading, setLoading] = useState(false);
      const [allSpreadsheetLink, setAllSpreadsheetLink] = useState([]);

      useEffect(() => {
            if (connectionID !== '') {
                  setAllSpreadsheetLink([]);
                  var config = {
                        method: 'get',
                        url: `${process.env.GATSBY_BASE_URL}/getSpreadsheetList/${connectionID}`,
                        headers: {
                              'Content-Type': 'application/json',
                        },
                  };

                  axios(config)
                        .then(function (response) {
                              setAllSpreadsheetLink(response.data);
                        })
                        .catch(function (error) {
                              message.error(
                                    'Your account have invalid grant. So, please add another google account.'
                              );
                        });
            }
      }, [connectionID]);

      return (
            <>
                  <Form.Item
                        label="Give a name"
                        name="name"
                        rules={[
                              { required: true, message: 'Name is required!' },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Give a name to this API.',
                                    }}
                              />
                        }
                  >
                        <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                        name="sheet_id"
                        label="Add your spreadsheet"
                        rules={[
                              {
                                    required: true,
                                    message: 'Spreadsheet is required!',
                              },
                        ]}
                  >
                        <Input autoComplete='off' placeholder="Add Google Spreadsheet File ID" />
                        {/* <Select
                              name="sheet_id"
                              placeholder="Select Google Spreadsheet file"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                    option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                              }
                        >
                              {allSpreadsheetLink.map((option, key) => (
                                    <Option value={option.id} key={key}>
                                          {option.name}
                                    </Option>
                              ))}
                        </Select> */}
                  </Form.Item>

                  <Form.Item>
                        <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="make-btn"
                              icon={
                                    formState === 'adding' ? (
                                          <BsFolderPlus />
                                    ) : (
                                          <RiEditBoxFill />
                                    )
                              }
                        >
                              {formState === 'adding' ? 'Create' : 'Edit API'}
                        </Button>
                  </Form.Item>
            </>
      );
};

export default SheetForm;
