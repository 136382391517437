exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/rates`] = {
        get: {
            tags: ['Currency Exchange'],
            summary: 'Get Rates',
            description: 'Get Rates',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'source',
                    in: 'query',
                    description: 'Specify a Source Currency other than the default USD',
                },
                {
                    name: 'target',
                    in: 'query',
                    description:
                        'Specify a comma-separated list of currency codes to limit your API response to specific currencies.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/rates/convert`] = {
        get: {
            tags: ['Currency Exchange'],
            summary: 'Convert currency into other currency',
            description: 'Convert currency into other currency',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'amount',
                    in: 'query',
                    required: true,
                    description: 'Specify amount you want to convert',
                },
                {
                    name: 'from',
                    in: 'query',
                    required: true,
                    description: 'Specify source currency',
                },
                {
                    name: 'to',
                    in: 'query',
                    required: true,
                    description: 'Specify target currency',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/symbols`] = {
        get: {
            tags: ['Currency Exchange'],
            summary: 'Countries Symbols',
            description: 'Get 144 countries symbols with metadata',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
