exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}`] = {
            get: {
                  tags: ['Endpoint Proxy'],
                  summary: 'Use your endpoint proxy',
                  description: 'Use your endpoint proxy',
                  produces: ['application/json'],
                  parameters: [],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            post: {
                  tags: ['Endpoint Proxy'],
                  summary: 'Make post call to your endpoint proxy',
                  description: 'Use your endpoint proxy',
                  produces: ['application/json'],
                  isQueryParam: false,
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description:
                                    'pass the body data your API required',
                              sampleData: {},
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };
      return schemaObj;
};
