exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Email Verification'],
            summary: 'Email Check',
            description: 'Check an email address is working or not.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'email',
                    in: 'query',
                    description: 'email address',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
