const importEnableList = [
    {
        name: 'Airtable',
        id: 'airtable',
    },
    {
        name: 'Airtable',
        id: 'google_sheets',
    },
    {
        name: 'Airtable',
        id: 'webflow',
    },
];

export const isImportEnable = function(appId) {
    const isIndexFound = importEnableList.findIndex(item => item.id === appId);
    if (isIndexFound > -1) return true;
    return false;
};
