exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/listEmails`] = {
        get: {
            tags: ['Zoho Mail'],
            summary: 'List Emails',
            description:
                'List of all the emails in a specific folder or the list of emails',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'folderId',
                    in: 'query',
                    description:
                        'Folder Id of the folder for which the emails need to be retrieved.',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'The number of emails to be retrieved from the start value mentioned. The default value is 10',
                },
                {
                    name: 'status',
                    in: 'query',
                    description: 'unread or read',
                },
                {
                    name: 'flagid',
                    in: 'query',
                    description: 'To retrieve emails based on a specific flag.',
                },
                {
                    name: 'labelid',
                    in: 'query',
                    description:
                        'To retrieve emails based on a specific label.',
                },
                {
                    name: 'sortBy',
                    in: 'query',
                    description: 'date, messageId, size',
                },
                {
                    name: 'sortorder',
                    in: 'query',
                    description: 'true - ascending; fasle - descending',
                },
                {
                    name: 'includeto',
                    in: 'query',
                    description: 'true - include; false - do not include',
                },
                {
                    name: 'includearchive',
                    in: 'query',
                    description: 'true - include; false - do not include',
                },
                {
                    name: 'includesent',
                    in: 'query',
                    description: 'true - include; false - do not include',
                },
                {
                    name: 'attachedMails',
                    in: 'query',
                    description:
                        'true - emails with attachments; false - all emails',
                },
                {
                    name: 'inlinedMails',
                    in: 'query',
                    description:
                        'true - emails with inlines; false - all emails',
                },
                {
                    name: 'flaggedMails',
                    in: 'query',
                    description: 'true - emails with flags; false - all emails',
                },
                {
                    name: 'respondedMails',
                    in: 'query',
                    description:
                        'true - emails with replies; false - all emails',
                },
                {
                    name: 'threadedMails',
                    in: 'query',
                    description:
                        'true - emails that are conversations; false - all emails',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/updateEmail`] = {
        put: {
            tags: ['Zoho Mail'],
            summary: 'Update Email',
            description:
                'Update emails like mark a particular email or a set of emails as read, unread, archive, unarchive.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'messageID',
                    in: 'query',
                    description:
                        'Single or Multiple Message Ids which need to be marked as read, unread, archive, unarchive.',
                    required: true,
                },
                {
                    name: 'mode',
                    in: 'query',
                    description:
                        'markAsRead, markAsUnread, archiveMails, unArchiveMails',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Zoho Mail'],
            summary: 'Search',
            description:
                'Retrieves the list of emails, based on the custom search terms and parameters.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'searchKey',
                    in: 'query',
                    description:
                        'To search for new emails, provide the searchKey as newMails.',
                    required: true,
                },
                {
                    name: 'parameter',
                    in: 'query',
                    description:
                        'entire, content, sender, to, cc, subject, fileName, fileContent',
                },
                {
                    name: 'receivedTime',
                    in: 'query',
                    description: 'By default, the value is before 2 minutes. ',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'The number of emails to be retrieved from the start value mentioned. The default value is 10. ',
                },
                {
                    name: 'includeto',
                    in: 'query',
                    description:
                        'Whether the To details need to be included or not. ',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/sendEmail`] = {
        post: {
            tags: ['Zoho Mail'],
            summary: 'Send Email',
            description: 'The API is used to Send an Email. ',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'fromAddress',
                    in: 'query',
                    description: `Sender's email address for the From field`,
                    required: true,
                },
                {
                    name: 'toAddress',
                    in: 'query',
                    description: 'Recipient email address for the To field',
                    required: true,
                },
                {
                    name: 'ccAddress',
                    in: 'query',
                    description: 'Recipient email address for the Cc field',
                },
                {
                    name: 'content',
                    in: 'query',
                    description: 'Email content',
                    required: true,
                },
                {
                    name: 'bccAddress',
                    in: 'query',
                    description: 'Recipient email address for the Bcc field',
                },
                {
                    name: 'subject',
                    in: 'query',
                    description: 'Subject of the email that should be sent ',
                    required: true,
                },
                {
                    name: 'encoding',
                    in: 'query',
                    description:
                        'Default value is UTF-8. Other allowed encoding values are Big5, EUC-JP, EUC-KR, GB2312, ISO-2022-JP,ISO-8859-1,KOI8-R,Shift_JIS,US-ASCII,UTF-8,WINDOWS-1251,X-WINDOWS-ISO2022JP',
                },
                {
                    name: 'mailFormat',
                    in: 'query',
                    description:
                        'html or plaintext. Whether the email should be sent in HTML format or in plain text. The default value is html',
                },
                {
                    name: 'askReceipt',
                    in: 'query',
                    description:
                        'Whether you need to request Read receipt from the recipient. If required, enter the value as "yes".',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/listFolders`] = {
        get: {
            tags: ['Zoho Mail'],
            summary: 'List Folders',
            description: 'All the folders details for the specified account',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
