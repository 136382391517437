exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}/list_trends`] = {
    get: {
      tags: ["Google Trends"],
      summary: "List Google Trends Data",
      description: "List all the Google Trends Lists.",
      produces: ["application/json"],
      parameters: [
        {
          name: "keywords",
          in: "query",
          description: "Keywords one or more separated by comma (,)",
          required: true,
          type: "string",
        },
        {
          name: "location_name",
          in: "query",
          description:
            "full name of search engine location if you don’t use this field, you will recieve global results if you use this field, you don’t need to specify location_code",
          required: false,
          type: "string",
        },
        {
          name: "location_code",
          in: "query",
          description:
            "search engine location code. if you don’t use this field, you will recieve global results if you use this field, you don’t need to specify location_name you can receive the list of available locations of the search engines with their location_code by making a separate request",
          required: false,
          type: "string",
        },
        {
          name: "language_name",
          in: "query",
          description:
            "full name of search engine language. Default value: English",
          required: false,
          type: "string",
        },
        {
          name: "language_code",
          in: "query",
          description:
            "search engine language code. if you use this field, you don’t need to specify language_nameyou can receive the list of available languages of the search engine with their language_code by making a separate. eg. en",
          required: false,
          type: "string",
        },
        {
          name: "type",
          in: "query",
          description:
            "google trends type. If you don’t specify this field, the web type will be used by default possible values: web, news, youtube, images, froogle",
          required: false,
          type: "string",
        },
        {
          name: "date_from",
          in: "query",
          description:
            "starting date of the time range, if you don’t specify this field, the current day and month of the preceding year will be used by defaultminimal value for the web type: 2004-01-01minimal value for other types: 2008-01-01",
          required: false,
          type: "string",
        },
        {
          name: "date_to",
          in: "query",
          description:
            'ending date of the time range, if you don’t specify this field, the today’s date will be used by default date format: "yyyy-mm-dd" eg: "2019-01-15"',
          required: false,
          type: "string",
        },
        {
          name: "time_range",
          in: "query",
          description:
            "if you specify date_from or date_to parameters, this field will be ignored when setting a task possible values for all type parameters:past_hour, past_4_hours, past_day, past_7_days, past_30_days, past_90_days, past_12_months, past_5_years",
          required: false,
          type: "string",
        },
      ],
      responses: {
        200: {
          description: "successful operation",
        },
      },
    },
  };
  schemaObj[`/${username}/${appId}/${token}/list_history`] = {
    get: {
      tags: ["Google Trends"],
      summary: "Lists Google Trends Data from history",
      description: "List Google Trends data from your search history",
      produces: ["application/json"],
      parameters: [
        {
          name: "keywords",
          in: "query",
          description: "Keywords one or more separated by comma (,)",
          required: true,
          type: "string",
        },
      ],
      responses: {
        200: {
          description: "successful operation",
        },
      },
    },
  };
  
  schemaObj[`/${username}/${appId}/${token}/delete_history`] = {
    delete: {
      tags: ["Google Trendse"],
      summary: "Delete Records",
      description: "Delete Google Trends History Records",
      produces: ["application/json"],
      parameters: [
        {
          name: "keywords",
          in: "query",
          description: "Keywords one or more separated by comma (,). One of Keywords or history_id is required",
          type: "string",
        },
        {
          name: "history_id",
          in: "query",
          description: "History id of Google Trend to delete. One of Keywords or history_id is required",
          type: "string",
        },
      ],
      responses: {
        200: {
          description: "successful operation",
        },
      },
    },
  };

  return schemaObj;
};
