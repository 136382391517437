exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['XML to JSON'],
            summary: 'XML URL to JSON',
            description: 'Convert XML or RSS feed url into JSON response',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'pass xml url query params to get json data',
                    required: true,
                },
                {
                    name: 'apiKeyName',
                    in: 'query',
                    description: 'API key label/name used to access to endpoint.',
                    type: 'string',
                },
                {
                    name: 'apiKeyValue',
                    in: 'query',
                    description: 'You API key value to access this endpoint.',
                    type: 'string',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/data2json`] = {
        post: {
            tags: ['XML to JSON'],
            summary: 'XML data to JSON',
            description: 'Pass xml data into body and get json response',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an valid xml data',
                    required: true,
                    sampleData: ``
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
