exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/oneCallAPI`] = {
        get: {
            tags: ['Weather'],
            summary: 'One Call API(Dark Sky API)',
            description:
                'With our One Call API you can easily migrate from the Dark Sky API and get free access to main weather data such as current weather, forecast and historical weather with only one call to the API.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'lat',
                    in: 'query',
                    description: 'latitude - Geographical coordinates.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lon',
                    in: 'query',
                    description: 'longitude - Geographical coordinates.',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/byCityName`] = {
        get: {
            tags: ['Weather'],
            summary: 'By city name',
            description: 'Using city name you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'your city name query',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityID`] = {
        get: {
            tags: ['Weather'],
            summary: 'By city ID',
            description:
                'Using city ID you can get weather data if you know ID.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityId',
                    in: 'query',
                    description: 'City ID',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byGeoCord`] = {
        get: {
            tags: ['Weather'],
            summary: 'By geographic coordinates',
            description:
                'Using lat, lon coordinates of the location you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'lat',
                    in: 'query',
                    description: 'Latitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'long',
                    in: 'query',
                    description: 'Longitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byZipCode`] = {
        get: {
            tags: ['Weather'],
            summary: 'By ZIP code',
            description:
                'Pass ZIP code & Country Code as param and get weather data. (Please note if country is not specified then the search works for USA as a default.)',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'zipCode',
                    in: 'query',
                    description: 'ZIP code of the city',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'countryCode',
                    in: 'query',
                    description:
                        "Country Code and by default it will took 'US'",
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byMultiCity`] = {
        get: {
            tags: ['Weather'],
            summary: 'Call for several city IDs',
            description:
                'Get mutiple city weather data by passing mutiple city IDs.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityIDs',
                    in: 'query',
                    description: 'city IDs seprated by comma',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityName/threeHourForecast`] = {
        get: {
            tags: ['3 hour forecast'],
            summary: 'By city name',
            description: 'Using city name you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'your city name query',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityID/threeHourForecast`] = {
        get: {
            tags: ['3 hour forecast'],
            summary: 'By city ID',
            description:
                'Using city ID you can get weather data if you know ID.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityId',
                    in: 'query',
                    description: 'City ID',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byGeoCord/threeHourForecast`] = {
        get: {
            tags: ['3 hour forecast'],
            summary: 'By geographic coordinates',
            description:
                'Using lat, lon coordinates of the location you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'lat',
                    in: 'query',
                    description: 'Latitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'long',
                    in: 'query',
                    description: 'Longitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byZipCode/threeHourForecast`] = {
        get: {
            tags: ['3 hour forecast'],
            summary: 'By ZIP code',
            description:
                'Pass ZIP code & Country Code as param and get weather data. (Please note if country is not specified then the search works for USA as a default.)',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'zipCode',
                    in: 'query',
                    description: 'ZIP code of the city',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'countryCode',
                    in: 'query',
                    description:
                        "Country Code and by default it will took 'US'",
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[
        `/${username}/${appId}/${token}/byMultiCity/threeHourForecast`
    ] = {
        get: {
            tags: ['3 hour forecast'],
            summary: 'Call for several city IDs',
            description:
                'Get mutiple city weather data by passing mutiple city IDs.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityIDs',
                    in: 'query',
                    description: 'city IDs seprated by comma',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityName/forecastHourly`] = {
        get: {
            tags: ['Hourly Forecast'],
            summary: 'By city name',
            description: 'Using city name you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'your city name query',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityID/forecastHourly`] = {
        get: {
            tags: ['Hourly Forecast'],
            summary: 'By city ID',
            description:
                'Using city ID you can get weather data if you know ID.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityId',
                    in: 'query',
                    description: 'City ID',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byGeoCord/forecastHourly`] = {
        get: {
            tags: ['Hourly Forecast'],
            summary: 'By geographic coordinates',
            description:
                'Using lat, lon coordinates of the location you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'lat',
                    in: 'query',
                    description: 'Latitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'long',
                    in: 'query',
                    description: 'Longitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byZipCode/forecastHourly`] = {
        get: {
            tags: ['Hourly Forecast'],
            summary: 'By ZIP code',
            description:
                'Pass ZIP code & Country Code as param and get weather data. (Please note if country is not specified then the search works for USA as a default.)',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'zipCode',
                    in: 'query',
                    description: 'ZIP code of the city',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'countryCode',
                    in: 'query',
                    description:
                        "Country Code and by default it will took 'US'",
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byMultiCity/forecastHourly`] = {
        get: {
            tags: ['Hourly Forecast'],
            summary: 'Call for several city IDs',
            description:
                'Get mutiple city weather data by passing mutiple city IDs.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityIDs',
                    in: 'query',
                    description: 'city IDs seprated by comma',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityName/forecastDaily`] = {
        get: {
            tags: ['By city ID'],
            summary: 'By city name',
            description: 'Using city name you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'your city name query',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byCityID/forecastDaily`] = {
        get: {
            tags: ['By city ID'],
            summary: 'By city ID',
            description:
                'Using city ID you can get weather data if you know ID.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityId',
                    in: 'query',
                    description: 'City ID',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byGeoCord/forecastDaily`] = {
        get: {
            tags: ['By city ID'],
            summary: 'By geographic coordinates',
            description:
                'Using lat, lon coordinates of the location you can get weather data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'lat',
                    in: 'query',
                    description: 'Latitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'long',
                    in: 'query',
                    description: 'Longitude',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byZipCode/forecastDaily`] = {
        get: {
            tags: ['By city ID'],
            summary: 'By ZIP code',
            description:
                'Pass ZIP code & Country Code as param and get weather data. (Please note if country is not specified then the search works for USA as a default.)',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'zipCode',
                    in: 'query',
                    description: 'ZIP code of the city',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'countryCode',
                    in: 'query',
                    description:
                        "Country Code and by default it will took 'US'",
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/byMultiCity/forecastDaily`] = {
        get: {
            tags: ['By city ID'],
            summary: 'Call for several city IDs',
            description:
                'Get mutiple city weather data by passing mutiple city IDs.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'cityIDs',
                    in: 'query',
                    description: 'city IDs seprated by comma',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'lang',
                    in: 'query',
                    description: 'language code of the country.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
