import React, { useEffect, useState } from 'react';
import { numberOfCardsToCreate, outputAs, sourceOfInformation, typeOfCard } from './option';

import { Form, Select } from 'antd';

const AntiFlashCardWriter = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    'Type of card': null,
    'Number of cards to create': null,
    'Source of information': null,
    'Output As': null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Type of card'>
        <Select
          options={typeOfCard}
          placeholder='Type of card'
          onChange={(value) => {
            setFormKey('Type of card', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='Number of cards to create'
      >
        <Select
          options={numberOfCardsToCreate}
          placeholder='Number of cards to create'
          onChange={(value) => {
            setFormKey('Number of cards to create', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='         Source of information'
      >
        <Select
          options={sourceOfInformation}
          placeholder='Source of information'
          onChange={(value) => {
            setFormKey('Source of information', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Output As'>
        <Select
          options={outputAs}
          placeholder='Output As'
          onChange={(value) => {
            setFormKey('Output As', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
    </Form>
  );
};

export default AntiFlashCardWriter;
