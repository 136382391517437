exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Get Tweets',
            description: 'List all yours tweets as json',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'type',
                    in: 'query',
                    description:
                        'Tweets of users timeline. There are three type: home_timeline, user_timeline, mentions_timeline',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'Total number of records you want in one request. Default value is 20.',
                    type: 'string',
                },
                {
                    name: 'since_id',
                    in: 'query',
                    description:
                        'Returns results with an ID greater than (that is, more recent than) the specified ID.',
                    type: 'string',
                },
                {
                    name: 'until_id',
                    in: 'query',
                    description:
                        'Returns results with an ID less than (that is, older than) or equal to the specified ID.',
                    type: 'string',
                },
                {
                    name: 'end_time',
                    in: 'query',
                    description:
                        'Returns tweets created before the given date. Date should be formatted as YYYY-MM-DD. Keep in mind that the search index has a 7-day limit.',
                    type: 'string',
                },
                {
                    name: 'exclude_replies',
                    in: 'query',
                    description:
                        'This parameter will prevent replies from appearing in the returned timeline.',
                    type: 'string',
                },
                {
                    name: 'next_token',
                    in: 'query',
                    description:
                        'Token to get next set of results',
                    type: 'string',
                },
                {
                    name: 'previous_token',
                    in: 'query',
                    description:
                        'Token to get previous set of results',
                    type: 'string',
                }
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        post: {
            tags: ['Twitter'],
            summary: 'Make A Tweet',
            description: 'Make tweet with simple POST call.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'status',
                    in: 'query',
                    description: 'text that you wanna tweet',
                    type: 'string',
                    required: true,
                },
            ],
        },
    };

    schemaObj[`/${username}/${appId}/${token}/lookup`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Tweet Lookup',
            description: 'Lookup for a tweet',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id_str',
                    in: 'query',
                    description: 'tweet id string',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/followers`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Get Followers',
            description:
                'Returns a cursored collection of user objects for users following the specified user.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'The ID of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'The number of users to return per page, up to a maximum of 100. Defaults to 20.',
                    type: 'string',
                },
                {
                    name: 'pagination_token',
                    in: 'query',
                    description:
                        'The next of previous pagination token, to get next or previous set of results',
                    type: 'string',
                },
                {
                    name: 'include_user_entities',
                    in: 'query',
                    description:
                        'The user object entities node will not be included when set to false',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/followings`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Following List',
            description:
                'Returns a cursored collection of user objects for every user the specified user is following (otherwise known as their "friends").',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'The ID of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'The number of users to return per page, up to a maximum of 200. Defaults to 20.',
                    type: 'string',
                },
                {
                    name: 'include_user_entities',
                    in: 'query',
                    description:
                        'The user object entities node will not be included when set to false',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/follow`] = {
        post: {
            tags: ['Twitter'],
            summary: 'Follow',
            description: 'Follow the user you wants to make friend.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'screen_name',
                    in: 'query',
                    description: 'The screen name of the user to follow.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'The ID of the user to follow.',
                    type: 'string',
                },
                {
                    name: 'follow',
                    in: 'query',
                    description: 'Enable notifications for the target user.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/unfollow`] = {
        post: {
            tags: ['Twitter'],
            summary: 'Unfollow',
            description: 'Unfollow a user from your friend list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'screen_name',
                    in: 'query',
                    description: 'The screen name of the user to follow.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'user_id',
                    in: 'query',
                    description: 'The ID of the user to follow.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/updateProfile`] = {
        post: {
            tags: ['Twitter'],
            summary: 'Update Profile',
            description: 'Update your twitter bio data',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'name',
                    in: 'query',
                    description: 'Full name associated with the profile.',
                    type: 'string',
                },
                {
                    name: 'url',
                    in: 'query',
                    description:
                        'URL associated with the profile. Will be prepended with http:// if not present.',
                    type: 'string',
                },
                {
                    name: 'location',
                    in: 'query',
                    description:
                        'The city or country describing where the user of the account is located. The contents are not normalized or geocoded in any way.',
                    type: 'string',
                },
                {
                    name: 'description',
                    in: 'query',
                    description:
                        'A description of the user owning the account.',
                    type: 'string',
                },
                {
                    name: 'profile_link_color',
                    in: 'query',
                    description:
                        "Sets a hex value that controls the color scheme of links used on the authenticating user's profile page on twitter.com. This must be a valid hexadecimal value, and may be either three or six characters (ex: F00 or FF0000).",
                    type: 'string',
                },
                {
                    name: 'include_entities',
                    in: 'query',
                    description:
                        'The entities node will not be included when set to false.',
                    type: 'string',
                },
                {
                    name: 'skip_status',
                    in: 'query',
                    description:
                        'When set to either true , t or 1 statuses will not be included in the returned user object.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/lists`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Get Lists',
            description:
                'Returns all lists the authenticating or specified user subscribes to, including their own.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'The ID of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user for whom to return results.',
                    type: 'string',
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'The number of users to return per page, up to a maximum of 100. Defaults to 20.',
                    type: 'string',
                },
                {
                    name: 'pagination_token',
                    in: 'query',
                    description:
                        'The next of previous pagination token, to get next or previous set of results',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listStatuses`] = {
        get: {
            tags: ['Twitter'],
            summary: 'List Statuses',
            description:
                'Returns a timeline of tweets authored by members of the specified list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'The numerical id of the list.',
                    type: 'string',
                },
                {
                    name: 'slug',
                    in: 'query',
                    description:
                        "You can identify a list by its slug instead of its numerical id. If you decide to do so, note that you'll also have to specify the list owner using the owner_id or owner_screen_name parameters.",
                    type: 'string',
                },
                {
                    name: 'owner_screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user who owns the list being requested by a slug.',
                    type: 'string',
                },
                {
                    name: 'owner_id',
                    in: 'query',
                    description:
                        'The user ID of the user who owns the list being requested by a slug .',
                    type: 'string',
                },
                {
                    name: 'since_id',
                    in: 'query',
                    description:
                        'Returns results with an ID greater than (that is, more recent than) the specified ID. There are limits to the number of Tweets which can be accessed through the API. If the limit of Tweets has occured since the since_id, the since_id will be forced to the oldest ID available.',
                    type: 'string',
                },
                {
                    name: 'max_id',
                    in: 'query',
                    description:
                        'Returns results with an ID less than (that is, older than) or equal to the specified ID.',
                    type: 'string',
                },
                {
                    name: 'count',
                    in: 'query',
                    description:
                        'Specifies the number of results to retrieve per "page."',
                    type: 'string',
                },
                {
                    name: 'include_entities',
                    in: 'query',
                    description:
                        'You can omit entities from the result by using include_entities=false',
                    type: 'string',
                },
                {
                    name: 'list_id',
                    in: 'query',
                    description:
                        'When set to either true , t or 1 , the list timeline will contain native retweets',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listMembers`] = {
        get: {
            tags: ['Twitter'],
            summary: 'List Members',
            description: 'Get all the members of a list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'The numerical id of the list.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'owner_id',
                    in: 'query',
                    description:
                        'The user ID of the user who owns the list being requested by a slug.',
                    type: 'string',
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'Specifies the number of results to return per page. The default is 20, with a maximum of 5,000.',
                    type: 'string',
                },
                {
                    name: 'pagination_token',
                    in: 'query',
                    description:
                        'Token to get result set',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listSubscribers`] = {
        get: {
            tags: ['Twitter'],
            summary: 'List Subscribers',
            description: 'Returns the subscribers of the specified list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'The numerical id of the list.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'slug',
                    in: 'query',
                    description:
                        "You can identify a list by its slug instead of its numerical id. If you decide to do so, note that you'll also have to specify the list owner using the owner_id or owner_screen_name parameters.",
                    type: 'string',
                },
                {
                    name: 'owner_screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user who owns the list being requested by a slug.',
                    type: 'string',
                },
                {
                    name: 'owner_id',
                    in: 'query',
                    description:
                        'The user ID of the user who owns the list being requested by a slug.',
                    type: 'string',
                },
                {
                    name: 'count',
                    in: 'query',
                    description:
                        'Specifies the number of results to return per page (see cursor below). The default is 20, with a maximum of 5,000.',
                    type: 'string',
                },
                {
                    name: 'cursor',
                    in: 'query',
                    description:
                        'Causes the collection of list members to be broken into "pages" of consistent sizes (specified by the count parameter). If no cursor is provided, a value of -1 will be assumed, which is the first "page."',
                    type: 'string',
                },
                {
                    name: 'include_entities',
                    in: 'query',
                    description:
                        'When set to either true , t or 1 , each tweet will include a node called "entities".',
                    type: 'string',
                },
                {
                    name: 'skip_status',
                    in: 'query',
                    description:
                        'When set to either true , t or 1 statuses will not be included in the returned user objects.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/addMemberToList`] = {
        post: {
            tags: ['Twitter'],
            summary: 'Add member to List',
            description: 'Add a member to a list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'The numerical id of the list.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'The ID of the user for whom to return results.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'screen_name',
                    in: 'query',
                    description:
                        'The screen name of the user for whom to return results. ',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/removeMembersFromList`] = {
        post: {
            tags: ['Twitter'],
            summary: 'Remove Members',
            description: 'Remove mutiple members from a list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'The numerical id of the list.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'A comma separated list of user IDs, up to 100 are allowed in a single request.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'screen_name',
                    in: 'query',
                    description:
                        'A comma separated list of screen names, up to 100 are allowed in a single request.',
                    type: 'string',
                }
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/trendsPlace`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Trends Place',
            description: 'Returns the top 50 trending topics for a specific id',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description:
                        'The numeric value that represents the location from where to return trending information for from. Formerly linked to the Yahoo! Where On Earth ID Global information is available by using 1 as the WOEID. Example 1',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'exclude',
                    in: 'query',
                    description:
                        'Setting this equal to hashtags will remove all hashtags from the trends list.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/trendsAvailable`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Trends Available',
            description:
                'Returns the locations that Twitter has trending topic information for.',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Twitter'],
            summary: 'Search Tweets',
            description: 'Search Tweets by passing q param',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description:
                        'A UTF-8, URL-encoded search query of 500 characters maximum, including operators. Queries may additionally be limited by complexity.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'max_results',
                    in: 'query',
                    description:
                        'Total number of records you want in one request. Default value is 20.',
                    type: 'string',
                },
                {
                    name: 'end_time',
                    in: 'query',
                    description:
                        'Returns tweets created before the given date. Date should be formatted as YYYY-MM-DD. Keep in mind that the search index has a 7-day limit.',
                    type: 'string',
                },
                {
                    name: 'since_id',
                    in: 'query',
                    description:
                        'Returns results with an ID greater than (that is, more recent than) the specified ID.',
                    type: 'string',
                },
                {
                    name: 'until_id',
                    in: 'query',
                    description:
                        'Returns results with an ID less than (that is, older than) or equal to the specified ID.',
                    type: 'string',
                },
                {
                    name: 'max_id',
                    in: 'query',
                    description:
                        'Returns results with an ID less than (that is, older than) or equal to the specified ID.',
                    type: 'string',
                },
                {
                    name: 'next_token',
                    in: 'query',
                    description:
                        'Token to get next set of results',
                    type: 'string',
                },
                {
                    name: 'previous_token',
                    in: 'query',
                    description:
                        'Token to get previous set of results',
                    type: 'string',
                }
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
