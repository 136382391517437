exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Pocket'],
            summary: 'Retrieve Items',
            description: "To retrieve item(s) from a user's Pocket list",
            produces: ['application/json'],
            parameters: [
                {
                    name: 'state',
                    in: 'query',
                    description: 'unread, archive, or all',
                    type: 'string',
                },
                {
                    name: 'favorite',
                    in: 'query',
                    description: '0 or 1',
                    type: 'string',
                },
                {
                    name: 'tag',
                    in: 'query',
                    description: '0 or 1',
                    type: 'string',
                },
                {
                    name: 'contentType',
                    in: 'query',
                    description: 'article, video, or image',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'newest, oldest, title, or site',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Pocket'],
            summary: 'Add Item',
            description: 'Add single Item into pocket list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'The URL of the item you want to save',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'title',
                    in: 'query',
                    description:
                        'This can be included for cases where an item does not have a title, which is typical for image or PDF URLs. If Pocket detects a title from the content of the page, this parameter will be ignored.',
                },
                {
                    name: 'tags',
                    in: 'query',
                    description: 'A comma-separated list of tags to apply to the item',
                },
                {
                    name: 'tweet_id',
                    in: 'query',
                    description:
                        'If you are adding Pocket support to a Twitter client, please send along a reference to the tweet status id. This allows Pocket to show the original tweet alongside the article.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        put: {
            tags: ['Pocket'],
            summary: 'Modify',
            description:
                'Modify endpoint allow you make a change or batch several changes to a user’s list or Pocket data.',
            produces: ['application/json'],
            isQueryParam: false,
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: ' list of actions should be sent as a JSON array.',
                    required: true,
                    sampleData: [
                        {
                            action: 'archive',
                            item_id: '229279689',
                            time: '1348853312',
                        },
                    ],
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
