exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/forms`] = {
        get: {
            tags: ['Typeform'],
            summary: 'Get Forms',
            description: 'List all Typeform froms from your account as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description:
                        'The page of results to retrieve. Default 1 is the first page of results.',
                },
                {
                    name: 'pageSize',
                    in: 'query',
                    description:
                        'Number of results to retrieve per page. Default is 10. Maximum is 200.',
                },
                {
                    name: 'search',
                    in: 'query',
                    description:
                        'Returns items that contain the specified string.',
                },
                {
                    name: 'workspaceId',
                    in: 'query',
                    description:
                        'Retrieve typeforms for the specified workspace.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/getForm`] = {
        get: {
            tags: ['Typeform'],
            summary: 'Form Metadata',
            description:
                'Get the form schema, metadata as JSON with single endpoint',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'formId',
                    in: 'query',
                    description:
                        'Unique ID for the form. Find in your form URL. For example, in the URL "https://mysite.typeform.com/to/u6nXL7" the form_id is <b>u6nXL7</b>',
                    required: true,
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/formResponses`] = {
        get: {
            tags: ['Typeform'],
            summary: 'Get Form Responses',
            description: 'Returns form responses as JSON results',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'formId',
                    in: 'query',
                    description:
                        'Unique ID for the form. Find in your form URL. For example, in the URL "https://mysite.typeform.com/to/u6nXL7" the form_id is <b>u6nXL7</b>',
                    required: true,
                },
                {
                    name: 'pageSize',
                    in: 'query',
                    description:
                        'Maximum number of responses. Default value is 25. Maximum value is 1000. If your typeform has more than 1000 responses, use the <b>since/until</b> or <b>before/after</b> query parameters',
                },
                {
                    name: 'since',
                    in: 'query',
                    description:
                        'Limit request to responses submitted since the specified date and time. Could be passed as int (timestamp in seconds) or in ISO 8601 format, UTC time, to the second, with T as a delimiter between the date and time (<b>2020-03-20T14:00:59</b>).',
                },
                {
                    name: 'until',
                    in: 'query',
                    description:
                        'Limit request to responses submitted until the specified date and time. Could be passed as int (timestamp in seconds) or in ISO 8601 format, UTC time, to the second, with T as a delimiter between the date and time (<b>2020-03-20T14:00:59</b>).',
                },
                {
                    name: 'after',
                    in: 'query',
                    description:
                        'Limit request to responses submitted after the specified token. Could not be used together with <b>sort</b> parameter, as it sorts responses in the order that our system processed them (<b>submitted_at</b>).',
                },
                {
                    name: 'before',
                    in: 'query',
                    description:
                        'Limit request to responses submitted before the specified token. Could not be used together with <b>sort</b> parameter, as it sorts responses in the order that our system processed them (<b>submitted_at</b>). ',
                },
                {
                    name: 'completed',
                    in: 'query',
                    description:
                        'Limit responses only to those which were submitted. This parameter changes <b>since/until</b> filter, so if <b>completed=true</b>, it will filter by <b>submitted_at</b>, otherwise - <b>landed_at</b>.',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description:
                        'Responses order in <b>{fieldID},{asc|desc}</b> format. You can use built-in <b>submitted_at/landed_at</b> field IDs or any field ID from your typeform, possible directions are <b>asc/desc</b>. Default value is <b>submitted_at,desc</b>.',
                },
                {
                    name: 'query',
                    in: 'query',
                    description:
                        'Limit request to only responses that include the specified string.',
                },
                {
                    name: 'fields',
                    in: 'query',
                    description:
                        'Show only specified fields in answers section. Use a comma-separated list to specify more than one <b>field</b> value.',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
