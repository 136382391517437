import axios from 'axios';

export const makeNewAPI = async function (
      appObj,
      uid,
      authType,
      connectionID,
      projectId
) {
      try {
            let endpoint;
            const dataObj = { appObj, uid, projectId };
            if (authType === 'key' || authType === 'key2') {
                  endpoint = 'make-api';
            } else {
                  endpoint = 'make-oauth-api';
                  dataObj._id = connectionID;
            }

            const response = await axios.post(
                  `${process.env.GATSBY_BASE_URL}/${endpoint}`,
                  dataObj,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            );
            if (response.status === 201) {
                  return { status: 201, message: response.data.message };
            }
            if (response.status === 200) {
                  return { status: 200, message: response.data.message };
            }
            return { status: 500, message: response.data.message };
      } catch (error) {
            return { status: 500, message: error.message };
      }
};

export const updateAPI = async function (appObj, _id) {
      try {
            const response = await axios.put(
                  `${process.env.GATSBY_BASE_URL}/make-api`,
                  { appObj, _id },
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            );
            if (response.status === 200) {
                  return { status: 200, message: '‎ℹ Successfuly updated' };
            }
            return { status: 500, message: '‎ℹ Something is wrong' };
      } catch (error) {
            return { status: 500, message: '‎ℹ Something is wrong' };
      }
};
