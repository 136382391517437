exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/index`] = {
        post: {
            tags: ['Algolia'],
            summary: 'Add or Replace Objects',
            description:
                'To add mutiple objects pass indexName as query params & Array of Objects into body',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'indexName',
                    in: 'query',
                    description: 'Name of your Indice from Algolia.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'type',
                    in: 'query',
                    description: 'add or replace',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'type',
                    in: 'body',
                    description: 'Array of objects',
                    required: true,
                    sampleData: [
                        {
                            firstname: 'Jimmie',
                            lastname: 'Barninger',
                        },
                    ],
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Algolia'],
            summary: 'Search',
            description:
                'Search your index result from Algolia by passing q param',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'indexName',
                    in: 'query',
                    description: 'Name of your Indice from Algolia.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'q',
                    in: 'query',
                    description: 'You search query to get result',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
