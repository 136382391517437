exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/listBuckets`] = {
        get: {
            tags: ['AWS S3'],
            summary: 'List Buckets',
            description: 'List all the buckets from your AWS account as JSON',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/listBucketObjects`] = {
        get: {
            tags: ['AWS S3'],
            summary: 'List Bucket Objects',
            description: 'List all the object of a bucket as JSON',
            parameters: [
                {
                    name: 'bucketName',
                    in: 'query',
                    description: 'bucket name',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/getObject`] = {
        get: {
            tags: ['AWS S3'],
            summary: 'Get Object',
            description: 'Get single JSON object',
            parameters: [
                {
                    name: 'bucketName',
                    in: 'query',
                    description: 'bucket name',
                    required: true,
                },
                {
                    name: 'objectKey',
                    in: 'query',
                    description: 'Object Key',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/putObject`] = {
        post: {
            tags: ['AWS S3'],
            summary: 'Put Object',
            description: 'Upload file to bucket',
            parameters: [
                {
                    name: 'bucketName',
                    in: 'query',
                    description: 'Pass the text',
                    required: true,
                },
                {
                    name: 'file',
                    in: 'formData',
                    type: 'file',
                    description: 'file to upload',
                    required: true,
                },
                {
                    name: 'fileName',
                    in: 'query',
                    description: 'file name',
                },
                {
                    name: 'ACL',
                    in: 'query',
                    description:
                        'a set of predefined grants like `private`, `public-read`, `public-read-write`, `aws-exec-read`, `authenticated-read`, `bucket-owner-read`, `bucket-owner-full-control`, `log-delivery-write`',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/putRemoteObject`] = {
        post: {
            tags: ['AWS S3'],
            summary: 'Upload remote file',
            description: 'You can upload any remote file in your s3 bucket',
            parameters: [
                {
                    name: 'bucketName',
                    in: 'query',
                    description: 'Pass the text',
                    required: true,
                },
                {
                    name: 'url',
                    in: 'query',
                    description: 'remote file url to upload',
                    required: true,
                },
                {
                    name: 'fileName',
                    in: 'query',
                    description: 'file name',
                },
                {
                    name: 'ACL',
                    in: 'query',
                    description:
                        'a set of predefined grants like `private`, `public-read`, `public-read-write`, `aws-exec-read`, `authenticated-read`, `bucket-owner-read`, `bucket-owner-full-control`, `log-delivery-write`',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
