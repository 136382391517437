exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Contentful'],
            summary: 'Get All Entries',
            description: 'List all entries of your CMS.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description:
                        'Total number of records you want in one request.',
                    required: false,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/singleEntry`] = {
        get: {
            tags: ['Contentful'],
            summary: 'Get Single Entry',
            description: 'Single object of your post.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'id of the entry',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
