exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        post: {
            tags: ['Slack'],
            summary: 'Send Data to Channel',
            description: 'Pass object data into body as POST request and you will recieved the data in your channel',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an array of row array into body',
                    required: true,
                    sampleData: {
                        email: 'hello@nocodeapi.com',
                        notification: 'payment',
                        source: 'nocodeapi.com',
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/sendText`] = {
        post: {
            tags: ['Slack'],
            summary: 'Send Text to Channel',
            description: 'Pass text data as query POST request and you will recieved the data in your channel',
            parameters: [
                {
                    name: 'text',
                    in: 'query',
                    description: 'Pass the text',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
