exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/sites`] = {
        get: {
            tags: ['Netlify'],
            summary: 'List Sites',
            description: 'List all the sites from your netlify account.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'name',
                    in: 'query',
                    description: 'site name',
                },
                {
                    name: 'filter',
                    in: 'query',
                    description: 'Enum: "all" "owner" "guest"',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination number',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of object in API call',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/listForms`] = {
        get: {
            tags: ['Netlify'],
            summary: 'List forms',
            description: 'List all forms of a site',
            parameters: [
                {
                    name: 'site_id',
                    in: 'query',
                    description: 'site id',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/listFormSubmissions`] = {
        get: {
            tags: ['Netlify'],
            summary: 'List form Submissions',
            description: 'List of objects into a form',
            parameters: [
                {
                    name: 'form_id',
                    in: 'query',
                    description: 'form id',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination number',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of object in API call',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/listSiteSubmissions`] = {
        get: {
            tags: ['Netlify'],
            summary: 'Site Submissions',
            description: 'Get all the submission for a site.',
            parameters: [
                {
                    name: 'site_id',
                    in: 'query',
                    description: 'site id',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination number',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Number of object in API call',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
