exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/calendarList`] = {
        get: {
            tags: ['Google Calendar'],
            summary: 'List all Calendars',
            description: 'List all the calendars data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'Maximum number of entries returned on one result page. default is 100',
                },
                {
                    name: 'minAccessRole',
                    in: 'query',
                    description:
                        'The minimum access role for the user in the returned entries. Acceptable values are: freeBusyReader, owner, reader, writer',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'Token specifying which result page to return',
                },
                {
                    name: 'showDeleted',
                    in: 'query',
                    description: 'Whether to include deleted calendar list entries in the result. default is false',
                },
                {
                    name: 'showHidden',
                    in: 'query',
                    description: 'Whether to show hidden entries. default is false',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/listEvents`] = {
        get: {
            tags: ['Google Calendar'],
            summary: 'List Events',
            description: 'List all the calendar events as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'calendarId',
                    in: 'query',
                    description: 'Calendar identifier. by defauls is "primary"',
                },
                {
                    name: 'alwaysIncludeEmail',
                    in: 'query',
                    description:
                        'A value will always be returned in the email field for the organizer, creator and attendees, even if no real email address is available (i.e. a generated, non-working value will be provided).',
                },
                {
                    name: 'iCalUID',
                    in: 'query',
                    description: 'Specifies event ID in the iCalendar format to be included in the response.',
                },
                {
                    name: 'maxAttendees',
                    in: 'query',
                    description: 'The maximum number of attendees to include in the response.',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'Maximum number of entries returned on one result page. default is 100',
                },
                {
                    name: 'orderBy',
                    in: 'query',
                    description:
                        'The order of the events returned in the result. Acceptable values are: startTime, updated',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'Token specifying which result page to return..',
                },
                {
                    name: 'q',
                    in: 'query',
                    description:
                        'Free text search terms to find events that match these terms in any field, except for extended properties.',
                },
                {
                    name: 'showDeleted',
                    in: 'query',
                    description: 'Whether to include deleted events (with status equals "cancelled") in the result.',
                },
                {
                    name: 'showHiddenInvitations',
                    in: 'query',
                    description: 'Whether to include hidden invitations in the result.',
                },
                {
                    name: 'singleEvents',
                    in: 'query',
                    description:
                        'Whether to expand recurring events into instances and only return single one-off events and instances of recurring events, but not the underlying recurring events themselves.',
                },
                {
                    name: 'timeMax',
                    in: 'query',
                    description: "Upper bound (exclusive) for an event's start time to filter by.",
                },
                {
                    name: 'timeMin',
                    in: 'query',
                    description: "Lower bound (exclusive) for an event's end time to filter by",
                },
                {
                    name: 'timeZone',
                    in: 'query',
                    description: 'Time zone used in the response.',
                },
                {
                    name: 'updatedMin',
                    in: 'query',
                    description:
                        "Lower bound for an event's last modification time (as a RFC3339 timestamp) to filter by.",
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/event`] = {
        get: {
            tags: ['Google Calendar'],
            summary: 'Get single event',
            description: 'Get single event data as JSON with eventId',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'eventId',
                    in: 'query',
                    required: true,
                    description: 'Event identifier.',
                },
                {
                    name: 'calendarId',
                    in: 'query',
                    description: 'Calendar identifier. by defauls is "primary"',
                },
                {
                    name: 'alwaysIncludeEmail',
                    in: 'query',
                    description:
                        'A value will always be returned in the email field for the organizer, creator and attendees, even if no real email address is available (i.e. a generated, non-working value will be provided).',
                },
                {
                    name: 'maxAttendees',
                    in: 'query',
                    description: 'The maximum number of attendees to include in the response.',
                },
                {
                    name: 'timeZone',
                    in: 'query',
                    description: 'Time zone used in the response.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Google Calendar'],
            summary: 'Creates an event.',
            description: 'Add new event into your calendar',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'calendarId',
                    in: 'query',
                    description: 'Calendar identifier. by defauls is "primary"',
                },
                {
                    name: 'maxAttendees',
                    in: 'query',
                    description: 'The maximum number of attendees to include in the response.',
                },
                {
                    name: 'sendNotifications',
                    in: 'query',
                    description: 'Whether to send notifications about the creation of the new event.',
                },
                {
                    name: 'sendUpdates',
                    in: 'query',
                    description:
                        'Whether to send notifications about the creation of the new event. Acceptable values are: all, externalOnly, none',
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an object',
                    required: true,
                    sampleData: {
                        summary: 'Event summary',
                        location: 'Event location details',
                        description: 'Event description',
                        start: {
                            dateTime: '2021-02-21T21:00:00+05:30',
                            timeZone: 'IST',
                        },
                        end: {
                            dateTime: '2021-02-21T21:30:00+05:30',
                            timeZone: 'IST',
                        },
                        recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                        sendNotifications: true,
                        attendees: [{ email: 'hello@example.com' }, { email: 'sales@example.com' }],
                    },
                    example: {
                        summary: 'Event summary',
                        location: 'Event location details',
                        description: 'Event description',
                        start: {
                            dateTime: '2021-02-21T21:00:00+05:30',
                            timeZone: 'IST',
                        },
                        end: {
                            dateTime: '2021-02-21T21:30:00+05:30',
                            timeZone: 'IST',
                        },
                        recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                        sendNotifications: true,
                        attendees: [{ email: 'hello@example.com' }, { email: 'sales@example.com' }],
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        put: {
            tags: ['Google Calendar'],
            summary: 'Updates an event.',
            description: 'Update event fields in your calendar',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'eventId',
                    in: 'query',
                    required: true,
                    description: 'Event identifier.',
                },
                {
                    name: 'calendarId',
                    in: 'query',
                    description: 'Calendar identifier. by defauls is "primary"',
                },
                {
                    name: 'maxAttendees',
                    in: 'query',
                    description: 'The maximum number of attendees to include in the response.',
                },
                {
                    name: 'sendNotifications',
                    in: 'query',
                    description: 'Whether to send notifications about the creation of the new event.',
                },
                {
                    name: 'sendUpdates',
                    in: 'query',
                    description:
                        'Whether to send notifications about the creation of the new event. Acceptable values are: all, externalOnly, none',
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass an object',
                    required: true,
                    sampleData: {
                        summary: 'Event summary',
                        location: 'Event location details',
                        description: 'Event description',
                        start: {
                            dateTime: '2021-02-21T21:00:00+05:30',
                            timeZone: 'IST',
                        },
                        end: {
                            dateTime: '2021-02-21T21:30:00+05:30',
                            timeZone: 'IST',
                        },
                        recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                        sendNotifications: true,
                        attendees: [{ email: 'hello@example.com' }, { email: 'sales@example.com' }],
                    },
                    example: {
                        summary: 'Event summary',
                        location: 'Event location details',
                        description: 'Event description',
                        start: {
                            dateTime: '2021-02-21T21:00:00+05:30',
                            timeZone: 'IST',
                        },
                        end: {
                            dateTime: '2021-02-21T21:30:00+05:30',
                            timeZone: 'IST',
                        },
                        recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                        sendNotifications: true,
                        attendees: [{ email: 'hello@example.com' }, { email: 'sales@example.com' }],
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        delete: {
            tags: ['Google Calendar'],
            summary: 'Deletes an event',
            description: 'Delete an event from your calendar',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'eventId',
                    in: 'query',
                    required: true,
                    description: 'Event identifier.',
                },
                {
                    name: 'calendarId',
                    in: 'query',
                    description: 'Calendar identifier. by defauls is "primary"',
                },
                {
                    name: 'sendNotifications',
                    in: 'query',
                    description: 'Whether to send notifications about the creation of the new event.',
                },
                {
                    name: 'sendUpdates',
                    in: 'query',
                    description:
                        'Whether to send notifications about the creation of the new event. Acceptable values are: all, externalOnly, none',
                },
            ],
            responses: {
                '204': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
