exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/posts`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Posts',
            description: 'WordPress Posts lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: author, date, id, include, modified, parent, relevance, slug, include_slugs, title',
                },
                {
                    name: 'status',
                    in: 'query',
                    description: 'Limit result set to posts assigned one or more statuses.',
                },
                {
                    name: 'categories',
                    in: 'query',
                    description:
                        'Limit result set to all items that have the specified term assigned in the categories taxonomy.',
                },
                {
                    name: 'categories_exclude',
                    in: 'query',
                    description:
                        'Limit result set to all items except those that have the specified term assigned in the categories taxonomy.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/post/{post_id}`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Post',
            description: 'WordPress Posts lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'post_id',
                    in: 'path',
                    description: 'id of the collection',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/categories`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Categories',
            description: 'WordPress Categories lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: author, date, id, include, modified, parent, relevance, slug, include_slugs, title',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/tags`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Tags',
            description: 'WordPress Tags lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: author, date, id, include, modified, parent, relevance, slug, include_slugs, title',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/pages`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Pages',
            description: 'WordPress Pages lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'author',
                    in: 'query',
                    description: 'Limit result set to posts assigned to specific authors.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: author, date, id, include, modified, parent, relevance, slug, include_slugs, title',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/comments`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Comments',
            description: 'WordPress Comments lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'author',
                    in: 'query',
                    description: 'Limit result set to posts assigned to specific authors.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: date, date_gmt, id, include, post, parent, type',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/media`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Media',
            description: 'WordPress Media lists',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
                {
                    name: 'author',
                    in: 'query',
                    description: 'Limit result set to posts assigned to specific authors.',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'Order sort attribute ascending or descending. ex: asc, desc',
                },
                {
                    name: 'orderby',
                    in: 'query',
                    description:
                        'Sort collection by object attribute.. ex: author, date, id, include, modified, parent, relevance, slug, include_slugs, title',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['WordPress'],
            summary: 'Search',
            description: 'Search',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'search keywords',
                    required: true,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'page of the collection',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'Maximum number of items to be returned in result set.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
