exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Google Analytics'],
            summary: 'Get Google Analytics data',
            description: 'Get Google Analytics data',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'metrics',
                    in: 'query',
                    description: 'pageviews or users',
                    required: true,
                },
                {
                    name: 'startDate',
                    in: 'query',
                    description: 'start date EX: YYYY-MM-DD',
                    required: true,
                },
                {
                    name: 'endDate',
                    in: 'query',
                    description: "End date of your date range. by default is 'today' EX: YYYY-MM-DD",
                },
                {
                    name: 'dimensions',
                    in: 'query',
                    description: 'default value is date',
                },
                {
                    name: 'orderBys',
                    in: 'query',
                    description: 'Sort by metrics. default value no sorting',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
