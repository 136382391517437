exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['GoodReads'],
            summary: 'Search Books',
            description: 'Get JSON data for your goodreads search',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'q is query paarm. ex: harry potter.',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/searchAuthor`] = {
        get: {
            tags: ['GoodReads'],
            summary: 'Search Author',
            description: 'Search authors on goodreads',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'q',
                    in: 'query',
                    description: 'q is query paarm. ex: J. K. Rowling',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/myBooks`] = {
        get: {
            tags: ['GoodReads'],
            summary: 'My Books',
            description: 'List of books you are reading, planning to read. books from your shelves.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'uid',
                    in: 'query',
                    description: 'user id from your goodreads url.',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/authorList`] = {
        get: {
            tags: ['GoodReads'],
            summary: 'Author Books List',
            description: 'List of books of any author as JSON.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'author id.',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
