import { mapToSelectOptions } from '../helpers/index';
export const typeOfText = mapToSelectOptions([
  'Landing Page',
  'Landing Page Hero Text',
  'Meta Descriptions',
  'Social Proof Text',
  'Blog Article',
  'Blog Tiltle',
  'Blog Ideas',
  'Blog Outline',
  'Keyword Generator',
  'Confirmation Email',
  'Cancellation Email',
  'Follow Up Email',
  'Welcome Email',
  'Attention-Interest-Desire-Action',
  'Feature-Advantage-Benefit',
  'Problem-Promise-Proof-Proposal',
  'QUEST Copywriting',
  'Hook Generator',
  'Hashtag Generator',
  'Sales Cold Email',
  'General Ad Copy',
  'Google Headlines',
  'Google Descriptions',
  'LinkedIn Ad Copy',
  'Facebook Primary Text',
  'Facebook Headlines',
  'Facebook Link Descriptions',
  'Instagram Captions',
  'TikTok Topics',
  'YouTube Video Title',
  "Add what you need, delete what you don't",
]);
export const style = mapToSelectOptions([
  'Formal',
  'Informal',
  'Casual',
  'Emotional',
  'Creative',
  'Persuasive',
  'Business',
  'Technical',
  'Legal',
  'Medical',
  'Academic',
]);
export const tone = mapToSelectOptions([
  'Funny',
  'Serious',
  'Friendly',
  'Professional',
  'Empathetic',
  'Confident',
  'Enthusiastic',
  'Assertive',
  'Encouraging',
  'Excited',
  'Witty',
  'Sympathetic',
  'Analytical',
  'Authoritative',
  'Romantic',
]);
export const language = mapToSelectOptions([
  'Spanish',
  'French',
  'German',
  'Italian',
  'Arabic',
  'Japanese',
  'Chinese',
  'US English',
  'UK English',
  'Australian English',
  'New Zealand English',
  'Irish',
  'Scottish',
  'Swiss German',
  'Berliner German',
  'Kölsch German',
  'Add your language',
]);
export const audience = mapToSelectOptions([
  'Lay',
  'Managerial',
  'Technicians',
  'Experts',
  'Influencers',
  'Brand Advocates',
  'Customer Advocates',
  'Archetype - Dominance',
  'Archetype - Influence',
  'Archetype - Steadiness',
  'Archetype - Conscientiousness',
]);
export const inTheVoiceOf = mapToSelectOptions([
  'Socrate',
  'Shakespeare',
  'Albert Einstein',
  'Add other famous personas here',
]);
export const slang = mapToSelectOptions([
  'Bro Talk',
  'Sassy girl Talk',
  'Snoop dogg Talk',
  '40s Gangster Talk',
  'Use your imagination and add :)',
]);
export const readerComprehension = mapToSelectOptions([
  '5 years old',
  '10 years old',
  '25 years old',
  '85 years old',
  'IQ 69',
  'IQ 115',
  'IQ 150',
  'beginner, assume no prior knowledge',
  'intermediate, assume some prior knowledge',
  'advanced, assume extensive prior knowledge',
  'PhD Graduate',
  'Triple PhD galactic brain',
]);
export const length = mapToSelectOptions([
  '280 characters',
  'Short, simple and to the point',
  '1 Paragraph',
  '3 Paragraphs',
  '100 Words',
  '300 Words',
  '500 Words',
  '1000 Words',
]);
export const outputAs = mapToSelectOptions([
  'Numbered list',
  'Bulleted list',
  'Bulleted list with nested items',
  'Task List',
  'Markdown table',
  'Markdown table with Column 1: Number',
  'Blockquote',
  'Code block',
  'JSON',
  'CSV',
  'YAML',
  'XML',
  'SQL',
]);

export const formatting = mapToSelectOptions([
  'Bold the important words',
  'Only the text. No comments before and after.',
  'Highlight key words and phrases',
]);
