exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}`] = {
            get: {
                  tags: ['Webflow'],
                  summary: 'Get all items',
                  description: 'List all items from the webflow CMS collection',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'page',
                              in: 'query',
                              description: 'Pagination number.',
                              type: 'string',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Total number of items you want in one request.(0-100). Premium Accounts can use <b>all</b> to fetch more than 100.',
                              required: false,
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            post: {
                  tags: ['Webflow'],
                  summary: 'Add Item',
                  description: 'Add new item into collection',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description: 'Pass an object of item into body',
                              required: true,
                              sampleData: {
                                    id: '42b720ef280c7a7a3be8cabe',
                                    cmsLocaleId: '653ad57de882f528b32e810e',
                                    lastPublished: '2022-11-29T16:22:43.159Z',
                                    lastUpdated: '2022-11-17T17:19:43.282Z',
                                    createdOn: '2022-11-17T17:11:57.148Z',
                                    isArchived: false,
                                    isDraft: false,
                                    fieldData: {
                                          date: '2022-11-18T00:00:00.000Z',
                                          featured: true,
                                          name: 'Pan Galactic Gargle Blaster Recipe',
                                          slug: 'pan-galactic-gargle-blaster',
                                          color: '#db4b68'
                                    }
                              },
                        },
                        {
                              name: 'live',
                              in: 'query',
                              description:
                                    'by default is false If you wanna add item into live then pass true.',
                              type: 'boolean',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            put: {
                  tags: ['Webflow'],
                  summary: 'Update Item',
                  description: 'Update your single item record.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'itemId',
                              in: 'query',
                              description: 'id of the item',
                              required: true,
                              type: 'string',
                        },
                        {
                              name: 'body',
                              in: 'body',
                              description:
                                    'Pass an array of row array into body',
                              required: true,
                              sampleData: {
                                    id: '42b720ef280c7a7a3be8cabe',
                                    cmsLocaleId: '653ad57de882f528b32e810e',
                                    lastPublished: '2022-11-29T16:22:43.159Z',
                                    lastUpdated: '2022-11-17T17:19:43.282Z',
                                    createdOn: '2022-11-17T17:11:57.148Z',
                                    isArchived: false,
                                    isDraft: false,
                                    fieldData: {
                                          date: '2022-11-18T00:00:00.000Z',
                                          featured: true,
                                          name: 'Pan Galactic Gargle Blaster Recipe',
                                          slug: 'pan-galactic-gargle-blaster',
                                          color: '#db4b68'
                                    }
                              },
                        },
                        {
                              name: 'live',
                              in: 'query',
                              description:
                                    'by default is false If you wanna update item into live then pass true.',
                              type: 'boolean',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            patch: {
                  tags: ['Webflow'],
                  summary: 'Patch Item',
                  description: 'Patch your single item record.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'itemId',
                              in: 'query',
                              description: 'id of the item',
                              required: true,
                              type: 'string',
                        },
                        {
                              name: 'body',
                              in: 'body',
                              description:
                                    'Pass an array of row array into body',
                              required: true,
                              sampleData: {
                                    id: '42b720ef280c7a7a3be8cabe',
                                    cmsLocaleId: '653ad57de882f528b32e810e',
                                    lastPublished: '2022-11-29T16:22:43.159Z',
                                    lastUpdated: '2022-11-17T17:19:43.282Z',
                                    createdOn: '2022-11-17T17:11:57.148Z',
                                    isArchived: false,
                                    isDraft: false,
                                    fieldData: {
                                          date: '2022-11-18T00:00:00.000Z',
                                          featured: true,
                                          name: 'Pan Galactic Gargle Blaster Recipe',
                                          slug: 'pan-galactic-gargle-blaster',
                                          color: '#db4b68'
                                    }
                              },
                        },
                        {
                              name: 'live',
                              in: 'query',
                              description:
                                    'by default is false If you wanna update item into live then pass true.',
                              type: 'boolean',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            delete: {
                  tags: ['Webflow'],
                  summary: 'Delete Item',
                  description: 'You can delete item from collection.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'itemId',
                              in: 'query',
                              description: 'id of the item',
                              required: true,
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/singleItem`] = {
            get: {
                  tags: ['Webflow'],
                  summary: 'Get single item',
                  description:
                        'Get single items from the webflow CMS collection',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'itemId',
                              in: 'query',
                              description: 'id of the item',
                              required: true,
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/listSites`] = {
            get: {
                  tags: ['Webflow'],
                  summary: 'List Sites',
                  description: 'Get the site data',
                  produces: ['application/json'],
                  parameters: [],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/listCollections`] = {
            get: {
                  tags: ['Webflow'],
                  summary: 'List Collections',
                  description: 'Get all the collestions data as JSON',
                  produces: ['application/json'],
                  parameters: [],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/collectionSchema`] = {
            get: {
                  tags: ['Webflow'],
                  summary: 'Collection Schema',
                  description: 'Get Collection with Full Schema',
                  produces: ['application/json'],
                  parameters: [],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      return schemaObj;
};
