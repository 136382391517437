import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
      Select,
      Modal,
      Form,
      Button,
      Input,
      Collapse,
      Alert,
      Progress,
      message,
} from 'antd';
import ConnectorAPIPlayground from '../connectorPLayground';
import Editor from '@monaco-editor/react';

import { isValidJson, recursiveSearch } from '../../../config/custom/functions';
const _ = require('lodash');
const { Panel } = Collapse;
const { Option } = Select;

function getNestedObject(nestedObj, pathArr) {
      const arrayPath = pathArr.split('.');
      const objectKeyValue = arrayPath.reduce(
            (obj, key) =>
                  obj && obj[key] !== 'undefined' ? obj[key] : undefined,
            nestedObj
      );

      if (typeof objectKeyValue === 'object') {
            return JSON.stringify(objectKeyValue);
      } else {
            return objectKeyValue;
      }
}

function compare(a, b) {
      if (a.required < b.required) {
            return 1;
      }
      if (a.required > b.required) {
            return -1;
      }
      return 0;
}

export default function WebflowImporter({ activeAPIData }) {
      const [isModalVisible, setIsModalVisible] = useState(false);
      const [fetchingSchema, setFetchingSchema] = useState(false);
      const [apiResult, setApiResult] = useState({});
      const [tableResult, setTableResult] = useState([]);
      const [tableColumns, setTableColumns] = useState([]);
      const [tableColumns2, setTableColumns2] = useState([]);
      const [errorMessage, setErrorMessage] = useState('');
      const [errorType, setErrorType] = useState('info');
      const [loading, setLoading] = useState(false);
      const [colArray, setColArray] = useState([]);
      const [collectionSchemaArray, setCollectionSchemaArray] = useState([]);
      const [panelActiveKey, setPanelActiveKey] = useState('1');
      const [progressCount, setProgressCount] = useState(0);

      useEffect(() => {
            console.log('playground loaded');
      }, []);

      const children = [];
      for (let i = 0; i < tableColumns.length; i++) {
            children.push(
                  <Option key={tableColumns[i].id}>{tableColumns[i].id}</Option>
            );
      }

      function handleChange(value) {
            const colArray = String(value).split(',');
            setColArray(colArray);
            setTableColumns2(colArray);
      }

      const sendResultBack = (result) => {
            setApiResult(result);
            setTableColumns2([]);
            setTableColumns([]);
            setTableResult([]);
            if (isValidJson(result) && Array.isArray(result)) {
                  setTableColumns(recursiveSearch(result[0], ''));
                  setTableResult(result);
            } else {
                  setIsModalVisible(true);
            }
      };

      const onResultKeyInput = ({ resultKey }) => {
            setTableColumns(recursiveSearch(apiResult[resultKey][0], ''));
            setTableResult(apiResult[resultKey]);
            setIsModalVisible(false);
      };

      const seeTheRows = (values) => {
            setLoading(true);
            const { cloud_name, token } = activeAPIData;
            Object.keys(values).forEach((key) =>
                  values[key] === undefined ? delete values[key] : {}
            );
            const webflowSchemaKeys = Object.keys(values);
            const tableColumnsCam = webflowSchemaKeys.map((webflowKey) => {
                  return (
                        webflowKey !== undefined &&
                        webflowKey.split('.').join('-')
                  );
            });

            const gRowsData = [];
            for (let index = 0; index < tableResult.length; index++) {
                  const tableSingleRow = tableResult[index];
                  // console.log(tableSingleRow, tableColumns2);
                  const dummyArray = [];
                  for (let j = 0; j < tableColumnsCam.length; j++) {
                        const colKey = tableColumnsCam[j];
                        if (
                              colKey === '_archived' ||
                              colKey === '_draft' ||
                              colKey === 'featured'
                        ) {
                              dummyArray.push(
                                    values[colKey] === 'true' ? true : false
                              );
                        } else {
                              dummyArray.push(
                                    getNestedObject(
                                          tableSingleRow,
                                          values[colKey]
                                    )
                              );
                        }
                  }
                  gRowsData.push(dummyArray);
            }

            const jsonArrayObjs = [];

            _.each(gRowsData, function (value, key) {
                  jsonArrayObjs.push(
                        _.zipObject([...tableColumnsCam], [...value])
                  );
            });

            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            let numberOfRowsPushed = 0;
            function makePushCall() {
                  const dataToPush = jsonArrayObjs.splice(0, 1);

                  const objData = Object.keys(dataToPush[0]).forEach((key) =>
                        dataToPush[0][key] === undefined
                              ? delete dataToPush[0][key]
                              : {}
                  );

                  var config = {
                        method: 'post',
                        url: `${process.env.GATSBY_BASE_URL}/${cloud_name}/webflow/${token}`,
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        data: dataToPush[0],
                  };

                  axios(config)
                        .then(function (response) {
                              const { err, msg } = response.data;
                              if (err !== undefined) {
                                    setLoading(false);
                                    setErrorType('error');
                                    setErrorMessage(err);
                              } else {
                                    numberOfRowsPushed = numberOfRowsPushed + 1;
                                    setProgressCount(
                                          (numberOfRowsPushed * 100) /
                                                tableResult.length
                                    );
                                    if (jsonArrayObjs.length > 0) {
                                          makePushCall();
                                    } else {
                                          setLoading(false);
                                          setErrorType('success');
                                          setErrorMessage('Successfully Done!');
                                    }
                                    // makePushCall();
                              }
                        })
                        .catch(function (error) {
                              setLoading(false);
                              console.log('There was an error!', error);
                              setErrorType('error');
                        });
            }
            makePushCall();
      };

      const onChangePanel = (obj) => {
            if (obj[0] !== undefined) setPanelActiveKey(obj[1]);
      };

      const fetchCollectionSchema = () => {
            setFetchingSchema(true);
            const { api_access, collectionId } = activeAPIData.apis_info;

            var myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${api_access}`);
            myHeaders.append('accept-version', '1.0.0');

            var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow',
            };

            fetch(
                  `${process.env.GATSBY_BASE_URL}/webflow/getCollectionSchema?collectionId=${collectionId}`,
                  requestOptions
            )
                  .then((response) => response.text())
                  .then((result) => {
                        const { err } = JSON.parse(result);
                        if (err === undefined) {
                              let postData = JSON.parse(result);
                              var newFieldSchemaArray = postData.fields.filter(
                                    function (el) {
                                          return el.editable;
                                    }
                              );
                              setCollectionSchemaArray(
                                    newFieldSchemaArray.sort(compare)
                              );
                        } else {
                              message.info(err);
                        }
                        setFetchingSchema(false);
                  })
                  .catch((error) => {
                        message.info(error.msg);
                        setFetchingSchema(false);
                  });

            setPanelActiveKey('2');
      };

      return (
            <div className="connector-page">
                  <Collapse
                        activeKey={[panelActiveKey]}
                        onChange={onChangePanel}
                        // accordion
                        collapsible
                  >
                        <Panel header="Pull Data from API" key="1">
                              <Modal
                                    width={700}
                                    title="Result Key is required."
                                    visible={isModalVisible}
                                    footer={null}
                                    onCancel={() => setIsModalVisible(false)}
                              >
                                    <Form
                                          name="basic"
                                          initialValues={{}}
                                          onFinish={onResultKeyInput}
                                    >
                                          <Form.Item
                                                name="resultKey"
                                                rules={[{ required: true }]}
                                                extra="Is the results array nested under some key? Check below JSON response."
                                          >
                                                <Input placeholder="Enter result key" />
                                          </Form.Item>

                                          <Form.Item>
                                                <Button
                                                      type="primary"
                                                      htmlType="submit"
                                                >
                                                      Submit
                                                </Button>
                                          </Form.Item>
                                    </Form>

                                    <Editor
                                          width="800"
                                          height="40vh"
                                          language="json"
                                          theme="vs"
                                          value={JSON.stringify(
                                                apiResult,
                                                null,
                                                3
                                          )}
                                          options={{
                                                selectOnLineNumbers: true,
                                                minimap: {
                                                      enabled: false,
                                                },
                                                readOnly: true,
                                          }}
                                    />
                              </Modal>

                              <ConnectorAPIPlayground
                                    sendResultBack={sendResultBack}
                              />

                              {tableResult.length > 0 && (
                                    <Select
                                          mode="multiple"
                                          allowClear
                                          style={{ width: '100%' }}
                                          placeholder="Please select key values"
                                          defaultValue={[]}
                                          onChange={handleChange}
                                    >
                                          {children}
                                    </Select>
                              )}
                              {tableResult.length > 0 && (
                                    <p
                                          style={{
                                                padding: 0,
                                                marginTop: 10,
                                                fontSize: 13,
                                          }}
                                    >
                                          Total rows: {tableResult.length}
                                    </p>
                              )}
                              <div
                                    style={{
                                          overflowX: 'auto',
                                          maxHeight: 250,
                                          overflowY: 'scroll',
                                          marginTop: 10,
                                    }}
                              >
                                    <table className="table">
                                          <thead>
                                                <tr>
                                                      {tableColumns2.map(
                                                            (colKey, key) => (
                                                                  <th
                                                                        key={`header-${key}`}
                                                                  >
                                                                        <div>
                                                                              {
                                                                                    colKey
                                                                              }
                                                                        </div>
                                                                  </th>
                                                            )
                                                      )}
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {tableResult.map(
                                                      (tableBobyRow, key2) => (
                                                            <tr
                                                                  key={`${key2}-row`}
                                                            >
                                                                  {tableColumns2.map(
                                                                        (
                                                                              colKey,
                                                                              key3
                                                                        ) => (
                                                                              <td
                                                                                    key={`body-${key3}`}
                                                                              >
                                                                                    {getNestedObject(
                                                                                          tableBobyRow,
                                                                                          colKey
                                                                                    )}
                                                                              </td>
                                                                        )
                                                                  )}
                                                            </tr>
                                                      )
                                                )}
                                          </tbody>
                                    </table>
                              </div>
                              <br />
                              <Button
                                    disabled={tableColumns2.length === 0}
                                    onClick={fetchCollectionSchema}
                              >
                                    Next Step
                              </Button>
                        </Panel>
                        <Panel
                              header="Bind with webflow collection keys & push data"
                              key="2"
                        >
                              {fetchingSchema && 'Loading webflow schema...'}
                              <br />
                              <Form name="basic" onFinish={seeTheRows}>
                                    {collectionSchemaArray.map(
                                          (fieldKey, key1) => (
                                                <div key={key1}>
                                                      <Form.Item
                                                            className="two-column"
                                                            label={
                                                                  fieldKey.name
                                                            }
                                                            name={fieldKey.slug}
                                                            key={key1}
                                                            validateMessages=""
                                                            rules={[{}]}
                                                      >
                                                            <Select
                                                                  showSearch
                                                                  style={{
                                                                        width: 300,
                                                                  }}
                                                                  placeholder={`Select the binding key for ${fieldKey.name}`}
                                                                  optionFilterProp="children"
                                                                  filterOption={(
                                                                        input,
                                                                        option
                                                                  ) =>
                                                                        option.children
                                                                              .toLowerCase()
                                                                              .indexOf(
                                                                                    input.toLowerCase()
                                                                              ) >=
                                                                        0
                                                                  }
                                                            >
                                                                  {fieldKey.type ===
                                                                  'Bool' ? (
                                                                        <>
                                                                              <Option value="true">
                                                                                    True
                                                                              </Option>
                                                                              <Option value="false">
                                                                                    False
                                                                              </Option>
                                                                        </>
                                                                  ) : (
                                                                        colArray.map(
                                                                              (
                                                                                    colVal,
                                                                                    key
                                                                              ) => (
                                                                                    <Option
                                                                                          key={
                                                                                                key
                                                                                          }
                                                                                          value={
                                                                                                colVal
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                colVal
                                                                                          }
                                                                                    </Option>
                                                                              )
                                                                        )
                                                                  )}
                                                            </Select>
                                                      </Form.Item>
                                                      <br />
                                                      {/* <p className="extra">
                                    Data type: {fieldKey.type} - {fieldKey.slug}
                                </p> */}
                                                </div>
                                          )
                                    )}
                                    <Button
                                          type="primary"
                                          htmlType="submit"
                                          loading={loading}
                                    >
                                          {loading
                                                ? 'Pushing data'
                                                : 'Push Data'}
                                    </Button>
                              </Form>

                              <br />
                              <Progress
                                    percent={progressCount.toFixed(2)}
                                    steps={10}
                                    strokeColor="#52c41a"
                              />

                              <br />
                              {errorMessage !== '' && (
                                    <Alert
                                          message={errorMessage}
                                          type={errorType}
                                    />
                              )}
                              {/* {errorType === 'success' && (
                        <a href={airtableLink} target="_blank">
                            View Airtable Base
                        </a>
                    )} */}
                        </Panel>
                  </Collapse>
            </div>
      );
}
