exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/workbooks`] = {
        get: {
            tags: ['Zoho Sheet'],
            summary: 'List all workbooks',
            description: 'List all workbooks from your zoho account',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/worksheets`] = {
        get: {
            tags: ['Zoho Sheet'],
            summary: 'List all worksheets',
            description: 'List all worksheets of a workbook',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Zoho Sheet'],
            summary: 'Create worksheet',
            description: 'Create worksheet into workbooks(spreadsheet)',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'workSheetName',
                    in: 'query',
                    description: 'Name of the new worksheet',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/sheetData`] = {
        get: {
            tags: ['Zoho Sheet'],
            summary: 'Fetch records',
            description: 'Fetch records of a worksheet',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'workSheetName',
                    in: 'query',
                    description: 'Name of the new worksheet',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'header_row',
                    in: 'query',
                    description:
                        'By default, first row of the worksheet is considered as header row. This can be used if tabular data starts from any row other than the first row.',
                    type: 'string',
                },
                {
                    name: 'criteria',
                    in: 'query',
                    description:
                        'Can be used to filter records. <b>example</b>: "Name"="Jack", "email"="hello@example.com", "age">14, etc',
                    type: 'string',
                },
                {
                    name: 'column_names',
                    in: 'query',
                    description: 'Name of the new worksheet',
                    type: 'string',
                },
                {
                    name: 'render_option',
                    in: 'query',
                    description:
                        'It defines how the value should be rendered. Possible options are <b>formatted</b>, <b>unformatted</b>, and <b>formula</b>.',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination for next page',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'It denotes the number of records.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        post: {
            tags: ['Zoho Sheet'],
            summary: 'Add records',
            description: 'Add records to worksheet',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'workSheetName',
                    in: 'query',
                    description: 'Name of the new worksheet',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'body',
                    in: 'body',
                    description:
                        'JSON Array. Example : [{"Name":"Joe","Region":"South","Units":284},{"Name":"Beth","Region":"East","Units":290}]. "Name", "Region", and "Units" are the table headers.',
                    required: true,
                    sampleData: [
                        { Name: 'Joe', Region: 'South', Units: 284 },
                        { Name: 'Beth', Region: 'East', Units: 290 },
                    ],
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        put: {
            tags: ['Zoho Sheet'],
            summary: 'Update records',
            description: 'Update records in worksheet',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'workSheetName',
                    in: 'query',
                    description: 'Name of the new worksheet',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'criteria',
                    in: 'query',
                    description:
                        'If criteria is not set all available rows will get updated. Mention the criteria as described as: <b>example</b>: "Name"="Jack", "email"="hello@example.com", "age">14, etc',
                    type: 'string',
                },
                {
                    name: 'header_row',
                    in: 'query',
                    description:
                        'By default, first row of the worksheet is considered as header row. This can be used if tabular data starts from any row other than the first row.',
                    type: 'string',
                },
                {
                    name: 'first_match_only',
                    in: 'query',
                    description:
                        'If true and if there are multiple records on the specified criteria, records will be updated for first match alone. Otherwise, all the matched records will be updated.',
                    type: 'string',
                },
                {
                    name: 'is_case_sensitive',
                    in: 'query',
                    description:
                        'By default it is true. Can be set as false for case incensitive search.',
                    type: 'string',
                },
                {
                    name: 'body',
                    in: 'body',
                    description:
                        'The JSON data that needs to be updated. Example:{"Month":"May","Amount":50}',
                    required: true,
                    sampleData: { Month: 'May', Amount: 50 },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
