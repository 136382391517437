import { mapToSelectOptions } from '../helpers';

export const degreeOfRevision = mapToSelectOptions([
  'Exactly the same text as mine, but corrected',
  'Minor Revision',
  'Substantial Revision',
  'Content revision',
]);

export const typeOfEdit = mapToSelectOptions([
  'Grammar and spelling',
  'Improve style and readability',
  'Enhance vocabulary',
  'Enhance clarity and consistency',
  'Rewrite to native writer level',
]);

export const giveFeedbackAbout = mapToSelectOptions([
  'Grammar',
  'Style',
  'Grammar and Style',
  'Improvement suggestions',
  'Comprehension level needed',
]);

export const changeStyleTo = mapToSelectOptions([
  'Formal',
  'Informal',
  'Casual',
  'Emotional',
  'Creative',
  'Persuasive',
  'Business',
  'Technical',
  'Legal',
  'Medical',
  'Academic',
]);

export const changeToneTo = mapToSelectOptions([
  'Funny',
  'Serious',
  'Friendly',
  'Professional',
  'Empathetic',
  'Confident',
  'Enthusiastic',
  'Assertive',
  'Encouraging',
  'Excited',
  'Witty',
  'Sympathetic',
  'Analytical',
  'Authoritative',
  'Romantic',
]);

export const rewriteInTheVoiceOf = mapToSelectOptions([
  'Socrate',
  'Shakespeare',
  'Albert Einstein',
  'Add other famous personas here',
]);

export const changeLanguage = mapToSelectOptions([
  'Spanish',
  'French',
  'German',
  'Italian',
  'Arabic',
  'Japanese',
  'Chinese',
  'US English',
  'UK English',
  'Australian English',
  'New Zealand English',
  'Irish',
  'Scottish',
  'Swiss German',
  'Berliner German',
  'Kölsch German',
  'Add your language',
]);

export const changeToSlang = mapToSelectOptions([
  'Bro Talk',
  'Sassy girl Talk',
  'Snoop dogg Talk',
  '40s Gangster Talk',
  'Use your imagination and add :)',
]);

export const changeReaderComprehensionLevelTo = mapToSelectOptions([
  '5 years old',
  '10 years old',
  '25 years old',
  '85 years old',
  'IQ 69',
  'IQ 115',
  'IQ 150',
  'beginner, assume no prior knowledge',
  'intermediate, assume some prior knowledge',
  'advanced, assume extensive prior knowledge',
  'PhD Graduate',
  'Triple PhD galactic brain',
]);

export const changeLengthTo = mapToSelectOptions([
  '280 characters',
  'Short, simple and to the point',
  '1 Paragraph',
  '3 Paragraphs',
  '100 Words',
  '300 Words',
  '500 Words',
  '1000 Words',
]);

export const outputAs = mapToSelectOptions([
  'Numbered list',
  'Bulleted list',
  'Bulleted list with nested items',
  'Task List',
  'Same structure as input',
  'Only Markdown table',
  'Only Markdown table with Column 1: Number',
  'Blockquote',
  'Code block',
  'JSON',
  'CSV',
  'YAML',
  'XML',
  'SQL',
]);

export const formatting = mapToSelectOptions([
  'Bold the important words',
  'Only the text. No comments before and after.',
  'Highlight key words and phrases',
]);
