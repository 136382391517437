exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Uptime Robot'],
            summary: 'Get Monitors',
            description:
                'Get the status about your monitors by passing your monitors ID. You can also pass mutiple IDs like this 15830-32696-83920',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'monitors',
                    in: 'query',
                    description:
                        '(if not used, will return all monitors in an account. Else, it is possible to define any number of monitors with their IDs like: monitors=15830-32696-83920)',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/addMonitor`] = {
        post: {
            tags: ['Uptime Robot'],
            summary: 'Add new Monitor',
            description: 'Add new website to uptim robot monitor with POST request.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'friendlyName',
                    in: 'query',
                    description: 'Give a name to this monitor',
                    required: true,
                },
                {
                    name: 'url',
                    in: 'query',
                    description: 'URL of the website',
                    required: true,
                },
                {
                    name: 'type',
                    in: 'query',
                    description: 'Monitor Type. 1 - HTTP(s), 2 - Keyword, 3 - Ping, 4 - Port, ',
                },
                {
                    name: 'interval',
                    in: 'query',
                    description: 'Monitor interval. Ex: 4',
                },
                {
                    name: 'keyword_type',
                    in: 'query',
                    description: 'required for keyword monitoring. 1 - exists or 2 - not exists',
                },
                {
                    name: 'keyword_value',
                    in: 'query',
                    description: 'required for keyword monitoring',
                },
                {
                    name: 'alert_contacts',
                    in: 'query',
                    description:
                        'alert_contacts=457_0_0-373_5_0-8956_2_3 where alert_contact>ids are seperated with - and threshold + recurrence are seperated with _. For ex: alert_contacts=457_5_0 refers to 457 being the alert_contact>id, 5 being the threshold and 0 being the recurrence. Use `getAlertContacts` endpoint to get ids',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/getAlertContacts`] = {
        get: {
            tags: ['Uptime Robot'],
            summary: 'Get Alert Contacts',
            description: 'The list of alert contacts can be called with this method.',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
