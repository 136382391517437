exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/getCollections`] = {
        get: {
            tags: ['FaunaDB'],
            summary: 'Get Collections',
            description: 'List all collection for a FaunaDB database',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'size',
                    in: 'query',
                    description: 'limit the object to return',
                },
                {
                    name: 'before',
                    in: 'query',
                    description: 'The cursor for the previous page, exclusive.',
                },
                {
                    name: 'after',
                    in: 'query',
                    description: 'The cursor for the next page, inclusive.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/getDocuments`] = {
        get: {
            tags: ['FaunaDB'],
            summary: 'List all Documents',
            description: 'Get list of documents into a collection',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'collectionID',
                    in: 'query',
                    required: true,
                    description: 'Collection id',
                },
                {
                    name: 'size',
                    in: 'query',
                    description: 'limit the object to return',
                },
                {
                    name: 'before',
                    in: 'query',
                    description: 'The cursor for the previous page, exclusive.',
                },
                {
                    name: 'after',
                    in: 'query',
                    description: 'The cursor for the next page, inclusive.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/document`] = {
        get: {
            tags: ['FaunaDB'],
            summary: 'Single Document',
            description: 'Get 144 countries symbols with metadata',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'collectionID',
                    in: 'query',
                    required: true,
                    description: 'Collection id',
                },
                {
                    name: 'documentID',
                    in: 'query',
                    required: true,
                    description: 'document id',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
