import { mapToSelectOptions } from '../helpers/index';

export const tutorPersona = mapToSelectOptions([
  'Snoop Dogg',
  'Richard Feynman',
  'Naval',
  'Sal Khan',
  'Steve Jobs',
  'Yoda',
  'Albert Einstein',
  'Confucius',
  'Maria Montessori',
  'Bruce Lee',
  'Bender the Robot',
  'Add your favourite teacher',
  'Or any person you enjoy listening to',
]);

export const styleOfTeaching = mapToSelectOptions([
  'Fun, engaging and memorable',
  'Informal',
  'Casual',
  'Emotional',
  'Creative',
  'Persuasive',
  'Business',
  'Technical',
  'Academic',
]);

export const tone = mapToSelectOptions([
  'Funny',
  'Serious',
  'Friendly',
  'Professional',
  'Empathetic',
  'Confident',
  'Enthusiastic',
  'Assertive',
  'Encouraging',
  'Excited',
  'Witty',
  'Sympathetic',
  'Analytical',
  'Authoritative',
  'Romantic',
]);

export const language = mapToSelectOptions([
  'Spanish',
  'French',
  'German',
  'Italian',
  'Arabic',
  'Japanese',
  'Chinese',
  'US English',
  'UK English',
  'Australian English',
  'New Zealand English',
  'Irish',
  'Scottish',
  'Swiss German',
  'Berliner German',
  'Kölsch German',
  'Add your language',
]);

export const studentLevel = mapToSelectOptions([
  '5 years old',
  '10 years old',
  '25 years old',
  '85 years old',
  'IQ 69',
  'IQ 115',
  'IQ 150',
  'beginner, assume no prior knowledge',
  'intermediate, assume some prior knowledge',
  'advanced, assume extensive prior knowledge',
  'PhD Graduate',
  'Triple PhD galactic brain',
]);
