import React, { useEffect, useState } from 'react';

import {
      Select,
      Modal,
      Form,
      Button,
      Input,
      Collapse,
      Alert,
      Progress,
} from 'antd';
import ConnectorAPIPlayground from '../connectorPLayground';

import { isValidJson, recursiveSearch } from '../../../config/custom/functions';
const _ = require('lodash');
const { Panel } = Collapse;
const { Option } = Select;

function getNestedObject(nestedObj, pathArr) {
      const arrayPath = pathArr.split('.');
      const objectKeyValue = arrayPath.reduce(
            (obj, key) =>
                  obj && obj[key] !== 'undefined' ? obj[key] : undefined,
            nestedObj
      );

      if (typeof objectKeyValue === 'object') {
            return JSON.stringify(objectKeyValue);
      } else {
            return objectKeyValue;
      }
}

const RenderReactJSON = (props) => {
      if (typeof window !== 'undefined') {
            const ReactJson = require('react-json-view').default;
            return <ReactJson {...props} />;
      }
      return null;
};

export default function AirtableImporter({ activeAPIData }) {
      const [isModalVisible, setIsModalVisible] = useState(false);
      const [apiResult, setApiResult] = useState({});
      const [tableResult, setTableResult] = useState([]);
      const [tableColumns, setTableColumns] = useState([]);
      const [tableColumns2, setTableColumns2] = useState([]);
      const [mapKeys, setMapKeys] = useState({});
      const [errorMessage, setErrorMessage] = useState('');
      const [errorType, setErrorType] = useState('info');
      const [loading, setLoading] = useState(false);
      const [airtableLink, setAirtableLink] = useState('');
      const [panelActiveKey, setPanelActiveKey] = useState('1');
      const [progressCount, setProgressCount] = useState(0);

      useEffect(() => {
            console.log('playground loaded');
      }, []);

      const children = [];
      for (let i = 0; i < tableColumns.length; i++) {
            children.push(
                  <Option key={tableColumns[i].id}>{tableColumns[i].id}</Option>
            );
      }

      function handleChange(value) {
            const colArray = String(value).split(',');
            setMapKeys(_.zipObject([...colArray], [...colArray]));
            setTableColumns2(colArray);
      }

      const sendResultBack = (result) => {
            setApiResult(result);
            setTableColumns2([]);
            setTableColumns([]);
            setTableResult([]);
            if (isValidJson(result) && Array.isArray(result)) {
                  setTableColumns(recursiveSearch(result[0], ''));
                  setTableResult(result);
            } else {
                  setIsModalVisible(true);
            }
      };

      const onResultKeyInput = ({ resultKey }) => {
            setTableColumns(recursiveSearch(apiResult[resultKey][0], ''));
            setTableResult(apiResult[resultKey]);
            setIsModalVisible(false);
      };

      const seeTheRows = ({ tableName }) => {
            setLoading(true);
            const { cloud_name, token, apis_info } = activeAPIData;
            const finalKeyArray = Object.values(mapKeys);
            const tableColumnsCam = finalKeyArray.map((sweetItem) => {
                  return sweetItem.split('.').join('-');
            });

            const gRowsData = [];
            for (let index = 0; index < tableResult.length; index++) {
                  const tableSingleRow = tableResult[index];
                  const dummyArray = [];
                  for (
                        let index2 = 0;
                        index2 < tableColumns2.length;
                        index2++
                  ) {
                        const colKey = tableColumns2[index2];
                        dummyArray.push(
                              getNestedObject(tableSingleRow, colKey)
                        );
                  }
                  gRowsData.push(dummyArray);
            }

            const jsonArrayObjs = [];

            _.each(gRowsData, function (value, key) {
                  jsonArrayObjs.push(
                        _.zipObject([...tableColumnsCam], [...value])
                  );
            });

            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            let numberOfRowsPushed = 0;
            function makePushCall() {
                  const dataToPush = jsonArrayObjs.splice(0, 50);
                  var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: JSON.stringify(dataToPush),
                        redirect: 'follow',
                  };
                  fetch(
                        `${process.env.GATSBY_BASE_URL}/${cloud_name}/airtable/${token}?tableName=${tableName}`,
                        requestOptions
                  )
                        .then(async (response) => {
                              const data = await response.json();

                              // check for error response
                              if (!response.ok) {
                                    // get error message from body or default to response statusText
                                    const error =
                                          (data && data.info) ||
                                          response.statusText;
                                    setErrorMessage(error);
                                    setLoading(false);
                                    setErrorType('error');
                              } else {
                                    numberOfRowsPushed =
                                          numberOfRowsPushed + 50;
                                    setProgressCount(
                                          (numberOfRowsPushed * 100) /
                                                tableResult.length
                                    );
                                    setAirtableLink(
                                          `https://airtable.com/${apis_info.base_id}`
                                    );
                                    if (jsonArrayObjs.length > 0) {
                                          makePushCall();
                                    } else {
                                          setLoading(false);
                                          setErrorType('success');
                                          setErrorMessage('Successfully Done!');
                                    }
                              }
                        })
                        .catch((error) => {
                              setLoading(false);
                              console.log('There was an error!', error);
                              setErrorType('error');
                        });
            }
            makePushCall();
      };

      const onChangePanel = (obj) => {
            if (obj[0] !== undefined) setPanelActiveKey(obj[1]);
      };

      return (
            <div className="connector-page">
                  <Collapse
                        activeKey={[panelActiveKey]}
                        onChange={onChangePanel}
                        // accordion
                        collapsible
                  >
                        <Panel header="Pull Data from API" key="1">
                              <Modal
                                    width={700}
                                    title="Result Key is required."
                                    visible={isModalVisible}
                                    footer={null}
                                    onCancel={() => setIsModalVisible(false)}
                              >
                                    <Form
                                          name="basic"
                                          initialValues={{}}
                                          onFinish={onResultKeyInput}
                                    >
                                          <Form.Item
                                                name="resultKey"
                                                rules={[{ required: true }]}
                                                extra="Is the results array nested under some key? Check below JSON response."
                                          >
                                                <Input placeholder="Enter result key" />
                                          </Form.Item>

                                          <Form.Item>
                                                <Button
                                                      type="primary"
                                                      htmlType="submit"
                                                >
                                                      Submit
                                                </Button>
                                          </Form.Item>
                                    </Form>
                                    <RenderReactJSON
                                          style={{
                                                fontSize: 13,
                                                borderRadius: 3,
                                          }}
                                          name={false}
                                          theme="ocean"
                                          enableClipboard={false}
                                          displayDataTypes={false}
                                          src={apiResult}
                                          displayObjectSize={false}
                                    />
                              </Modal>

                              <ConnectorAPIPlayground
                                    sendResultBack={sendResultBack}
                              />

                              {tableResult.length > 0 && (
                                    <Select
                                          mode="multiple"
                                          allowClear
                                          style={{ width: '100%' }}
                                          placeholder="Please select key values"
                                          defaultValue={[]}
                                          onChange={handleChange}
                                    >
                                          {children}
                                    </Select>
                              )}
                              {tableResult.length > 0 && (
                                    <p
                                          style={{
                                                padding: 0,
                                                marginTop: 10,
                                                fontSize: 13,
                                          }}
                                    >
                                          Total rows: {tableResult.length}
                                    </p>
                              )}
                              <div
                                    style={{
                                          overflowX: 'auto',
                                          maxHeight: 250,
                                          overflowY: 'scroll',
                                          marginTop: 10,
                                    }}
                              >
                                    <table className="table">
                                          <thead>
                                                <tr>
                                                      {tableColumns2.map(
                                                            (colKey, key) => (
                                                                  <th
                                                                        key={`header-${key}`}
                                                                  >
                                                                        <div>
                                                                              {
                                                                                    colKey
                                                                              }
                                                                        </div>
                                                                  </th>
                                                            )
                                                      )}
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {tableResult.map(
                                                      (tableBobyRow, key2) => (
                                                            <tr
                                                                  key={`${key2}-row`}
                                                            >
                                                                  {tableColumns2.map(
                                                                        (
                                                                              colKey,
                                                                              key3
                                                                        ) => (
                                                                              <td
                                                                                    key={`body-${key3}`}
                                                                              >
                                                                                    {getNestedObject(
                                                                                          tableBobyRow,
                                                                                          colKey
                                                                                    )}
                                                                              </td>
                                                                        )
                                                                  )}
                                                            </tr>
                                                      )
                                                )}
                                          </tbody>
                                    </table>
                              </div>
                              <br />
                              <Button
                                    disabled={tableColumns2.length === 0}
                                    onClick={() => setPanelActiveKey('2')}
                              >
                                    Next Step
                              </Button>
                        </Panel>
                        <Panel header="Input Table Name & push data" key="2">
                              <RenderReactJSON
                                    style={{
                                          fontSize: 13,
                                          borderRadius: 3,
                                    }}
                                    name={false}
                                    theme="ocean"
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    src={mapKeys}
                                    displayObjectSize={false}
                                    onEdit={(edit) => {
                                          setMapKeys(edit.updated_src);
                                    }}
                              />
                              <Form
                                    layout="vertical"
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={seeTheRows}
                              >
                                    <Form.Item
                                          label="tableName"
                                          name="tableName"
                                          rules={[
                                                {
                                                      required: true,
                                                      message: 'Please input tableName of your Airtable Base.',
                                                },
                                          ]}
                                    >
                                          <Input />
                                    </Form.Item>

                                    <Form.Item>
                                          <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={loading}
                                          >
                                                {loading
                                                      ? 'Pushing data'
                                                      : 'Push Data'}
                                          </Button>
                                    </Form.Item>
                              </Form>
                              <Progress
                                    percent={progressCount.toFixed(2)}
                                    steps={10}
                                    strokeColor="#52c41a"
                              />

                              {errorMessage !== '' && (
                                    <Alert
                                          message={errorMessage}
                                          type={errorType}
                                    />
                              )}
                              {errorType === 'success' && (
                                    <a href={airtableLink} target="_blank">
                                          View Airtable Base
                                    </a>
                              )}
                        </Panel>
                  </Collapse>
            </div>
      );
}
