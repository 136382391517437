import React, {useState, useEffect, useContext} from 'react';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { IoMdCopy } from 'react-icons/io';
import moment from 'moment';
import {
    RiDeleteBinLine,   
} from 'react-icons/ri';
import { Space, Table, message, Popconfirm } from 'antd';
import axios from 'axios';

const NoCodeFormData = ({apiObj}) =>{

    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId } = state;
    const [loading, setLoading] = useState(true);
    const [nocodeFormsData, setnocodeFormsData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { cloud_name, app_id, token } = apiObj;

   const fetchNocodeForm = async()=> {
        setLoading(true);
        const formsData = await axios.get(
              `${process.env.GATSBY_BASE_URL}/${cloud_name}/nForms/${token}/search?time=${new Date().getTime()}`,
              { headers: { 'Content-Type': 'application/json' } }
        );
      if (formsData.data && formsData.data.length >0 ) {
          const data = [];
          const dataColumns = [];
          formsData.data.map((fData, index) => {
              data[index] = {};
              fData.fields.map((field, index1)=>{
                data[index][field.field] = field.value
              })
              data[index]['key'] = index+1;
              data[index]['id'] = fData._id;
          })
          formsData.data[0].fields.map((col, index)=>{
              dataColumns.push({title: col.field, key:col.field, dataIndex: col.field});
          })
          dataColumns.push({
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <Space size="middle">
                        <Popconfirm
                            title="Are you sure to delete this form data?"
                            onConfirm={()=> deleteData(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >   
                            <a href="#" style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    color: '#ff0000',
                                }}><RiDeleteBinLine /></a>
                        </Popconfirm>
                      </Space>
                    )})
        
          setColumns(dataColumns);
          setnocodeFormsData(data);
      } else {
          setnocodeFormsData([]);
      }
      setLoading(false);
  }

  const deleteData = async(id) =>{
      axios.delete(`${process.env.GATSBY_BASE_URL}/${cloud_name}/nForms/${token}/data`,
      { params: { id: id} },
      {
        headers: {
              'Content-Type':
                    'application/json',
        },
    }).then((res)=> {
        message.info(`Form data is deleted`);
        fetchNocodeForm();
    }).catch((error)=>{
        message.info("Something went wrong!")
    })
  }

  const confirm = (e) => {
    message.success('Click on Yes');
  };

  const triggerCopy = (copyURL) => {
    const textField = document.createElement('textarea');
    textField.innerText = copyURL;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.success('URL copied');
};

    
   
      useEffect(() => {
        fetchNocodeForm()
      }, []);  

    return (
        <div>
            <Table dataSource={nocodeFormsData} columns={columns} />
        </div>
    )
}
export default NoCodeFormData;