exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/sendSMS`] = {
        post: {
            tags: ['Twilio'],
            summary: 'Send text SMS',
            description:
                'Using Twilio API send text SMS to an number from a Number',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description:
                        'Pass an body object, email is required. from is the trial number from your twilio account',
                    required: true,
                    sampleData: {
                        body: 'Hello from Twilio',
                        to: '+12345678901', // Text this number
                        from: '+12345678901', // From a valid Twilio number
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
