exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}/products`] = {
    get: {
      tags: ['Gumroad'],
      summary: 'List Products',
      description: 'Returns all products from your gumroad account.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'id',
          in: 'query',
          description: 'Product ID, if you want single product data',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/sales`] = {
    get: {
      tags: ['Gumroad'],
      summary: 'Sales',
      description: 'Get sales data.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'after',
          in: 'query',
          description: '(date in form YYYY-MM-DD) - Only return sales after this date',
          type: 'string',
        },
        {
          name: 'before',
          in: 'query',
          description: '(date in form YYYY-MM-DD) - Only return sales before this date',
          type: 'string',
        },
        {
          name: 'product_id',
          in: 'query',
          description: 'Filter sales by this product',
          type: 'string',
        },
        {
          name: 'email',
          in: 'query',
          description: 'Filter sales by this email',
          type: 'string',
        },
        {
          name: 'page',
          in: 'query',
          description: 'Return this page of results',
          type: 'string',
        },
        {
          name: 'id',
          in: 'query',
          description: 'Sales ID, if you want single sales data',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/subscribers`] = {
    get: {
      tags: ['Gumroad'],
      summary: 'Subscribers',
      description: 'List of subscribers of a product',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_id',
          in: 'query',
          description: 'Product ID, of which you want subscribers',
          type: 'string',
          required: true,
        },
        {
          name: 'email',
          in: 'query',
          description: 'Filter subscribers by this email',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/singleSubscriber`] = {
    get: {
      tags: ['Gumroad'],
      summary: 'Single Subscriber',
      description: 'Get one subscriber data with id.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'id',
          in: 'query',
          description: 'Subscriber ID',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/customfields`] = {
    get: {
      tags: ['Gumroad'],
      summary: 'Get all custom fields',
      description: 'Get all custom fields by product ID',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_id',
          in: 'query',
          description: 'Product ID',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    post: {
      tags: ['Gumroad'],
      summary: 'Add new custom field',
      description: 'Add new custom field in a product',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_id',
          in: 'query',
          description: 'Product ID',
          type: 'string',
          required: true,
        },
        {
          name: 'custom_field_name',
          in: 'query',
          description: 'Name of custom field',
          type: 'string',
          required: true,
        },
        {
          name: 'custom_field_req',
          in: 'query',
          description:
            'Whether custom fields is required. Accepted values are: required and optional. Default is optional',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    put: {
      tags: ['Gumroad'],
      summary: 'Update a custom field',
      description: 'Update a custom field in a product',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_id',
          in: 'query',
          description: 'Product ID',
          type: 'string',
          required: true,
        },
        {
          name: 'field_name',
          in: 'query',
          description: 'Name of custom field to be updated',
          type: 'string',
          required: true,
        },
        {
          name: 'custom_field_name',
          in: 'query',
          description: 'Changed Name',
          type: 'string',
          required: true,
        },
        {
          name: 'custom_field_req',
          in: 'query',
          description:
            'Whether custom fields is required. Accepted values are: required and optional. Default is optional',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    delete: {
      tags: ['Gumroad'],
      summary: 'Delete a custom field',
      description: 'Delete a custom field in a product',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_id',
          in: 'query',
          description: 'Product ID',
          type: 'string',
          required: true,
        },
        {
          name: 'field_name',
          in: 'query',
          description: 'Name of custom field to be deleted',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/licenses/verify`] = {
    post: {
      tags: ['Gumroad'],
      summary: 'Verify license',
      description: 'Verify a license with product_permalink and license_key.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_permalink',
          in: 'query',
          description:
            'the unique permalink of the product, if your product URL is "https://gumroad.com/l/QMGY" your product_permalink would be "QMGY',
          type: 'string',
          required: true,
        },
        {
          name: 'license_key',
          in: 'query',
          description: 'the license key provided by your customer',
          type: 'string',
          required: true,
        },
        {
          name: 'increment_uses_count',
          in: 'query',
          description: '"true"/"false", optional, default: "true"',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/licenses/enable`] = {
    put: {
      tags: ['Gumroad'],
      summary: 'Enable license',
      description: 'Enable a license with product_permalink and license_key.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_permalink',
          in: 'query',
          description:
            'the unique permalink of the product, if your product URL is "https://gumroad.com/l/QMGY" your product_permalink would be "QMGY',
          type: 'string',
          required: true,
        },
        {
          name: 'license_key',
          in: 'query',
          description: 'the license key provided by your customer',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/licenses/disable`] = {
    put: {
      tags: ['Gumroad'],
      summary: 'Disable license',
      description: 'Disable a license with product_permalink and license_key.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'product_permalink',
          in: 'query',
          description:
            'the unique permalink of the product, if your product URL is "https://gumroad.com/l/QMGY" your product_permalink would be "QMGY',
          type: 'string',
          required: true,
        },
        {
          name: 'license_key',
          in: 'query',
          description: 'the license key provided by your customer',
          type: 'string',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  return schemaObj;
};
