export const mapToSelectOptions = (arr) => {
  return arr.map((item) => {
    return { label: item, value: item };
  });
};
export const showTextArea = (value, key, keyArr, formMap) => {
  const keysVal = keyArr.map((keysVal) => keysVal.value);
  if (formMap[key] === null) {
    return false;
  }
  return formMap[key] === value || !keysVal.includes(formMap[key]);
};
