import { mapToSelectOptions } from '../helpers/index';
export const typeOfCard = mapToSelectOptions([
    "Basic",
    "Cloze",
    "Multiple Choice",
    "Reversed"
]);

export const numberOfCardsToCreate = mapToSelectOptions([
    "1",
    "3",
    "5",
    "10",
    "25"
]);

export const sourceOfInformation = mapToSelectOptions([
    "Only from my text",
    "You choose based on the Topic in My Text"
]);

export const outputAs = mapToSelectOptions([
    "Plain text",
    "CSV",
    "XML",
    "HTML",
    "TSV"
]);