exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}/contacts`] = {
            get: {
                  tags: ['HubSpot'],
                  summary: 'Contact List',
                  description:
                        'Returns all contact list as JSON that have been created in your HubSpot account.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'count',
                              in: 'query',
                              description:
                                    'This parameter lets you specify the amount of contacts to return in your API call.',
                              type: 'string',
                        },
                        {
                              name: 'vidOffset',
                              in: 'query',
                              description:
                                    'Used to page through the contacts. Every call to this endpoint will return a vid-offset value.',
                              type: 'string',
                        },
                        {
                              name: 'propertyMode',
                              in: 'query',
                              description:
                                    'One of “value_only” or “value_and_history” to specify if the current value for a property should be fetched, or the value and all the historical values for that property. Default is “value_only”.',
                              type: 'string',
                        },
                        {
                              name: 'formSubmissionMode',
                              in: 'query',
                              description:
                                    'One of “all”, “none”, “newest”, “oldest” to specify which form submissions should be fetched. Default is “newest”.',
                              type: 'string',
                        },
                        {
                              name: 'showListMemberships',
                              in: 'query',
                              description:
                                    'Boolean "true" or "false" to indicate whether current list memberships should be fetched for the contact. Default is false.',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            post: {
                  tags: ['HubSpot'],
                  summary: 'Create Contact',
                  description: 'Create a new contact in your HubSpot account',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description:
                                    'Pass an array of row array into body',
                              required: true,
                              sampleData: {
                                    properties: [
                                          {
                                                property: 'email',
                                                value: 'testingapis@hubspot.com',
                                          },
                                          {
                                                property: 'firstname',
                                                value: 'Adrian',
                                          },
                                          {
                                                property: 'lastname',
                                                value: 'Mott',
                                          },
                                          {
                                                property: 'website',
                                                value: 'http://hubspot.com',
                                          },
                                          {
                                                property: 'company',
                                                value: 'HubSpot',
                                          },
                                          {
                                                property: 'phone',
                                                value: '555-122-2323',
                                          },
                                          {
                                                property: 'address',
                                                value: '25 First Street',
                                          },
                                          {
                                                property: 'city',
                                                value: 'Cambridge',
                                          },
                                          {
                                                property: 'state',
                                                value: 'MA',
                                          },
                                          {
                                                property: 'zip',
                                                value: '02139',
                                          },
                                    ],
                              },
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            put: {
                  tags: ['HubSpot'],
                  summary: 'Update an Contact',
                  description:
                        'Update an existing contact in HubSpot. It only updates the properties specified in the request.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'email',
                              in: 'query',
                              description: 'The email address of the contact.',
                              type: 'string',
                              required: true,
                        },
                        {
                              name: 'body',
                              in: 'body',
                              description:
                                    'Pass an array of row array into body',
                              required: true,
                              sampleData: {
                                    properties: [
                                          {
                                                property: 'email',
                                                value: 'new-email@hubspot.com',
                                          },
                                          {
                                                property: 'firstname',
                                                value: 'Adrian',
                                          },
                                          {
                                                property: 'lastname',
                                                value: 'Mott',
                                          },
                                          {
                                                property: 'website',
                                                value: 'http://hubspot.com',
                                          },
                                          {
                                                property: 'company',
                                                value: 'HubSpot',
                                          },
                                          {
                                                property: 'phone',
                                                value: '555-122-2323',
                                          },
                                          {
                                                property: 'address',
                                                value: '25 First Street',
                                          },
                                          {
                                                property: 'city',
                                                value: 'Cambridge',
                                          },
                                          {
                                                property: 'state',
                                                value: 'MA',
                                          },
                                          {
                                                property: 'zip',
                                                value: '02139',
                                          },
                                    ],
                              },
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            delete: {
                  tags: ['HubSpot'],
                  summary: 'Delete a Contact',
                  description:
                        'Delete an existing contact from a particular HubSpot account.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'contactId',
                              in: 'query',
                              description:
                                    "You must pass the Contact's ID that",
                              type: 'string',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/forms`] = {
            get: {
                  tags: ['HubSpot'],
                  summary: 'Forms List',
                  description:
                        'Return all forms that have been created in an account. ',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The number of records to return. Has no default; if ommitted, all forms are returned.',
                              type: 'string',
                        },
                        {
                              name: 'offset',
                              in: 'query',
                              description:
                                    "Used to page through the results. If there are more records in your account than the limit= parameter, you will need to include an offset equal to the number of forms you've already requested.",
                              type: 'string',
                        },
                        {
                              name: 'formTypes',
                              in: 'query',
                              description:
                                    'By default non-marketing forms are filtered out of this endpoint. To request all forms, use this parameter with the value of ALL (case sensitive).',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/formSubmission`] = {
            get: {
                  tags: ['HubSpot'],
                  summary: 'Form Submission',
                  description:
                        'Get the submissions for the specified form. This will include the fields in the submissions, the time of the form submission, and the page URL that the form was submitted on. Submissions will be returned in reverse-chronological order.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'guid',
                              in: 'query',
                              description:
                                    'The unique guid for the form that you want to get the submissions from.',
                              type: 'string',
                              required: true,
                        },
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The number of results to include in the response. Defaults to 20, supports a maximum of 50.',
                              type: 'string',
                        },
                        {
                              name: 'after',
                              in: 'query',
                              description:
                                    'Used to get the next page of results. Each response returns an after value, which is used with this parameter in the next request to get the next page of results.',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/deals`] = {
            get: {
                  tags: ['HubSpot'],
                  summary: 'Deals',
                  description:
                        'All deal data from HubSpot, including any associated object data. It returns a paginated set of deals.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The number of records to return. Defaults to 100, has a maximum value of 250.',
                              type: 'string',
                        },
                        {
                              name: 'offset',
                              in: 'query',
                              description:
                                    'Used to page through the results. If there are more records in your account than the limit= parameter, you will need to use the offset returned in the first request to get the next set of results.',
                              type: 'string',
                        },
                        {
                              name: 'properties',
                              in: 'query',
                              description:
                                    'properties by comma seprated. like this properties=dealname,dealstage,pipeline,hubspot_owner_id,amount,dealtype',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            post: {
                  tags: ['HubSpot'],
                  summary: 'Create a deal',
                  description:
                        'All deal data from HubSpot, including any associated object data. It returns a paginated set of deals.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'body',
                              in: 'body',
                              description: 'Pass customer object',
                              required: true,
                              sampleData: {
                                    properties: [
                                          {
                                                value: '164728720',
                                                name: 'hubspot_owner_id',
                                          },
                                          {
                                                value: "Tim's Newer Deal",
                                                name: 'dealname',
                                          },
                                          {
                                                value: 'appointmentscheduled',
                                                name: 'dealstage',
                                          },
                                          {
                                                value: 'default',
                                                name: 'pipeline',
                                          },
                                          {
                                                value: 1409443200000,
                                                name: 'closedate',
                                          },
                                          {
                                                value: '60000',
                                                name: 'amount',
                                          },
                                          {
                                                value: 'newbusiness',
                                                name: 'dealtype',
                                          },
                                    ],
                              },
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      return schemaObj;
};
