exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/dataSourcesList`] = {
        get: {
            tags: ['Google Fit'],
            summary: 'Data Sources List',
            description: 'Lists all data source',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    // schemaObj[`/${username}/${appId}/${token}/dataSources`] = {
    //     get: {
    //         tags: ['Google Fit'],
    //         summary: 'Data Sources',
    //         description: 'Get a specific data source data',
    //         produces: ['application/json'],
    //         parameters: [
    //             {
    //                 name: 'dataSourceId',
    //                 in: 'query',
    //                 description:
    //                     'The data stream ID of the data source that created the dataset.',
    //                 required: true,
    //             },
    //         ],
    //         responses: {
    //             '200': {
    //                 description: 'successful operation',
    //             },
    //         },
    //     },
    // };
    // schemaObj[`/${username}/${appId}/${token}/dataPointChanges`] = {
    //     get: {
    //         tags: ['Google Fit'],
    //         summary: 'Data Point Changes',
    //         description:
    //             'Get user data point changes for a particular data source',
    //         produces: ['application/json'],
    //         parameters: [
    //             {
    //                 name: 'dataSourceId',
    //                 in: 'query',
    //                 description:
    //                     'The data stream ID of the data source that created the dataset.',
    //                 required: true,
    //             },
    //         ],
    //         responses: {
    //             '200': {
    //                 description: 'successful operation',
    //             },
    //         },
    //     },
    // };
    schemaObj[`/${username}/${appId}/${token}/aggregatesDatasets`] = {
        get: {
            tags: ['Google Fit'],
            summary: 'Aggregate Datasets',
            description:
                'Aggregates data of a certain type or stream into buckets divided by a given type of boundary.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'dataTypeName',
                    in: 'query',
                    description:
                        'Data Type name. You can pass mutiple by seprated commas. valid values are <b>steps_count</b>,  <b>active_minutes</b>, <b>calories_expended</b>, <b>heart_minutes</b>, <b>sleep_segment</b>, <b>weight</b>, <b>activity_summary</b>',
                    required: true,
                },
                {
                    name: 'timePeriod',
                    in: 'query',
                    description:
                        'Your datasets time period. by default is 7days(you will get last 7 days datasets). Valid values are: <b>today</b>, <b>7days</b>, <b>30days</b>',
                },
                {
                    name: 'durationTime',
                    in: 'query',
                    description:
                        'By default data is returning for every day. You can also get data per hour. Valid values are <b>daily</b>, <b>hourly</b>',
                },
                {
                    name: 'formatData',
                    in: 'query',
                    description:
                        'By default we are formating your data for you. Just pass query param formatData as <b>false</b> then your will get original response',
                },
                {
                    name: 'customTimePeriod',
                    in: 'query',
                    description:
                        'This will overlap <b>timePeriod</b> query param. the format for this will be like an array <b>[startTime,endTime]</b> and dates are passed as strings encoded according to RFC. Example: <b>["Tue, 20 Jul 2021 07:17:33 GMT","Tue, 16 Jul 2021 07:17:33 GMT"]</b>',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
