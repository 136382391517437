exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/listDatabases`] = {
        get: {
            tags: ['Notion'],
            summary: 'List databases',
            description: 'List all the database from your notion account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'start_cursor',
                    in: 'query',
                    description:
                        'If supplied, this endpoint will return a page of results starting after the cursor provided.',
                    type: 'string',
                },
                {
                    name: 'page_size',
                    in: 'query',
                    description:
                        'The number of items from the full list desired in the response. Maximum: 100',
                    type: 'string',
                },
                {
                    name: 'id',
                    in: 'query',
                    description:
                        'If supplied, then will return single database object',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/database`] = {
        get: {
            tags: ['Notion'],
            summary: 'Table',
            description:
                'Lists all entries as JSON from Notion Table, Board, List, Calendar, Gallery, Timeline databses.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'pass the databse id as query param',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'fields',
                    in: 'query',
                    description:
                        'filter fields value you want. pass key values by comma seprated like name,email,address',
                    type: 'string',
                },
                {
                    name: 'responseType',
                    in: 'query',
                    description:
                        'pass this param to change the response result. example: blocks or json. by default is json',
                    type: 'string',
                },
                {
                    name: 'sortBy',
                    in: 'query',
                    description: 'The name of the property to sort against.',
                    type: 'string',
                },
                {
                    name: 'sortDirection',
                    in: 'query',
                    description:
                        'The direction to sort. Possible values include "ascending" and "descending"',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/page`] = {
        get: {
            tags: ['Notion'],
            summary: 'Page Content',
            description: 'Get the content of a notion page as blocks or html',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'Identifier for a Notion page(page id)',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'start_cursor',
                    in: 'query',
                    description:
                        'If supplied, this endpoint will return a page of results starting after the cursor provided.',
                    type: 'string',
                },
                {
                    name: 'page_size',
                    in: 'query',
                    description:
                        'The number of items from the full list desired in the response. Maximum: 100',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/pageInfo`] = {
        get: {
            tags: ['Notion'],
            summary: 'Page info',
            description: 'Get page information',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'Identifier for a Notion page(page id)',
                    type: 'string',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['Notion'],
            summary: 'Search',
            description: 'Make search into yout notion account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'query',
                    in: 'query',
                    description:
                        'When supplied, limits which pages are returned by comparing the query to the page title.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'sortDirection',
                    in: 'query',
                    description: 'ascending or descending',
                    type: 'string',
                },
                {
                    name: 'filterValue',
                    in: 'query',
                    description: 'page or database',
                    type: 'string',
                },
                {
                    name: 'start_cursor',
                    in: 'query',
                    description:
                        'If supplied, this endpoint will return a page of results starting after the cursor provided.',
                    type: 'string',
                },
                {
                    name: 'page_size',
                    in: 'query',
                    description:
                        'The number of items from the full list desired in the response. Maximum: 100',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/users`] = {
        get: {
            tags: ['Notion'],
            summary: 'List all users',
            description: 'List all the users of your notion account',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'user_id',
                    in: 'query',
                    description:
                        'If supplied, then will return single user object',
                    type: 'string',
                },
                {
                    name: 'start_cursor',
                    in: 'query',
                    description:
                        'If supplied, this endpoint will return a page of results starting after the cursor provided.',
                    type: 'string',
                },
                {
                    name: 'page_size',
                    in: 'query',
                    description:
                        'The number of items from the full list desired in the response. Maximum: 100',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
