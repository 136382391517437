import React from 'react';
import { message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
const CodeSnippet = ({ code, language, id, readOnly = true }) => {
  const copyAPICode = () => {
    const textArea = document.createElement('textarea');
    textArea.textContent = code;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();

    message.success('copied');
  };

  return (
    <div>
      {id !== 'apiResult' && (
        <>
          <a
            style={{
              position: 'absolute',
              marginTop: -63,
              right: '6px',
            }}
            onClick={copyAPICode}
          >
            <CopyOutlined /> Copy this code
          </a>
          <br />
        </>
      )}

      <Editor
        width='800'
        height='50vh'
        language={language}
        id={id}
        theme='vs'
        value={code}
        className='overflow-auto'
        options={{
          readOnly,
          scrollBeyondLastLine: false,
          overviewRulerLanes: 0,
          scrollbar: {
            vertical: 'visible',
            horizontal: 'visible',
            handleMouseWheel: true,
          },
          wordWrap: 'on',
          selectOnLineNumbers: true,
          minimap: {
            enabled: false,
          },
        }}
      />
    </div>
  );
};

export default CodeSnippet;
