exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/lists`] = {
        get: {
            tags: ['MailChimp'],
            summary: 'All Lists',
            description: 'List all the MailChimp Lists.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of records to return. Default value is 100. Maximum value is 1000',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'sort_dir',
                    in: 'query',
                    description: 'Determines the order direction for sorted results. ASC, or DESC',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'sort_field',
                    in: 'query',
                    description: 'Returns files sorted by the specified field.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/members`] = {
        get: {
            tags: ['MailChimp'],
            summary: 'Get members',
            description: 'Get all the members of a list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'List id.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of records to return. Default value is 100. Maximum value is 1000',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        "The subscriber's status. subscribed, unsubscribed, cleaned, pending, transactional, or archived",
                    required: false,
                    type: 'string',
                },
                {
                    name: 'since_timestamp_opt',
                    in: 'query',
                    description:
                        'Restrict results to subscribers who opted-in after the set timeframe. We recommend ISO 8601 time format: 2015-10-21T15:41:36+00:00',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'before_timestamp_opt',
                    in: 'query',
                    description:
                        'Restrict results to subscribers who opted-in after the set timeframe. We recommend ISO 8601 time format: 2015-10-21T15:41:36+00:00',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'sort_field',
                    in: 'query',
                    description:
                        'Returns files sorted by the specified field. timestamp_opt, timestamp_signup, or last_changed',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'sort_dir',
                    in: 'query',
                    description: 'Determines the order direction for sorted results. ASC, or DESC',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        post: {
            tags: ['MailChimp'],
            summary: 'Add members',
            description: 'Add new member into the list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'List id.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'email_address',
                    in: 'query',
                    description: 'Email address of member',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    required: true,
                    description: 'Status: subscribed, unsubscribed, cleaned, pending, transactional',
                    type: 'string',
                },
                {
                    name: 'ip_signup',
                    in: 'query',
                    description: 'IP address the subscriber signed up from.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'timestamp_signup',
                    in: 'query',
                    description: 'The date and time the subscriber signed up for the list in ISO 8601 format.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'tags',
                    in: 'query',
                    description: 'The tags that are associated with a member.',
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        delete: {
            tags: ['MailChimp'],
            summary: 'Delete members',
            description: 'Remove one member from the list.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'list_id',
                    in: 'query',
                    description: 'List id.',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'hash_id',
                    in: 'query',
                    description: 'id of the member',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/campaigns`] = {
        get: {
            tags: ['MailChimp'],
            summary: 'List all Compaigns',
            description: 'Get all compaigns data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'The status of the campaign. Possible Values: save, paused, schedule, sending, or sent.',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'The number of records to return. Default value is 100. Maximum value is 1000',
                    type: 'string',
                },
                {
                    name: 'sort_dir',
                    in: 'query',
                    description: 'Determines the order direction for sorted results. ASC, or DESC',
                    type: 'string',
                },
                {
                    name: 'sort_field',
                    in: 'query',
                    description: 'Returns files sorted by the specified field.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/campaign`] = {
        get: {
            tags: ['MailChimp'],
            summary: 'Single Compaign',
            description: 'Single Compaign data.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    required: true,
                    description: 'campaign ID.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
