import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { navigate, Link } from 'gatsby';

import { Firebase } from '../../config/firebase';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { getAddonName } from '../../config/custom/functions';
import '../../styles/api-page.scss';

const AddonPage = ({ addonId, location }) => {
      const state = useContext(GlobalStateContext);
      const { user, selectedProjectId } = state;

      const addonName = getAddonName(addonId);
      const [jwtToken, setJwtToken] = useState('');

      const [loading, setLoading] = useState(false);
      
      useEffect(() => {
            setLoading(true);
            async function fetchUserSubscription(userId) {
                  const subRes = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/subscriptions/addons/${userId}/${addonId}`
                  );
                  const { message } = subRes.data;
                  if (message === 'No subscription') {
                        navigate('/addonmarketplace');
                  }
                  

                  const jwt = await axios.get(`${process.env.GATSBY_BASE_URL}/auth/apifuse/jwt/${userId}`);
                  if (jwt) {
                        setJwtToken(jwt.data.jwtToken);
                  }

                  setLoading(false);
                  if (user.notFound) {
                        navigate('/dashboard/setup');
                  }
            }
            Firebase.auth().onAuthStateChanged((firebaseUser) => {
                  if (firebaseUser && user !== null && user !== undefined) {
                        fetchUserSubscription(user.uid);
                  }
            });
      }, [user]);

  
      return (
            <div className="api-page">
                  <div className="two-col-page-head-section">
                        <h3 style={{ marginBottom: 0 }}>
                              <p
                                    style={{
                                          fontSize: 13,
                                          margin: 0,
                                          padding: 0,
                                    }}
                              >
                                    <Link to="/dashboard/addons">
                                          View all addons
                                    </Link>
                              </p>
                              <img
                                    src={`/addons_logos/${addonId}.png`}
                                    alt=""
                                    height="26px"
                                    style={{
                                          marginRight: 10,
                                    }}
                              />{' '}
                              {addonName}
                        </h3>

                  </div>
                  {
                       (addonId === 'workflows' && !loading && jwtToken !=='') &&
                            <div>
                               <iframe src={`https://nocodeapi.apifuse.io?jwt=${jwtToken}`} style={{height:"1000px", width:"1000px", marginTop:"50px",border:"none"}}></iframe>
                            </div>
                        }
            </div>
      );
};

export default AddonPage;
