exports.obj = function (username, appId, token) {
      const schemaObj = {};
      schemaObj[`/${username}/${appId}/${token}/search`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Search',
                  description:
                        'Get Spotify Catalog information about albums, artists, playlists, tracks, shows or episodes that match a keyword string.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'q',
                              in: 'query',
                              description:
                                    'Query you want to search on spotify',
                              required: true,
                        },
                        {
                              name: 'type',
                              in: 'query',
                              description:
                                    'album , artist, playlist, track, show and episode. By default is album',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/browse/categories`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Browse Categories',
                  description:
                        'Get a list of categories used to tag items in Spotify (on, for example, the Spotify player’s “Browse” tab).',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'country',
                              in: 'query',
                              description:
                                    'A country: an ISO 3166-1 alpha-2 country code.',
                        },
                        {
                              name: 'locale',
                              in: 'query',
                              description:
                                    'The desired language, consisting of an ISO 639-1 language code',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/browse/categoryPlaylist`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Browse Category Playlist',
                  description:
                        'Get a list of Spotify playlists tagged with a particular category.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'category_id',
                              in: 'query',
                              description:
                                    'The Spotify category ID for the category.',
                              required: true,
                        },
                        {
                              name: 'country',
                              in: 'query',
                              description:
                                    'A country: an ISO 3166-1 alpha-2 country code.',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/browse/featured`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Browse featured',
                  description:
                        'Get a list of Spotify featured playlists (shown, for example, on a Spotify player’s ‘Browse’ tab).',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'country',
                              in: 'query',
                              description:
                                    'A country: an ISO 3166-1 alpha-2 country code.',
                        },
                        {
                              name: 'locale',
                              in: 'query',
                              description:
                                    'The desired language, consisting of an ISO 639-1 language code',
                        },
                        {
                              name: 'timestamp',
                              in: 'query',
                              description:
                                    'A timestamp in ISO 8601 format: yyyy-MM-ddTHH:mm:ss',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/browse/new`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'New Releases',
                  description:
                        'Get a list of Spotify new playlists (shown, for example, on a Spotify player’s ‘Browse’ tab).',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'country',
                              in: 'query',
                              description:
                                    'A country: an ISO 3166-1 alpha-2 country code.',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/usersTop`] = {
            get: {
                  tags: ['Spotify'],
                  summary: "User's Top",
                  description: 'Get User’s Top Artists and Tracks',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'type',
                              in: 'query',
                              description:
                                    'The type of entity to return. Valid values: artists or tracks',
                              required: true,
                        },
                        {
                              name: 'time_range',
                              in: 'query',
                              description:
                                    'Over what time frame the affinities are computed. Valid values: long_term (calculated from several years of data and including all new data as it becomes available), medium_term (approximately last 6 months), short_term (approximately last 4 weeks). Default: medium_term',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/recommendations`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Recommendations',
                  description: 'Get Recommendations Based on Seeds',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'seed_artists',
                              in: 'query',
                              description:
                                    'A comma separated list of Spotify IDs for seed artists. Up to 5 seed values may be provided in any combination of seed_artists, seed_tracks and seed_genres.',
                        },
                        {
                              name: 'seed_genres',
                              in: 'query',
                              description:
                                    'A comma separated list of any genres in the set of available genre seeds. Up to 5 seed values may be provided in any combination of seed_artists, seed_tracks and seed_genres.',
                        },
                        {
                              name: 'seed_tracks',
                              in: 'query',
                              description:
                                    'A comma separated list of Spotify IDs for a seed track. Up to 5 seed values may be provided in any combination of seed_artists, seed_tracks and seed_genres.',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                        {
                              name: 'min_acousticness',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_acousticness',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_acousticness',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_danceability',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_danceability',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_danceability',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_duration_ms',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_duration_ms',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_duration_ms',
                              in: 'query',
                              description: 'Target duration of the track (ms)',
                              type: 'string',
                        },
                        {
                              name: 'min_energy',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_energy',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_energy',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_instrumentalness',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_instrumentalness',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_instrumentalness',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_key',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_key',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_key',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_liveness',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_liveness',
                              in: 'query',
                              description:
                                    ' For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_liveness',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_loudness',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_loudness',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_loudness',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_mode',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_mode',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_mode',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_popularity',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_popularity',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_popularity',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_speechiness',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_speechiness',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_speechiness',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_tempo',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_tempo',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_tempo',
                              in: 'query',
                              description: 'Target tempo (BPM)',
                              type: 'string',
                        },
                        {
                              name: 'min_time_signature',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_time_signature',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_time_signature',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                        {
                              name: 'min_valence',
                              in: 'query',
                              description:
                                    'For example, min_tempo=140 would restrict results to only those tracks with a tempo of greater than 140 beats per minute.',
                              type: 'string',
                        },
                        {
                              name: 'max_valence',
                              in: 'query',
                              description:
                                    'For example, max_instrumentalness=0.35 would filter out most tracks that are likely to be instrumental.',
                              type: 'string',
                        },
                        {
                              name: 'target_valence',
                              in: 'query',
                              description:
                                    'For example, you might request target_energy=0.6 and target_danceability=0.8. All target values will be weighed equally in ranking results.',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/availableGenreSeeds`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Available Genre Seeds',
                  description: 'Gejnjknjk',
                  produces: ['application/json'],
                  parameters: [],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/following`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Following',
                  description: 'Get the current user’s followed artists.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            put: {
                  tags: ['Spotify'],
                  summary: 'Follow Artists or Users',
                  description:
                        'Add the current user as a follower of one or more artists or other Spotify users.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'A comma-separated list of the artist or the user Spotify IDs.',
                              type: 'string',
                              required: true,
                        },
                        {
                              name: 'type',
                              in: 'query',
                              description:
                                    'The ID type: either artist or user.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            delete: {
                  tags: ['Spotify'],
                  summary: 'Unfollow Artists or Users',
                  description:
                        'Remove the current user as a follower of one or more artists or other Spotify users.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'A comma-separated list of the artist or the user Spotify IDs.',
                              type: 'string',
                              required: true,
                        },
                        {
                              name: 'type',
                              in: 'query',
                              description:
                                    'The ID type: either artist or user.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/myPlaylists`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'My Playlists',
                  description:
                        'Get a list of the playlists owned or followed by the current Spotify user.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/myLibrary`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'My Library',
                  description:
                        'Get a list of saved items in the current Spotify user’s ‘Your Music’ library.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'type',
                              in: 'query',
                              description: 'albums, shows, or tracks',
                              required: true,
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/playlists`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Playlists',
                  description:
                        'Retrieving information about a user’s playlists',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'The Spotify ID for the playlist.',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            put: {
                  tags: ['Spotify'],
                  summary: 'Follow Playlists',
                  description: 'Follow a Playlist',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'playlist_id',
                              in: 'query',
                              description: 'The playlist_id for the playlist.',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
            delete: {
                  tags: ['Spotify'],
                  summary: 'Unfollow Playlists',
                  description: 'Unfollow a Playlist',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'playlist_id',
                              in: 'query',
                              description: 'The playlist_id for the playlist.',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/artists`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Artists',
                  description:
                        'Get Spotify catalog information for a single artist identified by their unique Spotify ID and artists albums, top tracks, related artists.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'id',
                              in: 'query',
                              description: 'The Spotify ID for the artist.',
                              required: true,
                        },
                        {
                              name: 'queryType',
                              in: 'query',
                              description:
                                    'albums, top-tracks, or related-artists',
                        },
                        {
                              name: 'market',
                              in: 'query',
                              description:
                                    'An ISO 3166-1 alpha-2 country code or the string',
                        },
                        {
                              name: 'perPage',
                              in: 'query',
                              description:
                                    'Maximum number of results to return.',
                        },
                        {
                              name: 'page',
                              in: 'query',
                              description: 'paginate the number',
                              type: 'string',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/tracks`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Tracks',
                  description:
                        'Get Spotify catalog information for multiple tracks based on their Spotify IDs.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'A comma-separated list of the Spotify IDs for the tracks. Maximum: 50 IDs.',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/recentlyPlayed`] = {
            get: {
                  tags: ['Spotify'],
                  summary: 'Recently Played Tracks',
                  description: 'Get Recently Played Tracks',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'limit',
                              in: 'query',
                              description:
                                    'The maximum number of items to return. Default: 20. Minimum: 1. Maximum: 50.',
                        },
                        {
                              name: 'after',
                              in: 'query',
                              description:
                                    'A Unix timestamp in milliseconds. Returns all items after (but not including) this cursor position.',
                        },
                        {
                              name: 'before',
                              in: 'query',
                              description:
                                    'A Unix timestamp in milliseconds. Returns all items before (but not including) this cursor position.',
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };

      schemaObj[`/${username}/${appId}/${token}/saveTracks`] = {
            put: {
                  tags: ['Spotify'],
                  summary: 'Save Tracks',
                  description:
                        'Save one or more tracks to the current user’s ‘Your Music’ library.',
                  produces: ['application/json'],
                  parameters: [
                        {
                              name: 'ids',
                              in: 'query',
                              description:
                                    'A comma-separated list of the Spotify IDs for the tracks. Maximum: 50 IDs.',
                              required: true,
                        },
                  ],
                  responses: {
                        200: {
                              description: 'successful operation',
                        },
                  },
            },
      };
      return schemaObj;
};
