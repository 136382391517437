import React from 'react';
import { Router } from '@reach/router';


import Applications from '../blocks/components/applications';
import APIpage from '../blocks/components/apiPage';

import Addons from '../blocks/components/addons';
import AddonPage from '../blocks/components/addonPage';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';

// markup
const Dashboard = ({ location }) => {
      return (
            <>
                  <SEO title="Dashboard for your APIs — NoCodeAPI" />
                  <AppContainer activeNav="dashboard" location={location}>
                        <Router>
                              <Applications
                                    path="/dashboard"
                                    location={location}
                              />
                              <APIpage
                                    location={location}
                                    path="/dashboard/api/:appId"
                              />
                              <Addons
                                    location={location}
                                    path="/dashboard/addons"
                              />
                              <AddonPage
                                    location={location}
                                    path="/dashboard/addons/:addonId"
                              />
                        </Router>
                  </AppContainer>
            </>
      );
};

export default Dashboard;
