import React, { useState, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from "gatsby"
import {
      message,
      Drawer,
      Checkbox,
      Button,
      Tooltip,
      Input,
      Form,
      Divider,
      Typography,
      Table,
} from 'antd';
import { VscSettingsGear } from 'react-icons/vsc';
import {
      RiShieldFlashLine,
      RiLinksLine,
      RiKey2Fill,
      RiFileCopyLine,
} from 'react-icons/ri';
import {
      PlusOutlined,
      DeleteOutlined,
      SecurityScanOutlined,
} from '@ant-design/icons';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { Firebase } from '../../config/firebase';
import '../../styles/secure-drawer.scss';
import { randomString } from '../../config/custom/functions';

const checkboxListArray = [
      {
            label: 'GET',
            value: 'get',
      },
      {
            label: 'POST',
            value: 'post',
      },
      {
            label: 'PUT',
            value: 'put',
      },
      {
            label: 'DELETE',
            value: 'delete',
      },
];

const SecureEndpoint = ({ apiObj }) => {
      const [authorizedDomains, setAuthorizedDomains] = useState([]);
      const state = useContext(GlobalStateContext);
      const [checkedValues, setCheckedValues] = useState([]);
      const [apiToSecure, setApiToSecure] = useState(null);
      const [visible, setVisible] = useState(false);
      const [loading, setLoading] = useState(false);
      const [localhostEnable, setLocalhostEnable] = useState(false);
      const { Text } = Typography;
      const { user } = state;
      const isAllowedSecure = (user.status.includes('pro') || user.status.includes('enterprise'))?true:false;

      const addAuthorizedDomain = (values) => {
            const { domain_name } = values;
            const domainHostName = new URL(domain_name).host;
            const isAddedAlready = authorizedDomains.findIndex(
                  (x) => x.name === domainHostName
            );

            if (isAddedAlready === -1) {
                  const domainsArray = [
                        ...authorizedDomains,
                        { name: domainHostName, added_date: Date.now() },
                  ];
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              axios.put(
                                    `${process.env.GATSBY_BASE_URL}/secureEndpoint/withOrigin`,
                                    { domainsArray, token: apiToSecure.token },
                                    {
                                          headers: {
                                                'Content-Type':
                                                      'application/json',
                                          },
                                    }
                              )
                                    .then((res) => {
                                          setAuthorizedDomains(domainsArray);
                                          message.success(
                                                `${domainHostName}, is added successfully`
                                          );
                                    })
                                    .catch((error) => {
                                          message.error('Something is wrong!');
                                    });
                        }
                  });
            } else {
                  message.info(`${domainHostName}, is already there.`);
            }
      };

      const secureThisEndpoint = () => {
            const get = checkedValues.findIndex((x) => x === 'get') > -1;
            const post = checkedValues.findIndex((x) => x === 'post') > -1;
            const put = checkedValues.findIndex((x) => x === 'put') > -1;
            const deleteValue =
                  checkedValues.findIndex((x) => x === 'delete') > -1;

            const keys = {
                  api_key: apiToSecure.api_key,
                  get,
                  post,
                  put,
                  delete: deleteValue,
            };

            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        axios.put(
                              `${process.env.GATSBY_BASE_URL}/secure-endpoint/with-api-key`,
                              { keys, token: apiToSecure.token, uid: user.uid },
                              {
                                    headers: {
                                          'Content-Type': 'application/json',
                                    },
                              }
                        )
                              .then((res) => {
                                    message.success(
                                          'Your endpoint is key settings updated!'
                                    );
                                    setVisible(false);
                              })
                              .catch((error) => {
                                    message.error('Something is wrong!');
                              });
                  }
            });
      };

      const openSecurePage = () => {
            setApiToSecure(apiObj);
            setAuthorizedDomains(apiObj.origin_domains);
            const getLocalhostOrigin = apiObj.origin_domains.findIndex(
                  (x) => x.name === 'localhost'
            );
            if (getLocalhostOrigin > -1) setLocalhostEnable(true);
            if (apiObj.keys !== null) {
                  const activeCheck = [];
                  const { get, post, put } = apiObj.keys;
                  if (get) activeCheck.push('get');
                  if (post) activeCheck.push('post');
                  if (put) activeCheck.push('put');
                  if (apiObj.keys.delete) activeCheck.push('delete');
                  setCheckedValues(activeCheck);
            }
            setVisible(true);
      };

      const handleLocalhostEnable = (e) => {
            const checkValue = e.target.checked;
            setLocalhostEnable(checkValue);

            var domainsArray = [...authorizedDomains];
            var resMessage = '';

            if (checkValue) {
                  domainsArray.push({
                        name: 'localhost',
                        added_date: Date.now(),
                  });
                  resMessage = 'localhost, is added successfully';
            } else {
                  domainsArray = authorizedDomains.filter(function (item) {
                        return item.name !== 'localhost';
                  });
                  resMessage = 'localhost, is removed successfully';
            }

            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        axios.put(
                              `${process.env.GATSBY_BASE_URL}/secureEndpoint/withOrigin`,
                              { domainsArray, token: apiToSecure.token },
                              {
                                    headers: {
                                          'Content-Type': 'application/json',
                                    },
                              }
                        )
                              .then((res) => {
                                    setAuthorizedDomains(domainsArray);
                                    message.success(resMessage);
                              })
                              .catch((error) => {
                                    message.error('Something is wrong!');
                              });
                  }
            });
      };

      const removeDomain = (record) => {
            const { name } = record;

            const domainIndexToRemove = authorizedDomains.findIndex(
                  (x) => x.name === name
            );

            if (domainIndexToRemove > -1) {
                  const domainsArray = authorizedDomains.filter(function (
                        item
                  ) {
                        return item.name !== name;
                  });
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              axios.put(
                                    `${process.env.GATSBY_BASE_URL}/secureEndpoint/withOrigin`,
                                    { domainsArray, token: apiToSecure.token },
                                    {
                                          headers: {
                                                'Content-Type':
                                                      'application/json',
                                          },
                                    }
                              )
                                    .then((res) => {
                                          setAuthorizedDomains(domainsArray);
                                          message.success(
                                                `${name}, is removed succesfully`
                                          );
                                          if (name === 'localhost')
                                                setLocalhostEnable(false);
                                    })
                                    .catch((error) => {
                                          message.error('Something is wrong!');
                                    });
                        }
                  });
            } else {
                  message.info(`There are some issues.`);
            }
      };

      const domainColumns = [
            {
                  title: 'Authorized domain',
                  key: 'name',
                  render: (text, record) => (
                        <div
                              style={{
                                    display: 'flex',
                                    alignItems: 'center',
                              }}
                        >
                              <SecurityScanOutlined />
                              <span style={{ marginLeft: 10 }}>
                                    {record.name}
                              </span>
                        </div>
                  ),
            },
            {
                  title: 'Added date',
                  key: 'added_date',
                  render: (text, record) => (
                        <div>
                              <span style={{ marginLeft: 10 }}>
                                    {moment
                                          .unix(record.added_date / 1000)
                                          .fromNow()}
                              </span>
                        </div>
                  ),
            },
            {
                  title: 'Action',
                  key: 'action',
                  render: (text, record) => (
                        <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => removeDomain(record)}
                        >
                              <Tooltip
                                    style={{ fontSize: 13 }}
                                    title={`Remove ${record.name}`}
                              >
                                    <DeleteOutlined /> Remove
                              </Tooltip>
                        </div>
                  ),
            },
      ];

      return (
            <>
                  <div
                        className="option secure-endpoint"
                        onClick={openSecurePage}
                  >
                        <RiShieldFlashLine />
                        <span>Secure this endpoint</span>
                  </div>
                  <Drawer
                        width={500}
                        title="Secure Endpoint"
                        placement="left"
                        closable={false}
                        onClose={() => setVisible(false)}
                        visible={visible}
                        className="secure-drawer"
                  >
                        {
                          !isAllowedSecure &&
                          <>
                              <div className="pro-mode-enable">
                                    <Text style={{color:'#fff'}}>Securing this endpoint will require a <Link to="/plans">paid account</Link></Text>
                              </div>
                              <br/>
                          </>
                        }
                        
                        

                        <h3 className="h3-heading">Authorized domains</h3>
                        <p>
                              Add domain to allow your API request. Add valid
                              domain url. Likethis is valid format ↳{' '}
                              <b>https://example.com</b> or{' '}
                              <b>https://sub.example.com</b>
                        </p>
                        <Form name="basic" onFinish={addAuthorizedDomain}>
                              <Form.Item
                                    name="domain_name"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Please valid domain name.',
                                                type: 'url',
                                          },
                                    ]}
                              >
                                    <Input
                                          placeholder="Input domain URL"
                                          prefix={<RiLinksLine />}
                                    />
                              </Form.Item>

                              <Form.Item>
                                    <Button
                                          icon={<PlusOutlined />}
                                          disabled={!isAllowedSecure}
                                          type="primary"
                                          htmlType="submit"
                                          style={{
                                                fontSize: 13,
                                                fontWeight: 400,
                                          }}
                                    >
                                          Add domain
                                    </Button>
                              </Form.Item>
                        </Form>

                        {authorizedDomains.length > 0 && (
                              <>
                                    <Checkbox
                                          onChange={handleLocalhostEnable}
                                          checked={localhostEnable}
                                          style={{
                                                fontSize: 14,
                                                color: '#635e69',
                                                fontWeight: 300,
                                          }}
                                    >
                                          Allow this API to use with
                                          localhost(for locally development)
                                    </Checkbox>
                                    <br />
                                    <br />
                              </>
                        )}

                        <Table
                              dataSource={authorizedDomains}
                              columns={domainColumns}
                              className="domain-list"
                              locale={{
                                    emptyText: 'No Authorized domain added',
                              }}
                              rowKey={(record) => randomString(32, 'aA#')}
                        />
                        <br />
                        <Divider />
                        <br />

                        <h3 className="h3-heading">Secure HTTP request</h3>
                        <p>
                              To secure your endpoint for <b>GET</b>,{' '}
                              <b>POST</b>, <b>PUT</b>, or <b>DELETE</b> select
                              the checkbox and click on the button.
                        </p>
                        <Checkbox.Group
                              options={checkboxListArray}
                              defaultValue={checkedValues}
                              onChange={(values) => setCheckedValues(values)}
                        />
                        <br />
                        <br />
                        <Button
                              loading={loading}
                              type="primary"
                              onClick={secureThisEndpoint}
                              disabled={!isAllowedSecure}
                              icon={
                                    <VscSettingsGear
                                          style={{ marginRight: 5 }}
                                    />
                              }
                              style={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                              }}
                        >
                              Update Key Settings
                        </Button>
                        {apiToSecure !== null && (
                              <>
                                    <br />
                                    <br />
                                    <h4 style={{ fontWeight: 700 }}>API Key</h4>
                                    <Input
                                          addonAfter={<RiFileCopyLine />}
                                          prefix={<RiKey2Fill />}
                                          defaultValue={
                                                apiToSecure.keys.api_key
                                          }
                                          disabled
                                    />
                                    <p>
                                          You have to pass <b>api_key</b> as
                                          query param to your endpoint.
                                    </p>
                              </>
                        )}
                  </Drawer>
            </>
      );
};

export default SecureEndpoint;
