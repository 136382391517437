exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}/search`] = {
    post: {
      tags: ['Chat GPT'],
      summary: 'Send your question prompt to  NocodeAPI',
      description: 'Send your question prompt to your NocodeAPI.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'body',
          in: 'body',
          description: 'Pass question prompt into body',
          required: true,
          type: 'string',
          sampleData: {
            prompt: 'Explain how to create a youtube video in simple terms.',
          },
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    get: {
      tags: ['Chat GPT'],
      summary: 'Generate Chat GPT Prompt',
      description: 'Generate Chat GPT Prompt',
      produces: ['application/json'],
      parameters: [
        {
          name: 'Prompt',
          in: 'prompt',
          description: 'Generate Chat Gpt Prompts',
          required: true,
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };
  return schemaObj;
};
