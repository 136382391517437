import React, { useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import axios from 'axios';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';

const { Option } = Select;

const WebflowForm = ({ form, userID, formState }) => {
      const [loading, setLoading] = useState(false);
      const [sitesList, setSitesList] = useState([]);
      const [siteCollections, setSiteCollections] = useState([]);

      const getWebflowSites = (e) => {
            const { api_access } = form.getFieldsValue();
            const apiAccess = e.target.value || api_access;
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/webflow/getSites?token=${apiAccess}`,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            )
                  .then((result) => {
                        const siteData = result.data;
                        if (siteData.code !== undefined) {
                              message.error(siteData.msg);
                        } else {
                              setSitesList(siteData.sites);
                        }
                  })
                  .catch((err) => {
                        message.error('Your API access is in-valid');
                  });
      };

      const getSiteCollections = (e) => {
            const { api_access, siteId } = form.getFieldsValue();
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/webflow/getCollections?token=${api_access}&siteId=${siteId}`,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            )
                  .then((result) => {
                        const colData = result.data;
                        if (colData.code !== undefined) {
                              message.error(colData.msg);
                        } else {
                              setSiteCollections(colData.collections);
                        }
                  })
                  .catch((err) => {
                        message.error('something is missing.');
                  });
      };

      console.log(sitesList);

      return (
            <>
                  <Form.Item
                        label="Give a name"
                        name="name"
                        rules={[
                              { required: true, message: 'Name is required!' },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Give a name to this API.',
                                    }}
                              />
                        }
                  >
                        <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                        label="API Access"
                        name="api_access"
                        rules={[
                              {
                                    required: true,
                                    message: 'API Access is required!',
                              },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Go to dashboard -> click on three dots icon -> settings -> Integrations -> Generate API Access. <br/> <b>Note: Now supports on API V2</b>',
                                    }}
                              />
                        }
                  >
                        <Input.Password onChange={getWebflowSites} />
                  </Form.Item>

                  <a
                        style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                        }}
                        onClick={getWebflowSites}
                  >
                        Refresh Dropdown
                  </a>
                  <Form.Item
                        name="siteId"
                        label="Select Project"
                        rules={[
                              { required: true, message: 'Site is required!' },
                        ]}
                  >
                        <Select
                              name="siteId"
                              placeholder="Select Project"
                              onChange={getSiteCollections}
                              allowClear
                        >
                              {sitesList.map((site, key) => (
                                    <Option value={site.id} key={key}>
                                          {site.displayName}
                                    </Option>
                              ))}
                        </Select>
                  </Form.Item>
                  <a
                        style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                        }}
                        onClick={getSiteCollections}
                  >
                        Refresh Dropdown
                  </a>

                  <Form.Item
                        name="collectionId"
                        label="Select Collection"
                        rules={[
                              {
                                    required: true,
                                    message: 'Collection is required!',
                              },
                        ]}
                  >
                        <Select
                              name="collectionId"
                              placeholder="Select property"
                              allowClear
                        >
                              {siteCollections.map((option, key) => (
                                    <Option value={option.id} key={key}>
                                          {option.displayName}
                                    </Option>
                              ))}
                        </Select>
                  </Form.Item>

                  <Form.Item>
                        <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="make-btn"
                              icon={
                                    formState === 'adding' ? (
                                          <BsFolderPlus />
                                    ) : (
                                          <RiEditBoxFill />
                                    )
                              }
                        >
                              {formState === 'adding' ? 'Create' : 'Edit API'}
                        </Button>
                  </Form.Item>
            </>
      );
};

export default WebflowForm;
