exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Read Later'],
            summary: 'Get Entries',
            description: 'List all links entries.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'page',
                    in: 'query',
                    description: 'paginate the number',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Total number of entries you want in one request.(0-100). default is 30',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'archive',
                    in: 'query',
                    description: '1 or 0, all entries by default',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'starred',
                    in: 'query',
                    description: '1 or 0, all entries by default',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'tags',
                    in: 'query',
                    description: 'apis,news,morning. a list of tags. Will returns entries that matches ALL tags.',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: "'created' or 'updated', default 'created'",
                    required: false,
                    type: 'string',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: "'asc' or 'desc', default 'desc'",
                    required: false,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Read Later'],
            summary: 'Add entry',
            description: 'Add your link to Read Later.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'URL of the website',
                    required: true,
                    type: 'string',
                },
                {
                    name: 'title',
                    in: 'query',
                    description: 'If want to overlap the title',
                },
                {
                    name: 'archive',
                    in: 'query',
                    description: '1 or 0, all entries by default',
                },
                {
                    name: 'starred',
                    in: 'query',
                    description: '1 or 0, all entries by default',
                },
                {
                    name: 'preview_picture',
                    in: 'query',
                    description: 'Preview image URL',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        delete: {
            tags: ['Read Later'],
            summary: 'Delete entry',
            description: 'You can delete entries.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'id of the entry.',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
