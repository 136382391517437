exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/subscriptions`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Subscriptions',
            description: 'List of all subscriptions.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'customer',
                    in: 'query',
                    description: 'The ID of the customer whose subscriptions will be retrieved.',
                    type: 'string',
                },
                {
                    name: 'price',
                    in: 'query',
                    description: 'Filter for subscriptions that contain this recurring price ID.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description:
                        'active, past_due, unpaid, canceled, incomplete, incomplete_expired, trialing, all, ended',
                    type: 'string',
                },
                {
                    name: 'collection_method',
                    in: 'query',
                    description:
                        'The collection method of the subscriptions to retrieve. Either charge_automatically or send_invoice',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'current_period_end',
                    in: 'query',
                    description:
                        'A filter on the list based on the object current_period_end field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'current_period_start',
                    in: 'query',
                    description:
                        'A filter on the list based on the object current_period_start field. The value can be a string with an integer Unix timestamp',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/subscriptionItems`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Subscriptions items',
            description: 'Returns a list of your subscription items for a given subscription.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'subscription',
                    in: 'query',
                    required: true,
                    description: 'The ID of the subscription whose items will be retrieved.',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/customers`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Customers',
            description:
                'Returns a list of your customers. The customers are returned sorted by creation date, with the most recent customers appearing first.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'email',
                    in: 'query',
                    description:
                        'A case-sensitive filter on the list based on the customer’s email field. The value must be a string.',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/invoices`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Invoices',
            description: 'You can list all invoices, or list the invoices for a specific customer.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'customer',
                    in: 'query',
                    description: 'Only return invoices for the customer specified by this customer ID.',
                    type: 'string',
                },
                {
                    name: 'status',
                    in: 'query',
                    description: 'The status of the invoice, one of draft, open, paid, uncollectible, or void',
                    type: 'string',
                },
                {
                    name: 'subscription',
                    in: 'query',
                    description: 'Only return invoices for the subscription specified by this subscription ID.',
                    type: 'string',
                },
                {
                    name: 'collection_method',
                    in: 'query',
                    description:
                        'The collection method of the subscriptions to retrieve. Either charge_automatically or send_invoice',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'due_date',
                    in: 'query',
                    description:
                        'A filter on the list based on the object due_date field. The value can be a string with an integer Unix timestamp',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/balanceTransactions`] = {
        get: {
            tags: ['Stripe'],
            summary: 'Balance History',
            description:
                'Returns a list of transactions that have contributed to the Stripe account balance (e.g., charges, transfers, and so forth).',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'payout',
                    in: 'query',
                    description:
                        'For automatic Stripe payouts only, only returns transactions that were paid out on the specified payout ID.',
                    type: 'string',
                },
                {
                    name: 'type',
                    in: 'query',
                    description:
                        'One of: adjustment, advance, advance_funding, anticipation_repayment, application_fee, application_fee_refund, charge, connect_collection_transfer, contribution, issuing_authorization_hold, issuing_authorization_release, issuing_dispute, issuing_transaction, payment, payment_failure_refund, payment_refund, payout, payout_cancel, payout_failure, refund, refund_failure, reserve_transaction, reserved_funds, stripe_fee, stripe_fx_fee, tax_fee, topup, topup_reversal, transfer, transfer_cancel, transfer_failure, or transfer_refund',
                    type: 'string',
                },
                {
                    name: 'available_on',
                    in: 'query',
                    description:
                        'A filter on the list based on the object available_on field. The value can be a string with an integer Unix timestamp',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'currency',
                    in: 'query',
                    description: 'Only return transactions in a certain currency.',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/charges`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Charges',
            description: 'Returns a list of charges you’ve previously created.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'customer',
                    in: 'query',
                    description: 'Only return charges for the customer specified by this customer ID.',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'payment_intent',
                    in: 'query',
                    description:
                        'Only return charges that were created by the PaymentIntent specified by this PaymentIntent ID.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/plans`] = {
        get: {
            tags: ['Stripe'],
            summary: 'List Plans',
            description: 'Returns a list of your plans.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'active',
                    in: 'query',
                    description:
                        'Only return plans that are active or inactive (e.g., pass false to list all inactive plans).',
                    type: 'string',
                },
                {
                    name: 'product',
                    in: 'query',
                    description: 'Only return plans for the given product.',
                    type: 'string',
                },
                {
                    name: 'created',
                    in: 'query',
                    description:
                        'A filter on the list based on the object created field. The value can be a string with an integer Unix timestamp.',
                    type: 'string',
                },
                {
                    name: 'ending_before',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. ending_before is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description:
                        'A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.',
                    type: 'string',
                },
                {
                    name: 'starting_after',
                    in: 'query',
                    description:
                        'A cursor for use in pagination. starting_after is an object ID that defines your place in the list.',
                    type: 'string',
                },
                {
                    name: 'include_fields',
                    in: 'query',
                    description: 'only the fields wants to return in response',
                    type: 'string',
                },
                {
                    name: 'exclude_fields',
                    in: 'query',
                    description: 'fields wants to exclude in response',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
