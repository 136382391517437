import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Button, message, Upload } from 'antd';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';
import { InboxOutlined } from '@ant-design/icons';
import { GlobalStateContext } from '../../../context/GlobalContextProvider';
var Buffer = require('buffer/').Buffer;

const { Dragger } = Upload;

const CSVtoJSON = ({ formState, form }) => {
      const state = useContext(GlobalStateContext);
      const [loading, setLoading] = useState(false);

      useEffect(() => {}, []);

      const normFile = (e) => {
            if (Array.isArray(e)) {
                  return e;
            }
            return e && e.fileList;
      };

      const props = {
            name: 'file',
            action: `${process.env.GATSBY_BASE_URL}/uploadCSV`,
            multiple: false,
            maxCount: 1,
            accept: '.csv',
            beforeUpload(file) {
                  const isLt2M = file.size / 1024 / 1024;
                  const { status } = state.user;
                  if (
                        (isLt2M > 100 && status[0] !== 'free') ||
                        (isLt2M > 1 && status[0] === 'free')
                  ) {
                        file.flag = true;
                        message.info(
                              `CSV file must smaller than 100MB for paid users & 1mb for free users!`
                        );
                        return false;
                  }
                  return true;
            },
            onChange(info) {
                  if (info.file.status !== 'uploading') {
                        console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                        console.log(form, info.file.response);
                        form.setFieldsValue({
                              csvfileInfo: info.file.response,
                        });
                        message.success(
                              `${info.file.name} file uploaded successfully`
                        );
                  } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                  }
            },
      };

      return (
            <>
                  <Form.Item
                        label="Give a name"
                        name="name"
                        rules={[
                              { required: true, message: 'Name is required!' },
                        ]}
                        extra={
                              <div
                                    style={{
                                          fontWeight: 300,
                                          fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                          __html: 'Give a name to this API.',
                                    }}
                              />
                        }
                  >
                        <Input autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                        name="csvfileInfo"
                        label="Upload CSV"
                        noStyle
                        rules={[
                              {
                                    required: true,
                                    message: 'Please upload CSV file!',
                              },
                        ]}
                  >
                        <Input autoComplete="off" type="hidden" />
                  </Form.Item>

                  <Form.Item
                        label="Upload CSV"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true }]}
                  >
                        <Dragger {...props} required>
                              <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                    Click or drag CSV file to this area to
                                    upload
                              </p>
                        </Dragger>
                  </Form.Item>

                  <Form.Item>
                        <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="make-btn"
                              icon={
                                    formState === 'adding' ? (
                                          <BsFolderPlus />
                                    ) : (
                                          <RiEditBoxFill />
                                    )
                              }
                        >
                              {formState === 'adding' ? 'Create' : 'Edit API'}
                        </Button>
                  </Form.Item>
                  <p>
                        CSV file limitation
                        <br />
                        <b>Free users</b>: upto 1mb,
                        <br /> <b>Paid users</b>: upto 100mb
                  </p>
            </>
      );
};

export default CSVtoJSON;
