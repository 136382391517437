exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/sites`] = {
        get: {
            tags: ['Google Search Console'],
            summary: 'List Sites',
            description: "Lists the user's Search Console sites.",
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/searchAnalytics`] = {
        get: {
            tags: ['Google Search Console'],
            summary: 'Search Analytics',
            description:
                'Query your search traffic data with filters and parameters that you define. The method returns zero or more rows grouped by the row keys (dimensions) that you define. You must define a date range of one or more days.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'siteUrl',
                    in: 'query',
                    description: 'The URL of the actual sitemap. For example: http://www.example.com/sitemap.xml',
                    required: true,
                },
                {
                    name: 'startDate',
                    in: 'query',
                    description: 'Start date of the requested date range, in YYYY-MM-DD format.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'endDate',
                    in: 'query',
                    description: 'End date of the requested date range, in YYYY-MM-DD format.',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'dimensions',
                    in: 'query',
                    description: 'Zero or more dimensions by seprated commas. Example: country,device',
                    type: 'string',
                },
                {
                    name: 'searchType',
                    in: 'query',
                    description: 'The search type to filter for, Acceptable values are: news, image, video, web',
                    type: 'string',
                },
                {
                    name: 'aggregationType',
                    in: 'query',
                    description: 'How data is aggregated. Acceptable values are: auto, byPage, byProperty.',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'limit to per API calls',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'pagination',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    schemaObj[`/${username}/${appId}/${token}/sitemaps`] = {
        get: {
            tags: ['Google Search Console'],
            summary: 'Get Sitemaps',
            description: 'Lists the sitemaps-entries submitted for this site, or included in the sitemap index file',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'siteUrl',
                    in: 'query',
                    description: 'The URL of the actual sitemap. For example: http://www.example.com/sitemap.xml',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Google Search Console'],
            summary: 'Add Sitemap',
            description: 'Submits a sitemap for a site.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'siteUrl',
                    in: 'query',
                    description: 'The URL of the actual sitemap. For example: http://www.example.com/sitemap.xml',
                    required: true,
                },
                {
                    name: 'feedpath',
                    in: 'query',
                    description:
                        'The URL of the property as defined in Search Console. For example: http://www.example.com/ (URL-prefix property), or sc-domain:example.com (Domain property).',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        delete: {
            tags: ['Google Search Console'],
            summary: 'Delete Sitemap',
            description: 'Deletes a sitemap from this site. ',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'siteUrl',
                    in: 'query',
                    description: 'The URL of the actual sitemap. For example: http://www.example.com/sitemap.xml',
                    required: true,
                },
                {
                    name: 'feedpath',
                    in: 'query',
                    description:
                        'The URL of the property as defined in Search Console. For example: http://www.example.com/ (URL-prefix property), or sc-domain:example.com (Domain property).',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
