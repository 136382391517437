exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}/listSheets`] = {
        get: {
            tags: ['Smartsheet'],
            summary: 'List Sheets',
            description: 'Gets a list of all sheets that the user has access to in alphabetical order by name',
            produces: ['application/json'],
            parameters: [],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/sheetData`] = {
        get: {
            tags: ['Smartsheet'],
            summary: 'Sheet Data',
            description:
                'Returns the sheet, including rows, and optionally populated with discussion and attachment objects.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'sheetId',
                    in: 'query',
                    description: 'Sheet ID',
                    required: true,
                },
                {
                    name: 'dataFormat',
                    in: 'query',
                    description:
                        'defauls is smartsheet return. pass csv -> to get csv result or keyValue -> to get key value pair data.',
                },
                {
                    name: 'columnIds',
                    in: 'query',
                    description: 'a comma-separated list of column Ids.',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'which page number (1-based) to return.',
                },
                {
                    name: 'pageSize',
                    in: 'query',
                    description: 'number of rows per page to include with the sheet.',
                },
                {
                    name: 'rowIds',
                    in: 'query',
                    description: 'a comma-separated list of row Ids on which to filter the rows included in the result',
                },
                {
                    name: 'rowNumbers',
                    in: 'query',
                    description:
                        'a comma-separated list of row numbers on which to filter the rows included in the result. ',
                },
                {
                    name: 'rowsModifiedSince',
                    in: 'query',
                    description:
                        'filter to return only rows that have been modified since the date/time provided. Date should be in ISO-8601 format, for example, rowsModifiedSince=2020-01-30T13:25:32-07:00.',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
