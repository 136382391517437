exports.obj = function (username, appId, token) {
  const schemaObj = {};
  schemaObj[`/${username}/${appId}/${token}`] = {
    get: {
      tags: ['Google Sheet'],
      summary: 'Get rows',
      description: 'Get the rows from your google sheet',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'row_id',
          in: 'query',
          description: 'If you want one row',
          type: 'integer',
        },
        {
          name: 'perPage',
          in: 'query',
          description: 'Total number of records you want in one request.',
          type: 'integer',
        },
        {
          name: 'page',
          in: 'query',
          description: 'Page number',
          type: 'integer',
        },
        {
          name: 'fields',
          in: 'query',
          description:
            'Only data for fields whose names are in this list will be included in the result. with separated commas',
          type: 'integer',
        },
        {
          name: 'filterBy',
          in: 'query',
          description: 'key to filter result',
        },
        {
          name: 'filterValue',
          in: 'query',
          description:
            'text value to filter the rows, use multiple values to filter with separated commas',
        },
        {
          name: 'filterType',
          in: 'query',
          description:
            'How you want to filter: default is <b>equal</b>. Valid values are <b>equal</b>, <b>contain</b>, <b>greater</b> - only for integar, <b>less</b> - only for integar',
        },
        {
          name: 'valueRenderOption',
          in: 'query',
          description:
            'Determines how values should be rendered in the output. FORMATTED_VALUE, UNFORMATTED_VALUE or FORMULA',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    post: {
      tags: ['Google Sheet'],
      summary: 'Add new Rows',
      description: 'Add multiple new rows into Google Sheet.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'body',
          in: 'body',
          description: 'Array of row array into body',
          required: true,
          sampleData: [
            ['NoCodeAPI', 'hello@nocodeapi.com'],
            ['Mohd Danish', 'mddanishyusuf@gmail.com'],
          ],
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    put: {
      tags: ['Google Sheet'],
      summary: 'Update row',
      description: 'Pass object with new values with row_id',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'body',
          in: 'body',
          description: 'Object of row into body with row_id',
          required: true,
          sampleData: {
            row_id: 3,
            name: 'NoCodeAPI v2',
            email: 'hello@nocodeapi.com',
          },
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
    delete: {
      tags: ['Google Sheet'],
      summary: 'Delete row',
      description: 'You can also delete one row at a time',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'row_id',
          in: 'query',
          description: 'Row id of your sheet of which you want to delete',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/addRows`] = {
    post: {
      tags: ['Google Sheet'],
      summary: 'Add new Rows as JSON objects',
      description: 'Add multiple new rows into Google Sheet as key value pair.',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'body',
          in: 'body',
          description: 'Array of key value objects into body',
          required: true,
          sampleData: [
            {
              name: 'jack',
              message: 'is a programmer',
            },
            { name: 'brian', message: 'loves food' },
          ],
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/create`] = {
    post: {
      tags: ['Google Sheet'],
      summary: 'Create new Spreedsheet',
      description: 'You can also make fresh and new spreedsheet with POST call',
      produces: ['application/json'],
      parameters: [
        {
          name: 'title',
          in: 'query',
          description: 'pass title query params',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/styleCells`] = {
    put: {
      tags: ['Google Sheet'],
      summary: 'Style Cells',
      description: 'Add foreground or background color to google sheet cells',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'body',
          in: 'body',
          description: 'Array of key value objects into body',
          required: true,
          sampleData: {
            row_id: 0,
            end_row_id: 2,
            start_column_id: 1,
            end_column_id: 3,
            backgroundColor: 'rgb(255,255,255)',
            textColor: 'rgb(0,0,0)',
          },
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/addSheetTab`] = {
    post: {
      tags: ['Google Sheet'],
      summary: 'Create new tab',
      description: 'Create new tab in google sheet if not exist',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };

  schemaObj[`/${username}/${appId}/${token}/search`] = {
    get: {
      tags: ['Google Sheet'],
      summary: 'Search',
      description: 'You can search for anything by passing searchKey with searchValue',
      produces: ['application/json'],
      parameters: [
        {
          name: 'tabId',
          in: 'query',
          description: 'Your Sheet Tab name. (ex: Sheet1)',
          required: true,
        },
        {
          name: 'searchKey',
          in: 'query',
          description: 'search key to find result',
          required: true,
        },
        {
          name: 'searchValue',
          in: 'query',
          description: 'text value to search',
          required: true,
        },
        {
          name: 'searchType',
          in: 'query',
          description:
            'How you want to search: default is <b>equal</b>. Valid values are <b>equal</b>, <b>contain</b>, <b>greater</b> - only for integar, <b>less</b> - only for integar',
        },
        {
          name: 'valueRenderOption',
          in: 'query',
          description:
            'Determines how values should be rendered in the output. FORMATTED_VALUE, UNFORMATTED_VALUE or FORMULA',
          type: 'string',
        },
      ],
      responses: {
        200: {
          description: 'successful operation',
        },
      },
    },
  };
  return schemaObj;
};
