exports.obj = function(username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['GitHub'],
            summary: 'User profile',
            description:
                'Get GitHub user profile by passing username as query param.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'username',
                    in: 'query',
                    description:
                        'By default result will be for authenticated user',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/repos`] = {
        get: {
            tags: ['GitHub'],
            summary: 'Repositories',
            description: 'Get your Repository list',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'username',
                    in: 'query',
                    description:
                        'By default result will be for authenticated user',
                    type: 'string',
                },
                {
                    name: 'type',
                    in: 'query',
                    description:
                        'all, owner, public, private, member. Default: all',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description:
                        'created, updated, pushed, full_name. Default: full_name',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc. Default: asc',
                    type: 'string',
                },
                {
                    name: 'affiliation',
                    in: 'query',
                    description: 'owner, collaborator, organization_member',
                    type: 'string',
                },
                {
                    name: 'visibility',
                    in: 'query',
                    description: 'all, public, or private. Default: all',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/repoIssues`] = {
        get: {
            tags: ['GitHub'],
            summary: 'Repository Issues',
            description: 'Get the issues of the repositories as json',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'owner',
                    in: 'query',
                    description: 'name of the repo owner',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'repo',
                    in: 'query',
                    description: 'repository name',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'filter',
                    in: 'query',
                    description:
                        'assigned, created, mentioned, subscribed, all. By default assigned',
                    type: 'string',
                },
                {
                    name: 'state',
                    in: 'query',
                    description:
                        'Indicates the state of the issues to return. Can be either open, closed, or all. Default: open',
                    type: 'string',
                },
                {
                    name: 'labels',
                    in: 'query',
                    description:
                        'A list of comma separated label names. Example: bug,ui,@high',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'created, updated, Default: created',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc. Default: asc',
                    type: 'string',
                },
                {
                    name: 'since',
                    in: 'query',
                    description:
                        'Only issues updated at or after this time are returned. This is a timestamp in ISO 8601 format: YYYY-MM-DDTHH:MM:SSZ',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/issues`] = {
        get: {
            tags: ['GitHub'],
            summary: 'All Issues',
            description: 'Get the issues of the repositories as json',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'filter',
                    in: 'query',
                    description:
                        'assigned, created, mentioned, subscribed, all. By default assigned',
                    type: 'string',
                },
                {
                    name: 'state',
                    in: 'query',
                    description:
                        'Indicates the state of the issues to return. Can be either open, closed, or all. Default: open',
                    type: 'string',
                },
                {
                    name: 'labels',
                    in: 'query',
                    description:
                        'A list of comma separated label names. Example: bug,ui,@high',
                    type: 'string',
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'created, updated, Default: created',
                    type: 'string',
                },
                {
                    name: 'direction',
                    in: 'query',
                    description: 'asc or desc. Default: asc',
                    type: 'string',
                },
                {
                    name: 'since',
                    in: 'query',
                    description:
                        'Only issues updated at or after this time are returned. This is a timestamp in ISO 8601 format: YYYY-MM-DDTHH:MM:SSZ',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/search`] = {
        get: {
            tags: ['GitHub'],
            summary: 'Search',
            description: 'Search repositories, code, issues, users.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'type',
                    in: 'query',
                    description: 'repositories, code, issues, users',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'q',
                    in: 'query',
                    description: 'search query',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'sort',
                    in: 'query',
                    description: 'created, updated, Default: created',
                    type: 'string',
                },
                {
                    name: 'order',
                    in: 'query',
                    description: 'asc or desc. Default: asc',
                    type: 'string',
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'To get next page data',
                    type: 'string',
                },
                {
                    name: 'per_page',
                    in: 'query',
                    description: 'How many objects you need in one request',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
