import { Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  changeLanguage,
  changeLengthTo,
  changeReaderComprehensionLevelTo,
  changeStyleTo,
  changeToneTo,
  changeToSlang,
  degreeOfRevision,
  formatting,
  giveFeedbackAbout,
  outputAs,
  typeOfEdit,
} from './options';

import { showTextArea } from '../helpers';

const Editor = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    'Degree of revision': null,
    'Type of edit': null,
    'Give feedback about': null,
    'Change style to': null,
    'Change tone to': null,
    'Rewrite in the voice of': null,
    'Change language': null,
    'Change to slang': null,
    'Change reader comprehension level to': null,
    'Change length to': null,
    'Output as': null,
    Formatting: null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  const onChangeText = (event, name) => {
    setFormKey(name, event.target.value);
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Degree of revision'>
        <Select
          options={degreeOfRevision}
          placeholder='Degree of revision'
          onChange={(value) => {
            setFormKey('Degree of revision', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='
        Type
        of
        edit'
      >
        <Select
          options={typeOfEdit}
          placeholder='Type of edit'
          onChange={(value) => {
            setFormKey('Type of edit', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Give feedback about'>
        <Select
          options={giveFeedbackAbout}
          placeholder='Give feedback about'
          onChange={(value) => {
            setFormKey('Give feedback about', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='
        Change
        style'
        to
      >
        <Select
          options={changeStyleTo}
          placeholder='Change style to'
          onChange={(value) => {
            setFormKey('Change style to', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Change tone to'>
        <Select
          options={changeToneTo}
          placeholder='Change tone to'
          onChange={(value) => {
            setFormKey('Change tone to', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='
        Rewrite
        in
        the
        voice
   '
        of
      >
        <Select
          options={degreeOfRevision}
          placeholder='Rewrite in the voice of'
          onChange={(value) => {
            setFormKey('Rewrite in the voice of', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Change language'>
        <Select
          options={changeLanguage}
          onChange={(value) => {
            setFormKey('Change language', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      {showTextArea('Add your language', 'Change language', changeLanguage, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Language'>
          <Input.TextArea
            placeholder='Your language'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Change language')}
          />
        </Form.Item>
      )}
      <Form.Item
        className='mt-4 text-gray-600 font-medium text-md'
        label='
        Change
        to
        slang'
      >
        <Select
          options={changeToSlang}
          onChange={(value) => {
            setFormKey('Change to slang', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea(
        'Use your imagination and add :)',
        'Change to slang',
        changeToSlang,
        formMap,
      ) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Slang'>
          <Input.TextArea
            placeholder='Your slang'
            style={{ height: 120, marginBottom: 24 }}
            // value={addMoreOption.Slang}
            onChange={(e) => onChangeText(e, 'Change to slang')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md'>
        Change reader comprehension level
        <Select
          options={changeReaderComprehensionLevelTo}
          placeholder='Change reader comprehension level to'
          onChange={(value) => {
            setFormKey('Change reader comprehension level to', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Change length to'>
        <Select
          options={changeLengthTo}
          placeholder='Change length to'
          onChange={(value) => {
            setFormKey('Change length to', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Output as'>
        <Select
          options={outputAs}
          placeholder='Output as'
          onChange={(value) => {
            setFormKey('Output as', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Formatting'>
        <Select
          options={formatting}
          onChange={(value) => {
            setFormKey('Formatting', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
    </Form>
  );
};

export default Editor;
