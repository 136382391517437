exports.obj = function(username, appId, token) {
    const schemaObj = {};

    schemaObj[`/${username}/${appId}/${token}/getPosts`] = {
        get: {
            tags: ['Facebook Pages'],
            summary: 'Get Page Posts',
            description: 'Retrieve your facebook page post data as JSON',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'limit',
                    in: 'query',
                    description: 'limit the object to return',
                },
                {
                    name: 'before',
                    in: 'query',
                    description:
                        'pagination cursor to retrive previous results',
                },
                {
                    name: 'after',
                    in: 'query',
                    description: 'pagination cursor to retrive next results',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/publishPost`] = {
        post: {
            tags: ['Facebook Pages'],
            summary: 'Publish Post',
            description: 'Publish a post on the facebook page with simple API.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'message',
                    in: 'query',
                    description: 'message you want to publish',
                    required: true,
                },
                {
                    name: 'link',
                    in: 'query',
                    description: 'you can pass link with message param.',
                },
                {
                    name: 'message',
                    in: 'query',
                    description: 'message you want to publish',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/publishPhoto`] = {
        post: {
            tags: ['Facebook Pages'],
            summary: 'Publish Photo',
            description:
                'Publish an photo on your facebook page via a photo remote url.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'url',
                    in: 'query',
                    description: 'remote url of the photo',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/updatePost`] = {
        post: {
            tags: ['Facebook Pages'],
            summary: 'Update Post',
            description: 'You can update any post with page post id.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'message',
                    in: 'query',
                    description: 'new message string to update',
                    required: true,
                },
                {
                    name: 'pagePostId',
                    in: 'query',
                    description: 'page post id',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/deletePost`] = {
        delete: {
            tags: ['Facebook Pages'],
            summary: 'Delete Post',
            description: 'You can delete facebook page post without any coding',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'pagePostId',
                    in: 'query',
                    description: 'page post id',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/comments`] = {
        get: {
            tags: ['Facebook Pages'],
            summary: 'Get Comments',
            description: 'List all the comments of a facebook page post',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'pagePostId',
                    in: 'query',
                    description: 'page post id to get the comments',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
        post: {
            tags: ['Facebook Pages'],
            summary: 'Make Comment',
            description: 'Write a comment on a facebook page post.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'message',
                    in: 'query',
                    description: 'message you want to comment on the post',
                    required: true,
                },
                {
                    name: 'pagePostId',
                    in: 'query',
                    description: 'page post id to make the comment',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
