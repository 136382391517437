import { mapToSelectOptions } from '../helpers/index';
export const task = mapToSelectOptions([
  'Summarize my text',
  'Provide an overview of the key ideas in my text',
  'Explain the main arguments or claims made in my text',
  'Analyze the strengths of the ideas in my text',
  'Analyze the weaknesses the ideas in my text',
  'Provide context or background information ',
  'Offer additional resources or references',
  'Compare and contrast the ideas in my text with other viewpoints',
  'Identify any logical fallacies or inaccuracies',
  'Propose cons to the ideas in my text',
  'Propose pros to the ideas in my text',
  'Provide examples or evidence to support or refute the claims',
  'Write a meal plan. Use the details in my text',
  'Write a fitness plan. Use the details in my text',
  'Generate SEO Keywords',
  'Analyse according to the instructions in my text',
  "Add what you need, delete what you don't",
]);

export const style = mapToSelectOptions([
  'Formal',
  'Informal',
  'Casual',
  'Emotional',
  'Creative',
  'Persuasive',
  'Business',
  'Technical',
  'Legal',
  'Medical',
  'Academic',
]);
export const tone = mapToSelectOptions([
  'Funny',
  'Serious',
  'Friendly',
  'Professional',
  'Empathetic',
  'Confident',
  'Enthusiastic',
  'Assertive',
  'Encouraging',
  'Excited',
  'Witty',
  'Sympathetic',
  'Analytical',
  'Authoritative',
  'Romantic',
]);
export const language = mapToSelectOptions([
  'Spanish',
  'French',
  'German',
  'Italian',
  'Arabic',
  'Japanese',
  'Chinese',
  'US English',
  'UK English',
  'Australian English',
  'New Zealand English',
  'Irish',
  'Scottish',
  'Swiss German',
  'Berliner German',
  'Kölsch German',
  'Add your language',
]);
export const inTheVoiceOf = mapToSelectOptions([
  'Socrate',
  'Shakespeare',
  'Albert Einstein',
  'Add other famous personas here',
]);
export const slang = mapToSelectOptions([
  'Bro Talk',
  'Sassy girl Talk',
  'Snoop dogg Talk',
  '40s Gangster Talk',
  'Use your imagination and add :)',
]);
export const readerComprehension = mapToSelectOptions([
  '5 years old',
  '10 years old',
  '25 years old',
  '85 years old',
  'IQ 69',
  'IQ 115',
  'IQ 150',
  'beginner, assume no prior knowledge',
  'intermediate, assume some prior knowledge',
  'advanced, assume extensive prior knowledge',
  'PhD Graduate',
  'Triple PhD galactic brain',
]);
export const length = mapToSelectOptions([
  '280 characters',
  'Short, simple and to the point',
  '1 Paragraph',
  '3 Paragraphs',
  '100 Words',
  '300 Words',
  '500 Words',
  '1000 Words',
]);
export const outputAs = mapToSelectOptions([
  'Numbered list',
  'Bulleted list',
  'Bulleted list with nested items',
  'Task List',
  'Markdown table',
  'Markdown table with Column 1: Number',
  'Blockquote',
  'Code block',
  'JSON',
  'CSV',
  'YAML',
  'XML',
  'SQL',
]);
export const formatting = mapToSelectOptions([
  'Bold the important words',
  'Only the text. No comments before and after.',
  'Highlight key words and phrases',
]);
