exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Ghost'],
            summary: 'Get All posts',
            description: 'List all posts of your CMS.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'id',
                    in: 'query',
                    description: 'id of the entry',
                    required: false,
                    type: 'string',
                },
                {
                    name: 'limit',
                    in: 'query',
                    description: 'Number of record in one call. Default is 10',
                    required: false,
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Number of record in one call. Default is 10',
                    required: false,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    schemaObj[`/${username}/${appId}/${token}/{objectId}`] = {
        get: {
            tags: ['Ghost'],
            summary: 'Get Single post',
            description: 'List single post.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'objectId',
                    in: 'path',
                    description: 'id of the entry',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }

    return schemaObj
}
