import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import axios from 'axios';
import { BsFolderPlus } from 'react-icons/bs';
import { RiEditBoxFill } from 'react-icons/ri';

const { Option } = Select;

const ZohoMailForm = ({
      form,
      userID,
      formState,
      connectionID,
      oauthValidation,
}) => {
      const [loading, setLoading] = useState(false);
      const [ids, setIds] = useState([]);

      const refreshChatIdsList = () => {
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/zohoMail/getAccounts?_id=${connectionID}`,
                  {
                        headers: { 'Content-Type': 'application/json' },
                  }
            )
                  .then((result) => {
                        setIds(result.data.data);
                  })
                  .catch((err) => {
                        message.error(
                              'Your API key is expired. Re-add your zoho account.'
                        );
                  });
      };

      return (
            <>
                  {oauthValidation && (
                        <>
                              <Form.Item
                                    label="Give a name"
                                    name="name"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Name is required!',
                                          },
                                    ]}
                                    extra={
                                          <div
                                                style={{
                                                      fontWeight: 300,
                                                      fontSize: 14,
                                                }}
                                                dangerouslySetInnerHTML={{
                                                      __html: 'Give a name to this API.',
                                                }}
                                          />
                                    }
                              >
                                    <Input autoComplete="off" />
                              </Form.Item>

                              <a
                                    style={{
                                          cursor: 'pointer',
                                          textDecoration: 'underline',
                                    }}
                                    onClick={refreshChatIdsList}
                              >
                                    Refresh Dropdown
                              </a>
                              <Form.Item
                                    name="account_id"
                                    label="Account Name"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Account Name is required!',
                                          },
                                    ]}
                              >
                                    <Select
                                          name="account_id"
                                          placeholder="Select Account"
                                          allowClear
                                    >
                                          {ids.length > 0 &&
                                                ids.map((option, key) => (
                                                      <Option
                                                            value={
                                                                  option.accountId
                                                            }
                                                            key={key}
                                                      >
                                                            {option.accountName}
                                                      </Option>
                                                ))}
                                    </Select>
                              </Form.Item>

                              <Form.Item>
                                    <Button
                                          loading={loading}
                                          type="primary"
                                          htmlType="submit"
                                          className="make-btn"
                                          icon={
                                                formState === 'adding' ? (
                                                      <BsFolderPlus />
                                                ) : (
                                                      <RiEditBoxFill />
                                                )
                                          }
                                    >
                                          {formState === 'adding'
                                                ? 'Create'
                                                : 'Edit API'}
                                    </Button>
                              </Form.Item>
                        </>
                  )}
            </>
      );
};

export default ZohoMailForm;
