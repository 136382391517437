exports.obj = function (username, appId, token) {
    const schemaObj = {};

    schemaObj[`/${username}/${appId}/${token}/listProjects`] = {
        get: {
            tags: ['Google BigQuery'],
            summary: 'List projects',
            description: 'Lists all projects',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'Page token to get next page',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'Max number of records to retrive. Default max is 50',

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listDatasets`] = {
        get: {
            tags: ['Google BigQuery'],
            summary: 'List datasets',
            description: 'Lists all datasets',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'Page token to get next page',
                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'Max number of records to retrive. Default max is 50',

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/getDataset`] = {
        get: {
            tags: ['Google BigQuery'],
            summary: 'Get dataset',
            description: 'Get single dataset',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/createDataset`] = {
        post: {
            tags: ['Google BigQuery'],
            summary: 'Create dataset',
            description: 'Create a new dataset',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'request body to create dataset',
                    required: true,
                    sampleData: {
                        "kind": "bigquery#dataset",
                        "datasetReference": {
                            "projectId": "your_project_id",
                            "datasetId": "your_dataset_id"
                        },
                        "friendlyName": "My Dataset", //(optional)
                        "description": "This is a dataset for my data", //(optional)
                        "defaultPartitionExpirationMs": 3600000000 //(optional)  // 100 days in milliseconds
                    }
                },

            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/updateDataset`] = {
        put: {
            tags: ['Google BigQuery'],
            summary: 'Update dataset',
            description: 'Update a new dataset',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Request body to update dataset',
                    required: true

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/deleteDataset`] = {
        delete: {
            tags: ['Google BigQuery'],
            summary: 'Delete dataset',
            description: 'Delete a new dataset',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listTables`] = {
        get: {
            tags: ['Google BigQuery'],
            summary: 'List tables',
            description: 'List tables',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'maxResulst',
                    in: 'query',
                    description: 'max number or records to return. Default is 50',

                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'Token to get next page',

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/insertTables`] = {
        post: {
            tags: ['Google BigQuery'],
            summary: 'Insert tables',
            description: 'Insert tables',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Request body to insert table',
                    required: true,
                    sampleData: {
                        "kind": "bigquery#table",
                        "tableReference": {
                            "projectId": "your_project_id",
                            "datasetId": "your_dataset_id",
                            "tableId": "your_table_id"
                        },
                        "schema": {
                            "fields": [
                                {
                                    "name": "column_name1",
                                    "type": "STRING"
                                },
                                {
                                    "name": "column_name2",
                                    "type": "INTEGER"
                                }
                            ]
                        }
                    }

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/updateTables`] = {
        put: {
            tags: ['Google BigQuery'],
            summary: 'Update tables',
            description: 'Update tables',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'tableId',
                    in: 'query',
                    description: 'ID of table',
                    required: true

                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Request body to update tables',
                    required: true

                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/deleteTables`] = {
        delete: {
            tags: ['Google BigQuery'],
            summary: 'Delete table',
            description: 'Delete table',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'tableId',
                    in: 'query',
                    description: 'ID of table',
                    required: true

                },

            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/listTableData`] = {
        get: {
            tags: ['Google BigQuery'],
            summary: 'List table data',
            description: 'List table data',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'tableId',
                    in: 'query',
                    description: 'ID of table',
                    required: true

                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'max number of records to retrieve',
                },
                {
                    name: 'pageToken',
                    in: 'query',
                    description: 'token to retrieve next page',
                },
                {
                    name: 'startIndex',
                    in: 'query',
                    description: 'Start index of next page',
                },

            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    schemaObj[`/${username}/${appId}/${token}/insertAllTableData`] = {
        post: {
            tags: ['Google BigQuery'],
            summary: 'Insert all table data',
            description: 'Insert all table data',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'projectId',
                    in: 'query',
                    description: 'ID of project',
                    required: true
                },
                {
                    name: 'datasetId',
                    in: 'query',
                    description: 'ID of dataset',
                    required: true
                },
                {
                    name: 'tableId',
                    in: 'query',
                    description: 'ID of table',
                    required: true

                },
                {
                    name: 'maxResults',
                    in: 'query',
                    description: 'max number of records to retrieve',
                },
                {
                    name: 'body',
                    in: 'body',
                    description: 'Request body to insert table data',
                    required: true,
                    sampleData: {
                        "kind": "bigquery#tableDataInsertAllRequest",
                        "rows": [
                            {
                                "json": {
                                    "column_name1": "value1",
                                    "column_name2": 123
                                }
                            },
                            {
                                "json": {
                                    "column_name1": "value2",
                                    "column_name2": 456
                                }
                            }
                        ],
                        "skipInvalidRows": false, //(optional),
                        "ignoreUnknownValues": false, //(optional)
                    }
                },

            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
