import React, { useState } from 'react';
import { Button, Input, Form, Select } from 'antd';
import axios from 'axios';

const { Option } = Select;

const ConnectorAPIPlayground = ({ sendResultBack }) => {
    const [makingHTTPcall, setMakingHTTPcall] = useState(false);
    const [reqStatus, setReqStatus] = useState('');
    const [reqStatusType, setReqStatusType] = useState('success');

    const onFinish = values => {
        setMakingHTTPcall(true);
        const { thirdPartAPI, reqType } = values;
        const options = {
            method: reqType,
            url: thirdPartAPI,
            headers: { 'content-type': 'application/json' },
        };

        try {
            axios(options)
                .then(resultData => {
                    sendResultBack(resultData.data);
                    setMakingHTTPcall(false);
                    setReqStatusType('success');
                    setReqStatus('API request successful');
                })
                .catch(error => {
                    setMakingHTTPcall(false);
                    setReqStatusType('error');
                    setReqStatus('API request faild');
                });
        } catch (error) {
            console.log(error.message);
        }
    };

    const prefixSelector = (
        <Form.Item name="reqType" noStyle>
            <Select style={{ width: 80 }}>
                <Option value="GET">GET</Option>
                <Option value="POST">POST</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Form
            name="basic"
            initialValues={{
                reqType: 'GET',
            }}
            onFinish={onFinish}
            // onValuesChange={onValuesChange}
        >
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 20px 180px',
                    gridGap: 20,
                }}
            >
                <Form.Item name="thirdPartAPI">
                    <Input
                        addonBefore={prefixSelector}
                        placeholder="Third part API URL"
                    />
                </Form.Item>
                <div />
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={makingHTTPcall}
                >
                    Fetch Data
                </Button>
            </div>
        </Form>
    );
};

export default ConnectorAPIPlayground;
