import { mapToSelectOptions } from '../helpers/index';

export const helpMeWith = mapToSelectOptions([
  'Writing some code',
  'Explaining my code',
  'Improving my existing code',
  'Rewriting code using idiomatic constructs',
  'Rewriting code using the correct style',
  'Simplifying my code',
  'Writing test cases',
  'Explore alternative approaches',
  'Translating my code',
  'Tracking down bugs',
  'Scaffolding',
]);
export const outputAs = mapToSelectOptions([
  'Numbered list',
  'Bulleted list',
  'Bulleted list with nested items',
  'Task List',
  'Markdown table',
  'Markdown table with Column 1: Numbers',
  'Blockquote',
  'Code block',
  'JSON',
  'CSV',
  'YAML',
  'XML',
  'SQL',
]);
export const languageOrFramework = mapToSelectOptions([
  '/* Write it in the text box',
  '/* Leave this menu as: Choose an option',
]);
