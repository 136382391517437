exports.obj = function(username, appId, token) {
    const schemaObj = {}
    schemaObj[`/${username}/${appId}/${token}`] = {
        post: {
            tags: ['Cloudinary'],
            summary: 'Upload image',
            description: 'Upload image to cloudinary',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'file',
                    in: 'formData',
                    type: 'file',
                    description: 'file to upload',
                    required: true,
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    }
    return schemaObj
}
