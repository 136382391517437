import { Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { language, studentLevel, styleOfTeaching, tone, tutorPersona } from './option';
import { showTextArea } from '../helpers';

const Tutor = ({ handleChange }) => {
  const [formMap, setFormMap] = useState({
    'Tutor persona': null,
    'Style of teaching': null,
    Tone: null,
    Language: null,
    'Student level': null,
  });

  useEffect(() => {
    handleChange(formMap);
  }, [formMap]);

  const setFormKey = (key, val) => {
    const obj = formMap;
    obj[key] = val;
    setFormMap({ ...obj });
  };

  const onChangeText = (event, name) => {
    setFormKey(name, event.target.value);
  };

  return (
    <Form layout='vertical'>
      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Tutor persona'>
        <Select
          options={tutorPersona}
          placeholder='Tutor persona'
          onChange={(value) => {
            setFormKey('Tutor persona', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {(showTextArea('Add your favourite teacher', 'Tutor persona', tutorPersona, formMap) ||
        showTextArea(
          'Or any person you enjoy listening to',
          'Tutor persona',
          tutorPersona,
          formMap,
        )) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your tutor persona'>
          <Input.TextArea
            placeholder='Your chosen teacher'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Tutor persona')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Style of teaching'>
        <Select
          options={styleOfTeaching}
          placeholder='Style of teaching'
          onChange={(value) => {
            setFormKey('Style of teaching', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Tone'>
        <Select
          options={tone}
          placeholder='Tone'
          onChange={(value) => {
            setFormKey('Tone', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Language'>
        <Select
          options={language}
          placeholder='Language'
          onChange={(value) => {
            setFormKey('Language', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>

      {showTextArea('Add your language', 'Language', language, formMap) && (
        <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Your Language'>
          <Input.TextArea
            placeholder='Your language'
            style={{ height: 120, marginBottom: 24 }}
            onChange={(e) => onChangeText(e, 'Language')}
          />
        </Form.Item>
      )}

      <Form.Item className='mt-4 text-gray-600 font-medium text-md' label='Student level'>
        <Select
          options={studentLevel}
          placeholder='Student level'
          onChange={(value) => {
            setFormKey('Student level', value);
          }}
          className={'mt-2'}
        />
      </Form.Item>
    </Form>
  );
};

export default Tutor;
